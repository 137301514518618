import { useParams } from 'react-router-dom';
import CastDataSvc from '../../svc/CastDataSvc';
import React, { useEffect, useState } from 'react';
import { Cast, CastUpdateRequest, ItemType, LeanItem, SortOrder } from '../../model/Model';
import Grid from '@mui/material/Grid';
import CastCard from './CastCard';

import { Card, CardActions, CardContent, Stack, TextField } from '@mui/material';

import { isValidDateString } from '../../util/DateUtil';
import Mapper from '../../util/Mapper';
import RatingInput from '../../../core/cmp/common/RatingInput';
import ItemMovieChipList from '../movie/ItemMovieChipList';
import TagDataService from '../../svc/TagDataService';
import { AxiosError } from 'axios';
import DeleteButton from '../../../core/cmp/common/button/DeleteButton';
import UpdateButton from '../../../core/cmp/common/button/UpdateButton';
import ItemStatDataService from '../../svc/ItemStatDataService';
import CastStatCard from './CastStatCard';
import CastTagStatCard from './CastTagStatCard';
import AppTextField from '../../../core/cmp/common/form/AppTextField';
import AppRadioButton from '../../../core/cmp/common/form/AppRadioButton';
import PagingUtil from '../../util/PagingUtil';
import AppConstant from '../../../core/config/AppConstant';
import AppCheckBox from '../../../core/cmp/common/form/AppCheckBox';
import { enqueueSnackbar } from 'notistack';

export default function CastDetailPage() {
  const defaultPageSize = 6;

  const { id } = useParams();
  const [cast, setCast] = useState({} as Cast);
  const [tags, setTags] = useState([] as LeanItem[]);

  const updateHandler = async (id: string, actorUpdateRequest: CastUpdateRequest) => {
    const response = await CastDataSvc.update(id, actorUpdateRequest);

    enqueueSnackbar(response.message);
    init(id);
  };

  const deleteHandler = async (id: string) => {
    if (!window.confirm('Confirm deletion!')) return;

    try {
      const response = await CastDataSvc.delete(id);
      enqueueSnackbar(response.message);
      init(id);

      //@todo redirect
    } catch (ex: AxiosError | any) {
      enqueueSnackbar(ex.message);
    }
  };

  const init = async (id: string) => {
    setCast(await CastDataSvc.getOne(id!));
  };

  const initOnce = async () => {
    setTags((await TagDataService.getPage()).content.map((tag) => Mapper.toLeanItem(tag.id, tag.name)));
  };

  useEffect(() => {
    if (id === undefined) return;
    init(id);
    ItemStatDataService.update(id, 'CAST' as ItemType);
  }, [id]);

  useEffect(() => {
    initOnce();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {cast.id ? <CastCard cast={cast} /> : ''}
        </Grid>
        <Grid item xs={5}>
          {cast.id ? (
            <ActorEditableCard actor={cast} updateHandler={updateHandler} deleteHandler={deleteHandler} />
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={3}>
          <Stack spacing={1}>
            {cast.id ? <CastStatCard actorId={cast.id} /> : ''}
            {cast.id ? <CastTagStatCard actorId={cast.id} /> : ''}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {cast.id && tags.length > 0 ? (
            <ItemMovieChipList
              label="Recommended"
              pageRequest={{
                ...PagingUtil.getDefaultMoviePagingRequest(),
                pageSize: defaultPageSize,
                sortBy: 'updatedAt',
                sortOrder: 'ASC' as SortOrder,
                actresses: cast.gender === 'FEMALE' ? [Mapper.toLeanItem(cast.id, cast.name)] : [],
                actors: cast.gender === 'MALE' ? [Mapper.toLeanItem(cast.id, cast.name)] : [],
                statuses: [{ id: 'QUEUED', name: '' } as LeanItem, { id: 'BOOKMARKED', name: '' } as LeanItem],
              }}
            />
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12}>
          {cast.id ? (
            <ItemMovieChipList
              label="New Release"
              pageRequest={{
                ...PagingUtil.getDefaultMoviePagingRequest(),
                pageSize: defaultPageSize,
                sortBy: 'releaseOn',
                actresses: cast.gender === 'FEMALE' ? [Mapper.toLeanItem(cast.id, cast.name)] : [],
                actors: cast.gender === 'MALE' ? [Mapper.toLeanItem(cast.id, cast.name)] : [],
              }}
            />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </>
  );
}

function ActorEditableCard({
  actor,
  updateHandler,
  deleteHandler,
}: {
  actor: Cast;
  updateHandler: any;
  deleteHandler: any;
}) {
  const [actorUpdateRequest, setActorUpdateRequest] = useState(Mapper.toActorUpdateRequest(actor));

  useEffect(() => {
    setActorUpdateRequest(Mapper.toActorUpdateRequest(actor));
  }, [actor]);

  return (
    <>
      <Card variant="outlined" sx={{ minWidth: '300px', width: '100%' }}>
        <CardContent>
          <Stack spacing={1}>
            <AppTextField
              label="Name"
              value={actorUpdateRequest.name}
              onChange={(value: string) => {
                setActorUpdateRequest({ ...actorUpdateRequest, name: value });
              }}
            />

            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
              <RatingInput
                rating={actor.rating}
                ratingChangeHandler={(rating: number) => {
                  updateHandler(actor.id, { ...actorUpdateRequest, rating: rating });
                }}
              />
              <AppRadioButton
                label={'Gender'}
                value={actorUpdateRequest.gender}
                onChange={(value: string) => {
                  setActorUpdateRequest({ ...actorUpdateRequest, gender: value });
                }}
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <AppTextField
                label="Height"
                value={actorUpdateRequest.height + ''}
                onChange={(value: string) => {
                  setActorUpdateRequest({ ...actorUpdateRequest, height: parseInt(value) });
                }}
              />
              <AppTextField
                label="DOB"
                value={actorUpdateRequest.dob + ''}
                onChange={(value: string) => {
                  if (!isValidDateString(value)) return;
                  setActorUpdateRequest({ ...actorUpdateRequest, dob: value });
                }}
              />
            </Stack>
            <AppTextField
              label={'Cover'}
              value={actorUpdateRequest.cover}
              onChange={(value: string) => {
                setActorUpdateRequest({ ...actorUpdateRequest, cover: value });
              }}
            />
            <AppTextField
              label="Alternate Name"
              value={actorUpdateRequest.alternateNames.join(AppConstant.ITEM_SEPARATOR)}
              onChange={(value: string) => {
                setActorUpdateRequest({
                  ...actorUpdateRequest,
                  alternateNames: value.split(AppConstant.ITEM_SEPARATOR),
                });
              }}
            />
            <AppTextField
              label={'Description'}
              value={actorUpdateRequest.description}
              onChange={(value: string) => {
                setActorUpdateRequest({ ...actorUpdateRequest, description: value });
              }}
            />
            <TextField
              id="actor-profile-input"
              label="Profile"
              size="small"
              multiline={true}
              rows={3}
              value={actorUpdateRequest.profile}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setActorUpdateRequest({ ...actorUpdateRequest, profile: event.target.value });
              }}
            />

            <Stack direction="row" spacing={1}>
              <AppCheckBox
                checked={actorUpdateRequest.actor}
                label={'Actor'}
                onChange={(value: boolean) => {
                  updateHandler(actor.id, { ...actorUpdateRequest, actor: value });
                }}
              />
              <AppCheckBox
                checked={actorUpdateRequest.director}
                label={'Directed'}
                onChange={(value: boolean) => {
                  updateHandler(actor.id, { ...actorUpdateRequest, director: value });
                }}
              />
              <AppCheckBox
                checked={actorUpdateRequest.watch}
                label={'Watch'}
                onChange={(value: boolean) => {
                  updateHandler(actor.id, { ...actorUpdateRequest, watch: value });
                }}
              />
              <AppCheckBox
                checked={actorUpdateRequest.verified}
                label={'Verified'}
                onChange={(value: boolean) => {
                  updateHandler(actor.id, { ...actorUpdateRequest, verified: value });
                }}
              />
            </Stack>
          </Stack>
        </CardContent>
        <CardActions>
          <UpdateButton onClick={() => updateHandler(actor.id, actorUpdateRequest)} />
          <DeleteButton onClick={() => deleteHandler(actor.id)} />
        </CardActions>
      </Card>
    </>
  );
}
