import styled from '@emotion/styled';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import AppText from './AppText';

export default function RatingDisplay({ rating }: { rating: number }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <StyledRating
          disabled={true}
          max={10}
          onChange={() => {}}
          size="small"
          defaultValue={rating / 10}
          precision={0.5}
          icon={<StarRateRoundedIcon fontSize="inherit" />}
          emptyIcon={<StarRateRoundedIcon fontSize="inherit" />}
        />
        <AppText text={' ' + (rating <= 0 ? '-' : rating.toLocaleString())} />
      </Box>
    </>
  );
}

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
    fontSize: 14,
  },
  '& .MuiRating-iconEmpty': {
    fontSize: 14,
  },
});
