import { Grid, Button, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Count, Series } from '../../model/Model';
import ViewUtil from '../../util/ViewUtil';
import CachedIcon from '@mui/icons-material/Cached';
import SeriesDataService from '../../svc/SeriesDataService';
import SeriesGallery from './SeriesGallery';
import SeriesTable from './SeriesTable';
import ModelUtil from '../../util/ModelUtil';
import PageViewSelector from '../../../core/cmp/common/PageViewSelector';

export default function SeriesPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [series, setSeries] = useState([] as Series[]);
  const [movieCount, setMovieCount] = useState({} as Count);
  const [keyword, setKeyword] = useState('');

  const handleAdd = async () => {
    if (keyword.trim().length < 1) return;
    await SeriesDataService.save({ ...ModelUtil.getEmptySeriesRequest(), name: keyword });
    init();
  };

  const handleRefreshClick = () => init();

  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  const init = async () => {
    setMovieCount(await SeriesDataService.getMovieCount());
    setSeries((await SeriesDataService.getPage()).content);
  };

  const filter = (i: Series) => i.name.toLowerCase().includes(keyword.toLowerCase());

  React.useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Stack spacing={1}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              id="input-search-keyword"
              label="Search.."
              variant="outlined"
              size="small"
              fullWidth
              value={keyword}
              onChange={handleKeywordChange}
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="outlined" startIcon={<CachedIcon />} fullWidth onClick={handleRefreshClick}>
              Refresh
            </Button>
          </Grid>
          <Grid item xs={4}>
            <PageViewSelector />
          </Grid>
        </Grid>
        {ViewUtil.isTableView(searchParams) ? (
          <SeriesTable series={series.filter(filter)} movieCount={movieCount} />
        ) : (
          <SeriesGallery series={series.filter(filter)} movieCount={movieCount} />
        )}
        {series.filter(filter).length < 1 ? <Button onClick={handleAdd}>Create: {keyword}</Button> : ''}
      </Stack>
    </>
  );
}
