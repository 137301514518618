import styled from '@emotion/styled';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { SyntheticEvent } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

export default function RatingInput({ rating, ratingChangeHandler }: { rating: number; ratingChangeHandler: any }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton size="small" disabled={rating <= 0} onClick={() => ratingChangeHandler(--rating)}>
          <RemoveIcon fontSize="inherit" />
        </IconButton>
        <StyledRating
          max={10}
          onChange={(e: SyntheticEvent<Element, Event>, newValue: number | null) => {
            if (newValue == null || newValue < 0) return;
            ratingChangeHandler(newValue * 10);
          }}
          size="small"
          defaultValue={rating / 10}
          precision={0.5}
          icon={<StarRateRoundedIcon fontSize="inherit" />}
          emptyIcon={<StarRateRoundedIcon fontSize="inherit" />}
        />
        <IconButton size="small" disabled={rating >= 100} onClick={() => ratingChangeHandler(++rating)}>
          <AddIcon fontSize="inherit" />
        </IconButton>
        <Box sx={{ ml: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {rating}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
});
