import { Card, CardContent, Typography } from '@mui/material';
import StringUtil from '../../../wl/util/StringUtil';
import { Counts } from '../../../wl/model/Model';

export default function StatCard({ stat, itemId }: { stat: Counts; itemId: string }) {
  return (
    <>
      <Card variant="outlined" sx={{ minWidth: 100 }}>
        <CardContent>
          <Typography variant="h6" component="div">
            Stat
          </Typography>
          {Object.keys(stat).map((key, index) => (
            <Typography key={index} sx={{ fontSize: 14 }} color="text.secondary">
              {StringUtil.enumToLabel(key)}: {stat[key][itemId]}
            </Typography>
          ))}
        </CardContent>
      </Card>
    </>
  );
}
