import { Chip } from '@mui/material';
import { Tag } from '../../model/Model';

export default function TagChip({ tag }: { tag: Tag }) {
  return (
    <>
      {tag ? (
        <Chip label={tag.name} component="a" href={`/tag/${tag.id}`} variant="outlined" size="small" clickable />
      ) : (
        ''
      )}
    </>
  );
}
