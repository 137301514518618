import MovieCreationRoundedIcon from '@mui/icons-material/MovieCreationRounded';
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import LocalMoviesRoundedIcon from '@mui/icons-material/LocalMoviesRounded';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import CakeIcon from '@mui/icons-material/Cake';
import LIcon from '@mui/icons-material/List';
import WebIcon from '@mui/icons-material/Web';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { VideocamRounded, Woman2Rounded, Man2Rounded } from '@mui/icons-material';
import CircleNotificationsRoundedIcon from '@mui/icons-material/CircleNotificationsRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ScreenSearchDesktopRoundedIcon from '@mui/icons-material/ScreenSearchDesktopRounded';
import BookmarkAddRoundedIcon from '@mui/icons-material/BookmarkAddRounded';
import BookmarkRemoveRoundedIcon from '@mui/icons-material/BookmarkRemoveRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

export function ViewIcon() {
  return <VisibilityIcon />;
}

export function DashboardIcon() {
  return <DashboardCustomizeRoundedIcon />;
}

export function MovieIcon() {
  return <MovieCreationRoundedIcon />;
}

export function CastIcon() {
  return <PeopleRoundedIcon />;
}

export function TagIcon() {
  return <LocalOfferRoundedIcon />;
}

export function SeriesIcon() {
  return <LocalMoviesRoundedIcon />;
}

export function StudioIcon() {
  return <CoffeeMakerIcon />;
}

export function ImportIcon() {
  return <SystemUpdateAltIcon />;
}

export function MineIcon() {
  return <WarehouseIcon />;
}

export function SettingIcon() {
  return <SettingsIcon />;
}

export function ListIcon() {
  return <LIcon />;
}

export function AgeIcon() {
  return <CakeIcon />;
}

export function SourceIcon() {
  return <WebIcon />;
}

export function MaleCastIcon() {
  return <Man2Rounded />;
}

export function FemaleCastIcon() {
  return <Woman2Rounded />;
}

export function DirectorIcon() {
  return <VideocamRounded />;
}

export function RelationIcon() {
  return <DeviceHubIcon />;
}

export function VerifiedIcon() {
  return <CheckCircleRoundedIcon fontSize="small" color="inherit" />;
}

export function WatchIcon() {
  return <CircleNotificationsRoundedIcon fontSize="small" color="inherit" />;
}

export function CrawlIcon() {
  return <ScreenSearchDesktopRoundedIcon fontSize="small" color="inherit" />;
}

export function BookmarkAddIcon() {
  return <BookmarkAddRoundedIcon color="inherit" />;
}

export function BookmarkRemoveIcon() {
  return <BookmarkRemoveRoundedIcon color="inherit" />;
}

export function PlayArrowIcon() {
  return <PlayArrowRoundedIcon color="inherit" />;
}

export function DownloadIcon() {
  return <DownloadRoundedIcon color="inherit" />;
}
