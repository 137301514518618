import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GrabberType, Source, SourceUpdateRequest } from '../../model/Model';
import SourceDataService from '../../svc/SourceDataService';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SourceFeed from './SourceFeed';
import SourceEditableCard from './SourceEditableCard';
import ModelUtil from '../../util/ModelUtil';
import SourceCard from './SourceCard';
import PeekService from '../../svc/PeekService';
import CopyToClipboard from '../../../core/cmp/common/CopyToClipboard';

export default function SourceDetailPage() {
  const { id } = useParams();
  const [source, setSource] = useState(ModelUtil.getEmptySource() as Source);

  const onUpdate = (id: string, request: SourceUpdateRequest) => {};
  const onDelete = (id: string) => {};
  const init = async (sourceId: string) => {
    const source = await SourceDataService.get(sourceId);

    setSource(source);
  };

  useEffect(() => {
    if (id === undefined) return;

    init(id);
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          {source.id ? <SourceCard source={source} /> : ''}
          <hr />
          {source.id && source.grabber.id === GrabberType.JV_OS1 ? (
            <LinkGenerator source={source} onClick={(value: string) => {}} />
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={6}>
          {source.id ? <SourceEditableCard source={source} onUpdate={onUpdate} onDelete={onDelete} /> : ''}
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          {source.id && ['JV_S1', 'JV_OS1'].includes(source.grabber.id) ? <SourceFeed source={source} /> : ''}
        </Grid>
      </Grid>
    </>
  );
}

function LinkGenerator({ source, onClick }: { source: Source; onClick: any }) {
  const [links, setLinks] = useState([] as string[]);

  const init = async () => {
    setLinks(await PeekService.peekAllSubLink(source.id, '/works/date'));
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 200 }}>
        <Table sx={{ minWidth: 150 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {links.map((link, index) => (
              <TableRow key={index}>
                <TableCell>{++index}</TableCell>
                <TableCell>{link}</TableCell>
                <TableCell>
                  <CopyToClipboard value={link} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
