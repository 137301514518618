import {watchlistSvc} from '../../core/config/AxioxConfig';
import {
  Cast,
  CastUpdateRequest,
  Counts,
  StatRequest,
  CountDto,
  CastPagingRequest,
  ItemPage,
} from '../model/Model';
import PagingUtil from '../util/PagingUtil';
import DataService from './DataService';

class CastDataSvc extends DataService<Cast> {
  override URL: string = '/v1/cast';

  public async getPage(request: CastPagingRequest) {
    return (await watchlistSvc.get<ItemPage<Cast>>(`${this.URL}?${PagingUtil.getQuery(request)}`)).data;
  }

  public async getStat(request: StatRequest): Promise<Counts> {
    return (await watchlistSvc.get<Counts>(`${this.URL}/stat?${PagingUtil.getStatRequestQuery(request)}`)).data;
  }

  public async getTagStat(id: string): Promise<CountDto[]> {
    return (await watchlistSvc.get<CountDto[]>(`${this.URL}/${id}/tag`)).data;
  }

  public getEmptyUpdateRequest() {
    return {
      name: '',
      rating: -1,
      dob: '',
      height: -1,
      gender: 'FEMALE',
      profile: '',
      cover: '',
      alternateNames: [],
      description: '',
      actor: false,
      director: false,
      watch: false,
      verified: false,
      tags: [],
    } as CastUpdateRequest;
  }
}

export default new CastDataSvc();
