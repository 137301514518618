import { watchlistSvc } from '../../core/config/AxioxConfig';
import { Count, ItemPage, PageRequest, Studio } from '../model/Model';
import PagingUtil from '../util/PagingUtil';
import DataService from './DataService';

class StudioDataService extends DataService<Studio> {
  override URL: string = '/v1/studio';

  public async getPage(request?: PageRequest) {
    return this.getAll(PagingUtil.getQuery(request));
  }

  public async getMovieCount(id: string): Promise<Count> {
    return (await watchlistSvc.get<Count>(`${this.URL}/movie-count?id=${id}`)).data;
  }

  public async search(movieName: string) {
    return (await watchlistSvc.get<ItemPage<Studio>>(`${this.URL}/search?movieName=${movieName}`)).data;
  }
}

export default new StudioDataService();
