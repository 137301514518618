import Button from '@mui/material/Button';

export default function MovieAdder({ code, handleImport: handleAdd }: { code: string; handleImport: any }) {
  return (
    <>
      <Button
        variant="outlined"
        disabled={code.trim().length < 1}
        fullWidth
        onClick={() => {
          handleAdd(code);
        }}
      >
        Add: {code}
      </Button>
    </>
  );
}
