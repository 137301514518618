import { AxiosError } from 'axios';
import { watchlistSvc } from '../../core/config/AxioxConfig';
import { ApiResponse, GrabMovieResponse, SourceFetchRequest } from '../model/Model';
import PagingUtil from '../util/PagingUtil';

class PeekService {
  private static URL: string = '/v1/peek';

  public async peek(sourceFetchRequest: SourceFetchRequest) {
    const query = PagingUtil.getFetchQuery(sourceFetchRequest);
    return (await watchlistSvc.get<GrabMovieResponse[]>(`${PeekService.URL}/movie?${query}`)).data;
  }

  public async peekAllSubLink(sourceId: string, path: string) {
    return (
      await watchlistSvc.get<string[]>(`${PeekService.URL}/sub-link?sourceId=${sourceId}&path=${encodeURIComponent(path)}`)
    ).data;
  }

  public async grabVideoLink(path: string) {
    try {
      return (await watchlistSvc.get<ApiResponse>(`${PeekService.URL}/trailer-link?path=${path}`)).data;
    } catch (ex: AxiosError | any) {
      return { message: `Failed to grab video link for: ${path}` } as ApiResponse;
    }
  }
}

export default new PeekService();
