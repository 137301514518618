import { Stack, TextField, ToggleButtonGroup, ToggleButton, Button, Box, Pagination, Typography } from '@mui/material';
import PagingUtil from '../../../wl/util/PagingUtil';
import SortMenu from './SortMenu';
import { LeanItem, PageRequest } from '../../../wl/model/Model';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { useEffect } from 'react';

export default function PageControl({
  totalItems,
  pagingRequest,
  onPageChange,
  onPageSizeChange,
  onPaginationChange,
  sortByOptions,
  sortByChangeHandler,
  sortOrderChangeHandler,
  enableKeyboardShortcut = false,
}: {
  totalItems: number;
  pagingRequest: PageRequest;
  onPageChange: any;
  onPageSizeChange: any;
  onPaginationChange: any;
  sortByOptions: LeanItem[];
  sortByChangeHandler: any;
  sortOrderChangeHandler: any;
  enableKeyboardShortcut?: boolean;
}) {
  const handleKeyboardNavigation = (event: KeyboardEvent) => {
    console.log(pagingRequest.page, event.key);

    if (event.key === 'ArrowRight') {
      onPageChange(pagingRequest.page++);
      return;
    }

    if (event.key === 'ArrowLeft') {
      if (pagingRequest.page == 1) return;

      onPageChange(pagingRequest.page--);
      return;
    }
  };

  useEffect(() => {
    if (false) {
      window.addEventListener('keydown', handleKeyboardNavigation);

      return () => {
        window.removeEventListener('keydown', handleKeyboardNavigation);
      };
    }
  }, []);

  return (
    <>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <TextField
          label="Page No"
          variant="outlined"
          size="small"
          sx={{ maxWidth: 80 }}
          value={pagingRequest.page}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onPageChange(parseInt(event.target.value));
          }}
        />
        <ToggleButtonGroup
          size="small"
          value={pagingRequest.pageSize}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement>, value: string) => {
            onPageSizeChange(!value ? 3 : parseInt(value));
          }}
        >
          <ToggleButton value={3}>3</ToggleButton>
          <ToggleButton value={6}>6</ToggleButton>
          <ToggleButton value={12}>12</ToggleButton>
        </ToggleButtonGroup>
        <Button
          variant="outlined"
          size="small"
          title="Shuffle Page"
          onClick={() => onPageChange(PagingUtil.getRandomPage(totalItems, pagingRequest.pageSize))}
        >
          <ShuffleIcon />
        </Button>
        <SortMenu
          sortBy={pagingRequest.sortBy}
          sortOrder={pagingRequest.sortOrder}
          sortByOptions={sortByOptions}
          sortByChangeHandler={sortByChangeHandler}
          sortOrderChangeHandler={sortOrderChangeHandler}
        />
        <PaginationMenu pagingRequest={pagingRequest} totalItems={totalItems} onPaginationChange={onPaginationChange} />
      </Stack>
    </>
  );
}

function PaginationMenu({
  pagingRequest,
  totalItems,
  onPaginationChange,
}: {
  pagingRequest: PageRequest;
  totalItems: number;
  onPaginationChange: any;
}) {
  return (
    <>
      <Stack spacing={1} direction="row">
        <Pagination
          variant="outlined"
          shape="rounded"
          size="large"
          count={PagingUtil.getTotalPages(totalItems, pagingRequest.pageSize)}
          page={pagingRequest.page}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            onPaginationChange(value);
          }}
          siblingCount={1}
        />
        <Box>
          <Typography variant="caption">Total: {totalItems}</Typography>
        </Box>
      </Stack>
    </>
  );
}
