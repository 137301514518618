import Button from '@mui/material/Button';

export default function AppActionButton({
  label,
  onClick = null,
  disabled = false,
}: {
  label: string;
  onClick?: any;
  disabled?: boolean;
}) {
  return (
    <>
      {onClick == null ? (
        ''
      ) : (
        <Button size="small" variant="outlined" fullWidth onClick={onClick} disabled={disabled}>
          {label}
        </Button>
      )}
    </>
  );
}
