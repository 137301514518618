export default class ChartUtil {

    public static readonly BG_COLORS = ['#FF6384', '#36A2EB', '#FFCE56'];

    public static getDefaultPieChartData() {
        return {
            labels: [] as string[],
            datasets: [
              {
                data: [] as string[],
                backgroundColor: ChartUtil.BG_COLORS,
              },
            ],
          }
    }
    public static getDefaultLineChartData() {
        return {
            labels: [] as string[],
            datasets: [
              {
                label: '',
                data: [] as string[],
                backgroundColor: ChartUtil.BG_COLORS,
              },
            ],
          }
    }

}