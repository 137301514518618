import { useEffect, useState } from 'react';
import { GrabTask, ItemPage, LeanItem } from '../model/Model';
import GrabTaskSvc from '../svc/GrabTaskSvc';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  ButtonGroup,
} from '@mui/material';
import { toFromNow } from '../util/DateUtil';
import ActionButton from '../../core/cmp/common/button/ActionButton';
import AppTextField from '../../core/cmp/common/form/AppTextField';
import PagingUtil from '../util/PagingUtil';
import SingleAutoComplete from '../../core/cmp/common/SingleAutoComplete';

export default function GrabTaskPage() {
  const sortByAutoCompleteOptions = ['item', 'createdAt', 'updatedAt'].map((i) => ({ id: i, name: i } as LeanItem));
  const [grabTaskPage, setGrabTaskPage] = useState({} as ItemPage<GrabTask>);
  const [grabTaskPageRequest, setGrabTaskPageRequest] = useState(PagingUtil.getDefaultGrabTaskPageRequest());

  const init = async () => {
    setGrabTaskPage(await GrabTaskSvc.getAll(grabTaskPageRequest));
  };

  const updateStatus = async (id: string, status: string) => {
    await GrabTaskSvc.update(id, status);
    init();
  };

  useEffect(() => {
    console.log('grabTaskPage', grabTaskPage);
  }, [grabTaskPage]);

  useEffect(() => {
    init();
  }, [grabTaskPageRequest]);

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <AppTextField
            label={`Page of ${Math.ceil(grabTaskPage.count / grabTaskPageRequest.pageSize)}`}
            value={`${grabTaskPageRequest.page}`}
            onChange={(v: string) => setGrabTaskPageRequest({ ...grabTaskPageRequest, page: parseInt(v) })}
          />
        </Grid>
        <Grid item xs={3}>
          <AppTextField
            label={'Size'}
            value={`${grabTaskPageRequest.pageSize}`}
            onChange={(v: string) => setGrabTaskPageRequest({ ...grabTaskPageRequest, pageSize: parseInt(v) })}
          />
        </Grid>
        <Grid item xs={3}>
          <SingleAutoComplete
            label={'Sort By'}
            options={sortByAutoCompleteOptions}
            value={sortByAutoCompleteOptions.find((i) => i.id === grabTaskPageRequest.sortBy) || null}
            onChange={(v: LeanItem | null) =>
              setGrabTaskPageRequest({ ...grabTaskPageRequest, sortBy: v != null ? v.id : 'createdAt' })
            }
          />
        </Grid>
      </Grid>
      <hr />
      {grabTaskPage.content ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Item</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Updated</TableCell>
                  <TableCell align="center">Created</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {grabTaskPage.content.map((row) => (
                  <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>{row.item}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{toFromNow(row.updatedAt)}</TableCell>
                    <TableCell>{toFromNow(row.createdAt)}</TableCell>
                    <TableCell>
                      <ButtonGroup size="small" variant="outlined" fullWidth>
                        <ActionButton label={'Skip'} onClick={() => updateStatus(row.id, 'SKIPPED')} />
                        <ActionButton label={'Re-queue'} onClick={() => updateStatus(row.id, 'QUEUED')} />
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        ''
      )}
    </>
  );
}
