import dayjs from 'dayjs';
import {
  Cast,
  CastUpdateRequest,
  LeanItem,
  List,
  ListUpdateRequest,
  Movie,
  MovieRequest as MovieUpdateRequest,
  Series,
  SeriesUpdateRequest,
  Source,
  SourceUpdateRequest,
  Studio,
  StudioUpdateRequest,
} from '../model/Model';
import { DEFAULT_DATE_FORMAT } from './DateUtil';

export default class Mapper {
  public static toActorUpdateRequest(actor: Cast): CastUpdateRequest {
    return {
      name: actor.name,
      rating: actor.rating,
      dob: dayjs(actor.dob).format(DEFAULT_DATE_FORMAT),
      gender: actor.gender,
      height: actor.height,
      profile: actor.profile,
      cover: actor.cover,
      alternateNames: actor.alternateNames,
      description: actor.description,
      actor: actor.actor,
      director: actor.director,
      watch: actor.watch,
      verified: actor.verified,
    } as CastUpdateRequest;
  }

  public static toMovieRequest(movie: Movie): MovieUpdateRequest {
    return {
      name: movie.name,
      rating: movie.rating,
      link: movie.link,
      subFile: movie.subFile,
      cover: movie.cover,
      description: movie.description,
      releaseOn: movie.releaseOn,
      verified: movie.verified,
      status: movie.status,
      directorId: movie.director?.id || '',
      seriesId: movie.series?.id || '',
      tags: movie.tags.map((i) => i.id),
      casts: movie.casts.map((i) => i.id),
    };
  }

  public static toSeriesUpdateRequest(series: Series): SeriesUpdateRequest {
    return {
      name: series.name,
      link: series.link,
      description: series.description,
      cover: series.cover,
      rating: series.rating,
      verified: series.verified,
    } as SeriesUpdateRequest;
  }

  // public static toMoviePageRequest(pagingRequest: MoviePagingRequest): MoviePagingRequest {
  //   return {
  //     page: pagingRequest.page,
  //     pageSize: pagingRequest.pageSize,
  //     keyword: pagingRequest.keyword,
  //     names: pagingRequest.names,
  //     description: pagingRequest.description,
  //     sortBy: pagingRequest.sortBy,
  //     sortOrder: pagingRequest.sortOrder,
  //     tags: pagingRequest.tags.map((i) => i.id),
  //     excludeTags: pagingRequest.excludeTags.map((i) => i.id),
  //     actors: pagingRequest.actors.map((i) => i.id),
  //     actresses: pagingRequest.actresses.map((i) => i.id),
  //     directors: pagingRequest.directors.map((i) => i.id),
  //     series: pagingRequest.series.map((i) => i.id),
  //     presetFilters: pagingRequest.presetFilters.map((i) => i.id),
  //     releaseYears: pagingRequest.releaseYears.map((i) => i.id),
  //     ratings: pagingRequest.ratings.map(i => i),
  //     statuses: pagingRequest.statuses.map(i => i.id)
  //   } as MoviePagingRequest;
  // }

  // public static toActorPageRequest(pagingRequest: CastPagingRequest): CastPageRequest {
  //   return {
  //     page: pagingRequest.page,
  //     pageSize: pagingRequest.pageSize,
  //     keyword: pagingRequest.keyword,
  //     sortBy: pagingRequest.sortBy,
  //     sortOrder: pagingRequest.sortOrder,
  //     gender: pagingRequest.gender,
  //     actor: pagingRequest.actor,
  //     director: pagingRequest.director,
  //     verified: pagingRequest.verified,
  //     watch: pagingRequest.watch,
  //   } as CastPageRequest;
  // }

  public static toStudioUpdateRequest(studio: Studio): StudioUpdateRequest {
    return {
      name: studio.name,
      codes: studio.codes,
      cover: studio.cover,
      description: studio.description,
      link: studio.link,
      searchQuery: studio.searchQuery,
      moviePage: studio.moviePage,
      rating: studio.rating,
      verified: studio.verified,
    } as StudioUpdateRequest;
  }

  public static toListUpdateRequest(list: List): ListUpdateRequest {
    return {
      name: list.name,
      itemType: list.itemType,
      items: list.items,
      description: list.description,
    } as ListUpdateRequest;
  }

  public static toSourceUpdateRequest(source: Source): SourceUpdateRequest {
    return {
      url: source.url,
      searchQuery: source.grabber.searchQuery,
      pageQuery: source.grabber.pageQuery,
      sourceItem: source.sourceItem,
    } as SourceUpdateRequest;
  }

  public static toLeanItem(id: string, value: string) : LeanItem {
    return {
      id: id,
      name: value
    }
  }

  public static toLeanItems(values: string[]): LeanItem[] {
    return values.map((value) => ({
      id: value,
      name: value,
    }));
  }
}
