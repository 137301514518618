import { useEffect, useState } from 'react';
import { MovieViewLog } from '../../model/Model';
import MovieDataService from '../../svc/MovieDataService';
import { Card, CardContent, Typography } from '@mui/material';
import AppText from '../../../core/cmp/common/AppText';
import { toDateString, toFromNow } from '../../util/DateUtil';
import _ from 'lodash';

export default function MovieStatCard({ movieId }: { movieId: string }) {
  const [movieViewLogs, setMovieViewLogs] = useState([] as MovieViewLog[]);

  const init = async () => {
    const result = await MovieDataService.getMovieViewLogs(movieId);
    const data = _.values(_.keyBy(result, (movieViewLog) => toDateString(movieViewLog.createdAt)));

    setMovieViewLogs(data);
  };

  useEffect(() => {
    init();
  }, [movieId]);

  return (
    <>
      <Card variant="outlined" sx={{ minWidth: 100 }}>
        <CardContent>
          <Typography variant="h6" component="div">
            Views
          </Typography>

          <br />

          {movieViewLogs.map((movieVIewLog) => (
            <AppText text={toFromNow(movieVIewLog.createdAt)} />
          ))}
        </CardContent>
      </Card>
    </>
  );
}
