import Button from '@mui/material/Button';

export default function ActionButton({
  label = '',
  onClick = null,
  startIcon = null,
}: {
  label?: string;
  onClick?: any;
  startIcon?: React.ReactNode;
}) {
  return (
    <>
      <Button
        size="small"
        variant="outlined"
        fullWidth
        onClick={
          onClick != null
            ? onClick
            : () => {
                console.log('Not Implemented');
              }
        }
        startIcon={startIcon}
      >
        {label}
      </Button>
    </>
  );
}
