import { Grid } from '@mui/material';
import { Count, Tag } from '../../model/Model';
import TagDataService from '../../svc/TagDataService';
import TagTable from './TagTable';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TagGallery from './TagGallery';
import ViewUtil from '../../util/ViewUtil';
import ModelUtil from '../../util/ModelUtil';
import ActionButton from '../../../core/cmp/common/button/ActionButton';
import AppTextField from '../../../core/cmp/common/form/AppTextField';
import AppUtil from '../../util/AppUtil';
import PageViewSelector from '../../../core/cmp/common/PageViewSelector';
import { enqueueSnackbar } from 'notistack';

export default function TagPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState('');
  const [tags, setTags] = useState([] as Tag[]);
  const [count, setCount] = useState({} as Count);

  const init = async () => {
    const countMap = await TagDataService.getMovieCount();
    setCount(countMap);
    setTags((await TagDataService.getPage()).content.sort((a, b) => countMap[b.id] - countMap[a.id]));
  };

  const handleAdd = async () => {
    if (AppUtil.isBlank(keyword)) return;

    await TagDataService.save({ ...ModelUtil.getEmptyTagRequest(), name: keyword });
    init();
  };

  const filter = (i: Tag) => i.name.toLowerCase().includes(keyword.toLowerCase());

  React.useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <AppTextField label="Search.." value={keyword} onChange={(e: string) => setKeyword(e)} />
        </Grid>
        <Grid item xs={2}>
          <ActionButton label="Refresh" onClick={() => init()} />
        </Grid>
        <Grid item xs={2}>
          <ActionButton
            label="Update Usage Rank"
            onClick={async () => {
              enqueueSnackbar((await TagDataService.updateUsageRank()).message);
              init();
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <PageViewSelector />
        </Grid>
        <Grid item xs={12}>
          {ViewUtil.isDefaultView(searchParams) ? (
            <TagGallery tags={tags.filter(filter)} count={count} />
          ) : (
            <TagTable tags={tags.filter(filter)} tagMovieCounts={count} />
          )}
          <ActionButton label={`Create: ${keyword}`} onClick={handleAdd} />
        </Grid>
      </Grid>
    </>
  );
}
