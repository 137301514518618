import { Cast, LeanItem, Tag } from '../model/Model';

export default class DataUtil {
  public static readonly FEMALE_CAST_FILTER = (cast: Cast) => cast.actor === true && cast.gender === 'FEMALE';
  public static readonly MALE_CAST_FILTER = (cast: Cast) => cast.actor === true && cast.gender === 'MALE';
  public static readonly DIRECTOR_FILTER = (cast: Cast) => cast.director === true;
  
  public static readonly toCastLeanItem = (cast: Cast) => {
    return { id: cast.id, name: cast.name } as LeanItem
  };
  
  public static readonly toTagLeanItem = (tag: Tag) => {
    return { id: tag.id, name: tag.name } as LeanItem
  };
}
