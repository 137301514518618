import { Link } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function LinkOpenerIcon({ value }: { value: string }) {
  return (
    <>
      <Link href={value} target="_blank" fontSize="inherit" color="inherit">
        <OpenInNewIcon fontSize="inherit" />
      </Link>
    </>
  );
}
