import { watchlistSvc } from '../../core/config/AxioxConfig';
import { ApiResponse, Count, PageRequest, Tag } from '../model/Model';
import PagingUtil from '../util/PagingUtil';
import DataService from './DataService';

class TagDataService extends DataService<Tag> {
  override URL: string = '/v1/tag';

  public async getPage(request?: PageRequest) {
    return this.getAll(PagingUtil.getQuery(request));
  }

  public async getMovieCount(): Promise<Count> {
    return (await watchlistSvc.get<Count>(`${this.URL}/movie-count`)).data;
  }

  public async updateUsageRank() {
    return (await watchlistSvc.patch<ApiResponse>(`${this.URL}/usage-rank`)).data;
  }
}

export default new TagDataService();
