import { Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

// @todo remove id, change option to list
export default function SelectOption({
  id,
  label,
  value,
  options,
  changeHandler,
}: {
  id: string;
  label: string;
  value: string;
  options: Map<string, string>;
  changeHandler: any;
}) {
  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel id={'input-select-label-' + id}>{label}</InputLabel>
        <Select id={'input-select-' + id} value={value} label={label} onChange={changeHandler}>
          {Array.from(options.keys()).map((key) => (
            <MenuItem key={key} value={key}>
              {options.get(key)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
