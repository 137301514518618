import { Stack, Typography } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import LinkOpenerIcon from './LinkOpenerIcon';

export default function LinkIconDisplay({ value }: { value: string }) {
  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <LinkIcon />
        <Typography variant="body2" color="text.secondary">
          {value.trim().length > 0 ? (
            <LinkOpenerIcon value={`${value}`} />
          ) : (
            <CheckBoxOutlineBlankIcon fontSize="inherit" />
          )}
        </Typography>
      </Stack>
    </>
  );
}
