import { Card, CardContent, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import RelationService from '../../svc/RelationService';
import ActionButton from '../../../core/cmp/common/button/ActionButton';
import MovieChipList from '../movie/MovieChipList';
import { AppPage, LeanItem, RelationDto } from '../../model/Model';
import MovieDataService from '../../svc/MovieDataService';
import SingleAsyncAutoComplete from '../../../core/cmp/common/SingleAsyncAutoComplete';
import AppLink from '../../../core/cmp/common/AppLink';
import PagingUtil from '../../util/PagingUtil';
import Mapper from '../../util/Mapper';

export default function RelatedItemEditableCard({ item: item }: { item: string }) {
  const [relations, setRelations] = useState([] as RelationDto[]);
  const [movieCodeAutoCompleteOptions, setMovieCodeAutoCompleteOptions] = useState([] as LeanItem[]);
  const [selectedMovieCode, setSelectedMovieCode] = useState(null as LeanItem | null);
  const [moviePagingRequest, setMoviePagingRequest] = useState(PagingUtil.getDefaultMoviePagingRequest());

  const init = async () => {
    setRelations(await RelationService.getByItem(item));
  };

  const relate = async () => {
    if (selectedMovieCode == null) return;

    await RelationService.relateItems([item, selectedMovieCode.id]);
    setSelectedMovieCode(null);
    init();
  };

  const unrelate = async () => {
    relations.forEach((relation) => {
      RelationService.removeItemFromRelation(relation.id, [item]);
    });
    init();
  };

  useEffect(() => {
    if (moviePagingRequest.keyword.length <= 2) return;

    MovieDataService.getPage(moviePagingRequest).then((page) =>
      setMovieCodeAutoCompleteOptions(page.content.map((i) => Mapper.toLeanItem(i.id, i.name)))
    );
  }, [moviePagingRequest]);

  useEffect(() => {
    init();
  }, [item]);

  return (
    <>
      <Card variant="outlined" sx={{ minWidth: 100 }}>
        <CardContent>
          {relations.map((relation, k) => (
            <span key={k}>
              <Typography variant="h6" component="div">
                <AppLink href={`/${AppPage.RELATION}/${relation.id}`} label={'Related'} />{' '}
                <Typography variant="subtitle2" component="span">
                  ({relation.items.length})
                </Typography>
              </Typography>
              <MovieChipList names={relation.items.map((i) => i.name)} />
            </span>
          ))}

          <hr />
          {relations.length == 0 ? (
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
              <SingleAsyncAutoComplete
                label="Movies"
                options={movieCodeAutoCompleteOptions}
                value={selectedMovieCode}
                onChange={(value: LeanItem) => setSelectedMovieCode(value)}
                inputValue={moviePagingRequest.keyword}
                onInputChange={(value: string) => setMoviePagingRequest({ ...moviePagingRequest, keyword: value })}
              />
              <ActionButton label="Relate" onClick={relate} />
            </Stack>
          ) : (
            <ActionButton label="Unrelate" onClick={unrelate} />
          )}
        </CardContent>
      </Card>
    </>
  );
}
