import { watchlistSvc } from '../../core/config/AxioxConfig';
import { ItemCount, Count, Movie, Cast } from '../model/Model';

class StatDataService {
  private static URL: string = '/v1/stat';

  public async getAll() {
    return (await watchlistSvc.get<Count>(`${StatDataService.URL}`)).data;
  }

  public async getMovieByTopView() {
    return (await watchlistSvc.get<ItemCount<Movie>[]>(`${StatDataService.URL}/movie/max-view-count`)).data;
  }

  public async getCastByTopView() {
    return (await watchlistSvc.get<ItemCount<Cast>[]>(`${StatDataService.URL}/cast/max-view-count`)).data;
  }

  public async getMovieByRecentView() {
    return (await watchlistSvc.get<Movie[]>(`${StatDataService.URL}/movie/latest-view`)).data;
  }

  public async getCastByRecentView() {
    return (await watchlistSvc.get<Cast[]>(`${StatDataService.URL}/cast/latest-view`)).data;
  }
}

export default new StatDataService();
