import { watchlistSvc } from '../../core/config/AxioxConfig';
import { Grabber, GrabberDto } from '../model/Model';

class GrabberSvc {
  private readonly URL: string = '/v1/grabber';

  public async getAll() {
    return (await watchlistSvc.get<Grabber[]>(this.URL)).data;
  }

  public async get(id: string) {
    return (await watchlistSvc.get<Grabber>(`${this.URL}/${id}`)).data;
  }

  public async create(id: string) {
    return (await watchlistSvc.post<Grabber>(`${this.URL}/${id}`)).data;
  }

  public async update(id: string, req: GrabberDto) {
    return (await watchlistSvc.patch<Grabber>(`${this.URL}/${id}`, req)).data;
  }
}

export default new GrabberSvc();
