import { crawlSvc } from '../../core/config/AxioxConfig';
import { AppSetting } from '../model/CrawlModel';

class CrawlSettingSvc {
  private readonly URL: string = '/v1/app-setting';

  public async getAllAppSetting() {
    return (await crawlSvc.get<AppSetting[]>(`${this.URL}`)).data;
  }

  public async updateAppSetting(id: string, value: boolean) {
    return (await crawlSvc.patch<AppSetting[]>(`${this.URL}/${id}?value=${value}`)).data;
  }
}

export default new CrawlSettingSvc();
