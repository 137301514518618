import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SeriesDataService from '../../svc/SeriesDataService';
import { LeanItem, Series, SeriesUpdateRequest } from '../../model/Model';
import { Button, Card, CardActions, CardContent, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import SeriesCard from './SeriesCard';
import ItemMovieChipList from '../movie/ItemMovieChipList';
import CardField from '../../../core/cmp/common/CardField';
import RatingInput from '../../../core/cmp/common/RatingInput';
import Mapper from '../../util/Mapper';
import { AxiosError } from 'axios';
import { toFromNow } from '../../util/DateUtil';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ModelUtil from '../../util/ModelUtil';
import PagingUtil from '../../util/PagingUtil';
import { enqueueSnackbar } from 'notistack';

export default function SeriesDetailPage() {
  const { id } = useParams();
  const [series, setSeries] = useState({} as Series);

  const updateHandler = async (id: string, seriesUpdateRequest: SeriesUpdateRequest) => {
    await SeriesDataService.update(id, seriesUpdateRequest);
    init(id);
    enqueueSnackbar('Updated');
  };

  const deleteHandler = async (id: string) => {
    if (!window.confirm('Confirm deletion!')) return;

    try {
      await SeriesDataService.delete(id);
      init(id);
      enqueueSnackbar(`Deleted item with id ${id}`);
      //@todo redirect
    } catch (ex: AxiosError | any) {
      enqueueSnackbar(ex.response.data.message);
    }
  };

  const init = async (id: string) => {
    setSeries(await SeriesDataService.getOne(id));
  };

  useEffect(() => {
    if (id === undefined) return;

    init(id);
  }, [id]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          {series.id ? <SeriesCard series={series} movieCount={0} /> : ''}
        </Grid>
        <Grid item xs={8}>
          {series.id ? (
            <SeriesEditableCard series={series} updateHandler={updateHandler} deleteHandler={deleteHandler} />
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12}>
          {series.id ? (
            <ItemMovieChipList
              label="In Series"
              pageRequest={{
                ...PagingUtil.getDefaultMoviePagingRequest(),
                pageSize: 12,
                sortBy: 'releaseOn',
                series: [{ id: series.id, name: series.name } as LeanItem],
              }}
            />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </>
  );
}

function SeriesEditableCard({
  series,
  updateHandler,
  deleteHandler,
}: {
  series: Series;
  updateHandler: any;
  deleteHandler: any;
}) {
  const [seriesUpdateRequest, setSeriesUpdateRequest] = useState(ModelUtil.getEmptySeriesRequest());

  useEffect(() => {
    setSeriesUpdateRequest(Mapper.toSeriesUpdateRequest(series));
  }, [series]);

  return (
    <>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <Stack spacing={1}>
            <CardField value={series.name} />
            <CardField value={`Added: ${toFromNow(series.createdAt)} Updated: ${toFromNow(series.updatedAt)}`} />
            <TextField
              id="series-name-input"
              label="Name"
              size="small"
              value={seriesUpdateRequest.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSeriesUpdateRequest({ ...seriesUpdateRequest, name: event.target.value });
              }}
            />
            <RatingInput
              rating={series.rating}
              ratingChangeHandler={(rating: number) => {
                updateHandler(series.id, { ...seriesUpdateRequest, rating: rating });
              }}
            />
            <TextField
              id="series-link-input"
              label="Link"
              size="small"
              value={seriesUpdateRequest.link}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSeriesUpdateRequest({ ...seriesUpdateRequest, link: event.target.value });
              }}
            />
            <TextField
              id="series-description-input"
              label="Description"
              size="small"
              value={seriesUpdateRequest.description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSeriesUpdateRequest({ ...seriesUpdateRequest, description: event.target.value });
              }}
            />
            <TextField
              id="series-cover-input"
              label="Cover"
              size="small"
              value={seriesUpdateRequest.cover}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSeriesUpdateRequest({ ...seriesUpdateRequest, cover: event.target.value });
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={seriesUpdateRequest.verified}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const updatedSeriesUpdateRequest = { ...seriesUpdateRequest, verified: event.target.checked };
                    setSeriesUpdateRequest(updatedSeriesUpdateRequest);
                    updateHandler(series.id, updatedSeriesUpdateRequest);
                  }}
                />
              }
              label="Verified"
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            size="small"
            startIcon={<SaveIcon />}
            onClick={() => updateHandler(series.id, seriesUpdateRequest)}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => deleteHandler(series.id)}
          >
            Delete
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
