import { Stack, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import { Series } from '../../../wl/model/Model';
import LinkOpenerIcon from './LinkOpenerIcon';

export default function SeriesIconDisplay({ value }: { value: Series | undefined }) {
  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <LocalMoviesIcon />
        <Typography variant="body2" color="text.secondary">
          {value ? <LinkOpenerIcon value={`/series/${value.id}`} /> : <CheckBoxOutlineBlankIcon fontSize="inherit" />}
        </Typography>
      </Stack>
    </>
  );
}
