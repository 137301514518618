import { Button, Card, CardContent, Chip, Stack, Typography } from '@mui/material';
import { Studio } from '../../model/Model';
import CoverIconDisplay from '../../../core/cmp/common/CoverIconDisplay';
import DescriptionIconDisplay from '../../../core/cmp/common/DescriptionIconDisplay';
import MovieCountIconDisplay from '../../../core/cmp/common/MovieCountIconDisplay';
import RatingIconDisplay from '../../../core/cmp/common/RatingIconDisplay';
import VerificationIconDisplay from '../../../core/cmp/common/VerificationIconDisplay';
import CopyToClipboard from '../../../core/cmp/common/CopyToClipboard';
import AppLink from '../../../core/cmp/common/AppLink';
import { useEffect, useState } from 'react';
import AppUtil from '../../util/AppUtil';
import AppConstant from '../../../core/config/AppConstant';
import PeekService from '../../svc/PeekService';
import LinkIconDisplay from '../../../core/cmp/common/LinkIconDisplay';
import { PlayArrowIcon } from '../../../core/cmp/common/Icon';
import { enqueueSnackbar } from 'notistack';

export default function StudioCard({ studio, code }: { studio: Studio; code?: string }) {
  const [moviePageLink, setmoviePageLink] = useState('');
  const [videoLink, setVideoLink] = useState('');

  const init = async () => {
    if (!code) return;

    const moviePageLink = `${studio.link}${AppUtil.parseLinkWithCode(studio.moviePage, code)}`;
    setmoviePageLink(moviePageLink);
  };

  const getVideoLink = async (moviePageLink: string) => {
    if (!moviePageLink) return;
    const result = (await PeekService.grabVideoLink(encodeURIComponent(moviePageLink))).message;
    try {
      new URL(result);
      setVideoLink(result);
    } catch {
      enqueueSnackbar('Failed to get link');
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Card variant={studio.verified ? 'outlined' : 'elevation'} sx={{ width: '100%' }}>
        <CardContent>
          <Stack direction="row" spacing={1} justifyContent="space-around" useFlexGap flexWrap="wrap">
            <MovieCountIconDisplay value={0} />
            <RatingIconDisplay value={studio.rating} />
            <LinkIconDisplay value={studio.link} />
            <VerificationIconDisplay value={studio.verified} />
            <CoverIconDisplay value={studio.cover} />
            <DescriptionIconDisplay value={studio.description} />
          </Stack>
          <br />
          {code ? (
            <Stack direction="row" spacing={2}>
              <Typography variant="body2">
                <AppLink label={code} href={moviePageLink} />
              </Typography>
              <Button
                disabled={videoLink.length > 0}
                size="small"
                variant="outlined"
                onClick={() => getVideoLink(moviePageLink)}
              >
                Show Video Link
              </Button>
              <Button
                disabled={!videoLink}
                variant="outlined"
                size="small"
                href={`${AppConstant.DEFAULT_MEDIA_PLAYER}://weblink?url=${videoLink}`}
                target="blank"
              >
                <PlayArrowIcon />
              </Button>
            </Stack>
          ) : (
            ''
          )}
          <Typography variant="body2">
            <AppLink label={studio.name} href={`/studio/${studio.id}`} />
            <CopyToClipboard value={studio.name} />
          </Typography>
          <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
            {studio.codes.map((code, index) => (
              <Chip key={index} label={code.trim()} size="small" variant="outlined" />
            ))}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
