import { Tag } from '../../model/Model';
import Stack from '@mui/material/Stack';
import TagChip from './TagChip';
import { TagIcon } from '../../../core/cmp/common/Icon';

export default function TagChipList({ tags }: { tags: Tag[] }) {
  return (
    <>
      <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
        <TagIcon />
        {tags.map((tag) => (
          <TagChip key={tag.id} tag={tag} />
        ))}
      </Stack>
    </>
  );
}
