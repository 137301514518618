import Card from '@mui/material/Card';
import { Series } from '../../model/Model';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MovieCountIconDisplay from '../../../core/cmp/common/MovieCountIconDisplay';
import RatingIconDisplay from '../../../core/cmp/common/RatingIconDisplay';
import VerificationIconDisplay from '../../../core/cmp/common/VerificationIconDisplay';
import CoverIconDisplay from '../../../core/cmp/common/CoverIconDisplay';
import DescriptionIconDisplay from '../../../core/cmp/common/DescriptionIconDisplay';
import CopyToClipboard from '../../../core/cmp/common/CopyToClipboard';
import AppLink from '../../../core/cmp/common/AppLink';
import LinkIconDisplay from '../../../core/cmp/common/LinkIconDisplay';

export default function SeriesCard({ series, movieCount }: { series: Series; movieCount: number }) {
  return (
    <>
      <Card variant={series.verified ? 'outlined' : 'elevation'} sx={{ width: '100%' }}>
        <CardContent>
          <Stack direction="row" spacing={1} justifyContent="space-around">
            <MovieCountIconDisplay value={movieCount} />
            <RatingIconDisplay value={series.rating} />
            <VerificationIconDisplay value={series.verified} />
            <CoverIconDisplay value={series.cover} />
            <LinkIconDisplay value={series.link} />
            <DescriptionIconDisplay value={series.description} />
          </Stack>
          <br />
          <Typography variant="body2">
            <AppLink label={series.name} href={`/series/${series.id}`} />
            <CopyToClipboard value={series.name} />
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
