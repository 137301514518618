import MovieChipList from './MovieChipList';
import { useEffect, useState } from 'react';
import MovieDataService from '../../svc/MovieDataService';
import { Movie, MoviePagingRequest } from '../../model/Model';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MovieList from './MovieList';
import { Stack } from '@mui/material';
import ModelUtil from '../../util/ModelUtil';

export default function ItemMovieChipList({ label, pageRequest }: { label: string; pageRequest: MoviePagingRequest }) {
  const [totalMovies, setTotalMovies] = useState(0);
  const [page, setPage] = useState(pageRequest.page);
  const [expandView, setExpandView] = useState(true);
  const [movies, setMovies] = useState([] as Movie[]);

  const handleLoadMore = () => {
    const updatedPage = page + 1;
    setPage(updatedPage);
    init({ ...pageRequest, page: updatedPage });
  };

  const handleViewChange = () => {
    setExpandView(!expandView);
  };

  const init = async (pageRequest: MoviePagingRequest) => {
    const response = await MovieDataService.getPage(pageRequest);

    setTotalMovies(response.count);
    setMovies((movies) => [...movies, ...response.content]);
  };

  useEffect(() => {
    init(pageRequest);
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" color="text.secondary">
              {label} - Showing {movies.length} / {totalMovies}
            </Typography>
            <Button size="small" onClick={handleViewChange} variant="outlined">
              {expandView ? 'Minimal View' : 'Detail View'}
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {expandView === true ? (
            <MovieList movies={movies} option={ModelUtil.getMinimalMovieCardOption()} />
          ) : (
            <MovieChipList names={movies.map((i) => i.name)} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button size="small" onClick={handleLoadMore} disabled={movies.length >= totalMovies}>
            Load More
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
