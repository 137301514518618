import { useEffect, useState } from 'react';
import { MovieMetadata } from '../wl/model/CrawlModel';
import { Grid } from '@mui/material';
import { ItemPage, LeanItem, MovieMetadataPageRequest, SortOrder } from '../wl/model/Model';
import AppTextField from '../core/cmp/common/form/AppTextField';
import ActionButton from '../core/cmp/common/button/ActionButton';
import PageControl from '../core/cmp/common/PageControl';
import QueueSvc from './svc/QueueSvc';
import MovieMetaSvc from './svc/MovieMetadataSvc';
import MovieMetadataCard from './cmp/MovieMetadataCard';
import { enqueueSnackbar } from 'notistack';

export default function MovieMetadataPage() {
  const init = async () => {};

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <MovieMetadataList />
    </>
  );
}

function MovieMetadataList() {
  const [movieMetadataPage, setMovieMetadataPage] = useState({ content: [], count: 0 } as ItemPage<MovieMetadata>);
  const [pagingRequest, setPagingRequest] = useState({
    page: 1,
    pageSize: 3,
    sortBy: 'linkId',
    sortOrder: SortOrder.DESC,
    keyword: '',
    source: 'https://',
    cast: '',
    maleCast: '',
    femaleCast: '',
    description: '',
  } as MovieMetadataPageRequest);

  const sortByOptions = [
    { id: 'name', name: 'Name' },
    { id: 'releaseDate', name: 'Release Date' },
    { id: 'createdAt', name: 'Created' },
    { id: 'updatedAt', name: 'Updated' },
  ] as LeanItem[];

  const init = async () => {
    setMovieMetadataPage(await MovieMetaSvc.getPage(pagingRequest));
  };

  useEffect(() => {
    init();
  }, [pagingRequest]);

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <AppTextField
            label={'Source'}
            value={`${pagingRequest.source}`}
            onChange={(value: string) => setPagingRequest({ ...pagingRequest, source: value })}
          />
        </Grid>
        <Grid item xs={3}>
          <AppTextField
            label={'Cast'}
            value={`${pagingRequest.cast}`}
            onChange={(value: string) => setPagingRequest({ ...pagingRequest, cast: value })}
          />
        </Grid>
        <Grid item xs={3}>
          <AppTextField
            label={'Female Cast'}
            value={`${pagingRequest.femaleCast}`}
            onChange={(value: string) => setPagingRequest({ ...pagingRequest, femaleCast: value })}
          />
        </Grid>
        <Grid item xs={3}>
          <AppTextField
            label={'Description'}
            value={`${pagingRequest.description}`}
            onChange={(value: string) => setPagingRequest({ ...pagingRequest, description: value })}
          />
        </Grid>

        <Grid item xs={12}>
          <ActionButton
            label={'Scrape All Cover'}
            onClick={async () => {
              const response = await QueueSvc.scrapeCover(movieMetadataPage.content.map((it) => it.id));
              enqueueSnackbar(response.message);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <PageControl
            totalItems={movieMetadataPage.count}
            pagingRequest={pagingRequest}
            onPageChange={(value: number) => setPagingRequest({ ...pagingRequest, page: value })}
            onPageSizeChange={(value: number) => setPagingRequest({ ...pagingRequest, pageSize: value })}
            onPaginationChange={(value: number) => setPagingRequest({ ...pagingRequest, page: value })}
            sortByOptions={sortByOptions}
            sortByChangeHandler={() => {}}
            sortOrderChangeHandler={() => {}}
          />
        </Grid>
        {movieMetadataPage.content.map((movieMetadata, index) => (
          <Grid key={index} item xs={4}>
            <MovieMetadataCard
              movieMeta={movieMetadata}
              onScrapeItem={async (linkId: string) => {
                const response = await QueueSvc.scrapeItem([linkId]);
                enqueueSnackbar(response.message);
              }}
              onScrapeCover={async (movieMetadataId: string) => {
                const response = await QueueSvc.scrapeCover([movieMetadataId]);
                enqueueSnackbar(response.message);
              }}
              onDelete={async (metadataId: string) => {
                if (!confirm('Confirm delete!!!')) return;

                const response = await MovieMetaSvc.delete(metadataId);
                enqueueSnackbar(response.message);
                init();
              }}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <PageControl
            totalItems={movieMetadataPage.count}
            pagingRequest={pagingRequest}
            onPageChange={(value: number) => setPagingRequest({ ...pagingRequest, page: value })}
            onPageSizeChange={(value: number) => setPagingRequest({ ...pagingRequest, pageSize: value })}
            onPaginationChange={(value: number) => setPagingRequest({ ...pagingRequest, page: value })}
            sortByOptions={sortByOptions}
            sortByChangeHandler={() => {}}
            sortOrderChangeHandler={() => {}}
          />
        </Grid>
      </Grid>
    </>
  );
}
