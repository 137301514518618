import { useEffect, useState } from 'react';
import { Counts, StatItem } from '../../model/Model';
import CastDataSvc from '../../svc/CastDataSvc';
import StatCard from '../../../core/cmp/common/StatCard';

export default function CastStatCard({ actorId }: { actorId: string }) {
  const [stat, setStat] = useState({} as Counts);

  const init = async () => {
    setStat(
      await CastDataSvc.getStat({
        ids: [actorId!],
        statItems: [
          StatItem.TOTAL_MOVIES,
          StatItem.AVERAGE_RATING,
          StatItem.MISSING_RATING,
          StatItem.MISSING_TAG,
          StatItem.TOTAL_VIEWS,
        ],
      })
    );
  };

  useEffect(() => {
    init();
  }, [actorId]);

  return (
    <>
      <StatCard stat={stat} itemId={actorId} />
    </>
  );
}
