import _ from 'lodash';
import AppConstant from '../../core/config/AppConstant';

export default class AppUtil {
  private static readonly excludeList = ['www5.', 'www2.', 'www.'];

  public static getPageName(pathname: string): string {
    return _.upperFirst(pathname.split('/').filter((i) => i.trim().length > 0)[0]);
  }

  public static getRandomNumber(): number {
    return Math.floor(Math.random() * 10);
  }

  public static getFullPageName(pathname: string): string {
    return pathname
      .split('/')
      .filter((i) => i.trim().length > 0)
      .join(' | ');
  }

  public static getExtension(url: string) {
    if (url.trim().length < 1) return '';

    const path = new URL(url).pathname.split('/');
    const filename = path.pop();
    const extension = filename?.split('.').pop();

    return extension;
  }

  public static isBlank(url: string) {
    return url.trim().length < 1;
  }

  public static isEmpty(list: any[]) {
    return list.length < 1;
  }

  public static getHostname(url: string) {
    let name = new URL(url).hostname;
    AppUtil.excludeList.forEach((i) => (name = name.replace(i, '')));
    return name;
  }

  public static parseLinkWithCode(link: string, code: string): string {
    if (link.includes(AppConstant.CODE_WITHOUT_DASH_PLACEHOLDER)) {
      return link.replace(AppConstant.CODE_WITHOUT_DASH_PLACEHOLDER, code.replace('-', ''));
    }

    return link.replace(AppConstant.CODE_PLACEHOLDER, code);
  }

  public static toSiteName(url: string) : string {
    return (new URL(url)).hostname
  }
}
