import Button from '@mui/material/Button';

export default function DeleteButton({ onClick }: { onClick: any }) {
  return (
    <>
      <Button variant="outlined" fullWidth size="small" color="error" onClick={onClick}>
        Delete
      </Button>
    </>
  );
}
