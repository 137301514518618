import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppUtil from '../../wl/util/AppUtil';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppTitleBar({ open, handleDrawerOpen }: { open: boolean; handleDrawerOpen: any }) {
  const { pathname } = useLocation();
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    if (pathname === undefined) return;

    setPageTitle(AppUtil.getPageName(pathname));
  }, [pathname]);

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        sx={{ borderBottom: 1, borderColor: 'rgba(255, 255, 255, 0.12)' }}
      >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {pageTitle}
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
}
