import React, { useEffect, useState } from 'react';
import CastTable from './CastTable';
import { Cast, CastUpdateRequest, Count, LeanItem, SortOrder } from '../../model/Model';
import CastDataSvc from '../../svc/CastDataSvc';
import Stack from '@mui/material/Stack';
import PagingUtil from '../../util/PagingUtil';
import { Grid, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import CastGallery from './CastGallery';
import CastAddDialog from './CastAddDialog';
import Mapper from '../../util/Mapper';
import ViewUtil from '../../util/ViewUtil';
import { useSearchParams } from 'react-router-dom';
import TagDataService from '../../svc/TagDataService';
import AppTextField from '../../../core/cmp/common/form/AppTextField';
import PageViewSelector from '../../../core/cmp/common/PageViewSelector';
import PageControl from '../../../core/cmp/common/PageControl';

export default function CastPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tagAutoCompleteOptions, setTagAutoCompleteOptions] = useState([] as LeanItem[]);
  const sortByOptions = [
    { id: 'name', name: 'Name' },
    { id: 'rating', name: 'Rating' },
    { id: 'height', name: 'Height' },
    { id: 'dob', name: 'Age' },
    { id: 'createdAt', name: 'Created' },
    { id: 'updatedAt', name: 'Updated' },
  ] as LeanItem[];
  const [casts, setCasts] = useState([] as Cast[]);
  const [totalItems, setTotalItems] = useState(1);
  const [actorMovieCount, setActorMovieCount] = useState({} as Count);
  const [castPagingRequest, setCastPagingRequest] = useState(PagingUtil.getDefaultCastPagingRequest());
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const handlePaginationChange = (value: number) => {
    setCastPagingRequest({ ...castPagingRequest, page: value });
  };

  const handleCloseAddDialog = () => setOpenAddDialog(false);

  const handleCreate = async (req: CastUpdateRequest) => {
    await CastDataSvc.save(req);

    setOpenAddDialog(false);
    init();
  };

  const handleSortByChange = (sortBy: string) => setCastPagingRequest({ ...castPagingRequest, sortBy: sortBy });
  const handleSortOrderChange = (sortOrder: SortOrder) =>
    setCastPagingRequest({ ...castPagingRequest, sortOrder: sortOrder });

  const init = async () => {
    const result = await CastDataSvc.getPage(castPagingRequest);

    setCasts(result.content);
    setTotalItems(result.count);

    const statResult = await CastDataSvc.getStat({
      ids: result.content.map((i) => i.id),
      statItems: ['TOTAL_MOVIES'],
    });
    setActorMovieCount(statResult['TOTAL_MOVIES']);
  };

  const initAutoComplete = async () =>
    setTagAutoCompleteOptions(
      (await TagDataService.getPage()).content.map((tag) => Mapper.toLeanItem(tag.id, tag.name))
    );

  useEffect(() => {
    init();
  }, [castPagingRequest]);

  useEffect(() => {
    initAutoComplete();
  }, []);

  return (
    <>
      <Stack spacing={1}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <AppTextField
              label="Search..."
              value={castPagingRequest.keyword}
              onChange={(value: string) => setCastPagingRequest({ ...castPagingRequest, keyword: value })}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant={castPagingRequest.actor ? 'contained' : 'outlined'}
              onClick={() => setCastPagingRequest({ ...castPagingRequest, actor: !castPagingRequest.actor })}
            >
              Actor
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant={castPagingRequest.director ? 'contained' : 'outlined'}
              onClick={() => setCastPagingRequest({ ...castPagingRequest, director: !castPagingRequest.director })}
            >
              Director
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant={castPagingRequest.watch ? 'contained' : 'outlined'}
              onClick={() => setCastPagingRequest({ ...castPagingRequest, watch: !castPagingRequest.watch })}
            >
              Watch
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant={castPagingRequest.verified ? 'contained' : 'outlined'}
              onClick={() => setCastPagingRequest({ ...castPagingRequest, verified: !castPagingRequest.verified })}
            >
              Verified
            </Button>
          </Grid>
          <Grid item xs={4}>
            <ToggleButtonGroup
              size="small"
              value={castPagingRequest.gender}
              exclusive
              onChange={(event: React.MouseEvent<HTMLElement>, value: string) => {
                setCastPagingRequest({ ...castPagingRequest, gender: value });
              }}
            >
              <ToggleButton value="MALE">M</ToggleButton>
              <ToggleButton value="FEMALE">F</ToggleButton>
              <ToggleButton value="UNKNOWN">ALL</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                setOpenAddDialog(true);
              }}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <PageViewSelector />
          </Grid>
          <Grid item xs={12}>
            <PageControl
              totalItems={totalItems}
              pagingRequest={castPagingRequest}
              onPageChange={(page: number) => setCastPagingRequest({ ...castPagingRequest, page: page })}
              onPageSizeChange={(pageSize: number) =>
                setCastPagingRequest({ ...castPagingRequest, pageSize: pageSize })
              }
              onPaginationChange={handlePaginationChange}
              sortByOptions={sortByOptions}
              sortByChangeHandler={handleSortByChange}
              sortOrderChangeHandler={handleSortOrderChange}
            />
          </Grid>
        </Grid>
        {ViewUtil.isDefaultView(searchParams) ? (
          <CastGallery actors={casts} />
        ) : (
          <CastTable actors={casts} actorMovieCount={actorMovieCount} />
        )}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageControl
              totalItems={totalItems}
              pagingRequest={castPagingRequest}
              onPageChange={(page: number) => setCastPagingRequest({ ...castPagingRequest, page: page })}
              onPageSizeChange={(pageSize: number) =>
                setCastPagingRequest({ ...castPagingRequest, pageSize: pageSize })
              }
              onPaginationChange={handlePaginationChange}
              sortByOptions={sortByOptions}
              sortByChangeHandler={handleSortByChange}
              sortOrderChangeHandler={handleSortOrderChange}
            />
          </Grid>
        </Grid>
      </Stack>
      <CastAddDialog open={openAddDialog} handleClose={handleCloseAddDialog} handleCreate={handleCreate} />
    </>
  );
}
