import { watchlistSvc } from '../../core/config/AxioxConfig';
import { ApiResponse, RelationDto } from '../model/Model';

class RelationService {
  private readonly URL: string = '/v1/relation';

  public async getPage() {
    return (await watchlistSvc.get<RelationDto[]>(`${this.URL}`)).data;
  }

  public async getByItem(item: string) {
    return (await watchlistSvc.get<RelationDto[]>(`${this.URL}?item=${item}`)).data;
  }

  public async getById(relationId: string) {
    return (await watchlistSvc.get<RelationDto>(`${this.URL}/${relationId}`)).data;
  }

  public async relateItems(items: string[]) {
    return (await watchlistSvc.patch<ApiResponse>(`${this.URL}?items=${items.join(',')}`)).data;
  }

  public async addItemToRelation(relationId: string, items: string[]) {
    return (await watchlistSvc.patch<ApiResponse>(`${this.URL}/${relationId}/add?items=${items.join(',')}`)).data;
  }

  public async removeItemFromRelation(relationId: string, items: string[]) {
    return (await watchlistSvc.patch<ApiResponse>(`${this.URL}/${relationId}/remove?items=${items.join(',')}`)).data;
  }

  public async deleteRelation(relationId: string) {
    return (await watchlistSvc.delete<string[]>(`${this.URL}/${relationId}`)).data;
  }
}

export default new RelationService();
