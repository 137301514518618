import CardContent from '@mui/material/CardContent';
import { Source } from '../../model/Model';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import AppLink from '../../../core/cmp/common/AppLink';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import AppUtil from '../../util/AppUtil';

export default function SourceCard({ source }: { source: Source }) {
  return (
    <>
      <Card sx={{ width: '100%' }} variant="outlined">
        <CardContent>
          <Typography variant="body2">
            <AppLink label={AppUtil.toSiteName(source.url)} href={`/source/${source.id}`} />
          </Typography>
          <br />
          <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
            <Chip label={source.sourceItem} size="small" variant="outlined" />
            <Chip label={source.grabber.id} size="small" variant="outlined" />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
