import { Checkbox, FormControlLabel, Typography } from '@mui/material';

export default function AppCheckBox({ checked, label, onChange }: { checked: boolean; label: string; onChange: any }) {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={checked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange(event.target.checked);
            }}
          />
        }
        label={
          <Typography sx={{ fontSize: 12 }} variant="body2" color="textSecondary">
            {label}
          </Typography>
        }
      />
    </>
  );
}
