import AttachmentIcon from '@mui/icons-material/Attachment';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Stack, Typography } from '@mui/material';

export default function SubtitleFileIconDisplay({ value }: { value: boolean }) {
  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <AttachmentIcon />
        <Typography variant="body2" color="text.secondary">
          {value ? <CheckBoxIcon fontSize="inherit" /> : <CheckBoxOutlineBlankIcon fontSize="inherit" />}
        </Typography>
      </Stack>
    </>
  );
}
