import { LeanItem, SortOrder } from '../../../wl/model/Model';
import { ToggleButtonGroup, ToggleButton, Stack } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddCardIcon from '@mui/icons-material/AddCard';
import CachedIcon from '@mui/icons-material/Cached';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';

export default function SortMenu({
  sortBy,
  sortOrder,
  sortByOptions,
  sortByChangeHandler,
  sortOrderChangeHandler,
}: {
  sortBy: string;
  sortOrder: SortOrder;
  sortByOptions: LeanItem[];
  sortByChangeHandler: any;
  sortOrderChangeHandler: any;
}) {
  return (
    <>
      <Stack direction="row" spacing={1}>
        <ToggleButtonGroup
          size="small"
          value={sortBy}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement>, newSortBy: string) => {
            if (!newSortBy) return;
            sortByChangeHandler(newSortBy);
          }}
        >
          {sortByOptions.map((sortByOption, index) => (
            <ToggleButton key={index} value={sortByOption.id} title={sortByOption.name}>
              <Icon option={sortByOption.id} />
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <ToggleButtonGroup
          size="small"
          value={sortOrder}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement>, newSortOrder: SortOrder) => {
            if (!newSortOrder) {
              sortOrderChangeHandler(sortOrder);
              return;
            }
            sortOrderChangeHandler(newSortOrder);
          }}
        >
          <ToggleButton value="ASC" title="Ascending">
            <ArrowDropUpIcon />
          </ToggleButton>
          <ToggleButton value="" title="Refresh">
            <CachedIcon />
          </ToggleButton>
          <ToggleButton value="DESC" title="Descending">
            <ArrowDropDownIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </>
  );
}

function Icon({ option }: { option: string }) {
  return (
    <>
      {option === 'name' ? <SortByAlphaIcon /> : ''}
      {option === 'height' ? <SortByAlphaIcon /> : ''}
      {option === 'dob' ? <SortByAlphaIcon /> : ''}
      {option === 'rating' ? <StarRateRoundedIcon /> : ''}
      {option === 'releaseOn' ? <CalendarMonthIcon /> : ''}
      {option === 'releaseDate' ? <CalendarMonthIcon /> : ''}
      {option === 'createdAt' ? <AddCardIcon /> : ''}
      {option === 'updatedAt' ? <EditNoteIcon /> : ''}
    </>
  );
}
