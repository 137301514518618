export enum AppPage {
  TAG = 'tag',
  CAST = 'CAST',
  MOVIE = 'MOVIE',
  SERIES = 'series',
  RELATION = 'relation',
  STUDIO = 'studio',
  LIST = 'list',
  SOURCE = 'source',
  GRABER = 'grabber',
  SETTING = 'setting',
  IMPORT = 'import',
  CRAWL = 'crawl',
  LINK = 'link',
  MOVIE_METADATA = 'movie-metadata',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum StatItem {
  TOTAL_MOVIES = 'TOTAL_MOVIES',
  AVERAGE_RATING = 'AVERAGE_RATING',
  MISSING_RATING = 'MISSING_RATING',
  MISSING_TAG = 'MISSING_TAG',
  TOTAL_VIEWS = 'TOTAL_VIEWS',
}

export enum ExtSort {
  USAGE_COUNT = 'USAGE_COUNT',
  DEFAULT = 'DEFAULT',
}

export enum CastType {
  actor = 'actor',
  actress = 'actress',
  director = 'director',
}

export enum ImportType {
  FULL = 'FULL',
  DETAIL = 'DETAIL',
  SUB = 'SUB',
  TR = 'TR',
}

export enum ItemType {
  MOVIE = 'MOVIE',
  CAST = 'CAST',
}

export enum SourceItem {
  MOVIE = 'MOVIE',
  SUB = 'SUB',
  TORRENT = 'TORRENT',
}

export enum MovieStatus {
  ADDED = 'ADDED',
  CREATED = 'CREATED',
  BOOKMARKED = 'BOOKMARKED',
  QUEUED = 'QUEUED',
  WATCHING = 'WATCHING',
  REWATCH = 'REWATCH',
  COMPLETED = 'COMPLETED',
  STARRED = 'STARRED',
  DROPPED = 'DROPPED',
  SKIPPED = 'SKIPPED',
  UNKNOWN = 'UNKNOWN',
}

export enum GrabberType {
  JV_S1 = 'JV_S1',
  JV_S2 = 'JV_S2',
  JV_S3 = 'JV_S3',
  JV_OS1 = 'JV_OS1',
  JV_SB1 = 'JV_SB1',
  JV_TR1 = 'JV_TR1',
  V_OS1 = 'V_OS1',
  UNKNOWN = 'UNKNOWN',
}

export enum Orientation {
  HORIZONTAL = 'HORIZONTAL',
  DEFAULT = 'DEFAULT',
}

export enum TagType {
  ITEM_GENRE = 'ITEM_GENRE',
  ITEM_ACTION = 'ITEM_ACTION',
  ITEM_MEDIA_META = 'ITEM_MEDIA_META',
  ITEM_SUB_META = 'ITEM_SUB_META',
  ITEM_CONTENT_META = 'ITEM_CONTENT_META',
  UNKNOWN = 'UNKNOWN',
}

export interface ItemPage<T> {
  content: T[];
  count: number;
}

export interface ItemCount<T> {
  item: T;
  count: number;
}

export interface Count {
  [k: string]: number;
}

export interface Counts {
  [k: string]: Count;
}

export interface ApiResponse {
  message: string;
}

export interface Agg {
  itemId: string;
  itemCount: number;
}

export interface LeanItem {
  id: string;
  name: string;
}

export interface Entity {
  readonly id: string;
  readonly name: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface Tag extends Entity {
  readonly rating: number;
  readonly type: TagType;
  readonly verified: boolean;
}

export interface Cast extends Entity {
  readonly dob: Date;
  readonly height: number;
  readonly gender: string;
  readonly profile: string;
  readonly cover: string;
  readonly tags: Tag[];
  readonly alternateNames: string[];
  readonly description: string;
  readonly actor: boolean;
  readonly watch: boolean;
  readonly director: boolean;
  readonly rating: number;
  readonly verified: boolean;
}

export interface Series extends Entity {
  readonly link: string;
  readonly cover: string;
  readonly description: string;
  readonly rating: number;
  readonly verified: boolean;
}

export interface Studio extends Entity {
  readonly codes: string[];
  readonly cover: string;
  readonly description: string;
  readonly link: string;
  readonly searchQuery: string;
  readonly moviePage: string;
  readonly rating: number;
  readonly verified: boolean;
}

export interface Movie extends Entity {
  readonly casts: Cast[];
  readonly director?: Cast;
  readonly tags: Tag[];
  readonly link: string;
  readonly subFile: boolean;
  readonly cover: string;
  readonly series?: Series;
  readonly description: string;
  readonly releaseOn: string;
  readonly rating: number;
  readonly verified: boolean;
  readonly status: MovieStatus;
}

export interface List extends Entity {
  readonly itemType: string;
  readonly description: string;
  readonly items: string[];
}

export interface CodeMine {
  readonly code: string;
  readonly released: boolean;
  readonly movie?: Movie;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface PageRequest {
  page: number;
  pageSize: number;
  sortBy: string;
  sortOrder: SortOrder;
  keyword: string;
}

export interface StatRequest {
  ids: string[];
  statItems: string[];
}

export interface MinePageRequest extends PageRequest {
  includeImported: boolean;
}

export interface ListPageRequest extends PageRequest {
  itemId: string;
}

export interface CastPagingRequest extends PageRequest {
  gender: string;
  actor: boolean;
  director: boolean;
  verified: boolean;
  watch: boolean;
}

export interface GrabTaskPageRequest extends PageRequest {
  statuses: string[];
}

export interface MoviePagingRequest extends PageRequest {
  ids: string[];
  keyword: string;
  names: string[];
  description: string;
  tags: LeanItem[];
  excludeTags: LeanItem[];
  actors: LeanItem[];
  actresses: LeanItem[];
  series: LeanItem[];
  directors: LeanItem[];
  lists: string[];
  presetFilters: LeanItem[];
  releaseYears: LeanItem[];
  ratings: number[];
  castCounts: number[];
  statuses: LeanItem[];
  watch: boolean;
  verified: boolean;
}

export interface LinkPageRequest extends PageRequest {
  url: string;
  cast: string;
  femaleCast: string;
  maleCast: string;
  description: string;
}

export interface MovieMetadataPageRequest extends PageRequest {
  femaleCast: string;
  maleCast: string;
  cast: string;
  name: string;
  linkId: string;
  source: string;
  description: string;
}

export interface UpdateRequest {
  name: string;
}

export interface CastUpdateRequest {
  readonly name: string;
  readonly rating: number;
  readonly dob: string;
  readonly height: number;
  readonly gender: string;
  readonly profile: string;
  readonly cover: string;
  readonly alternateNames: string[];
  readonly description: string;
  readonly actor: boolean;
  readonly director: boolean;
  readonly watch: boolean;
  readonly verified: boolean;
}

export interface MovieRequest {
  readonly name: string;
  readonly rating: number;
  readonly link: string;
  readonly subFile: boolean;
  readonly cover: string;
  readonly description: string;
  readonly releaseOn: string;
  readonly verified: boolean;
  readonly status: MovieStatus;
  readonly directorId: string;
  readonly seriesId: string;
  readonly tags: string[];
  readonly casts: string[];
}

export interface TagUpdateRequest {
  readonly name: string;
  readonly rating: number;
  readonly type: TagType;
  readonly verified: boolean;
}

export interface ListUpdateRequest extends UpdateRequest {
  readonly itemType: string;
  readonly items: string[];
  readonly description: string;
}

export interface SeriesUpdateRequest {
  readonly name: string;
  readonly link: string;
  readonly description: string;
  readonly cover: string;
  readonly rating: number;
  readonly verified: boolean;
}

export interface StudioUpdateRequest {
  readonly name: string;
  readonly codes: string[];
  readonly cover: string;
  readonly description: string;
  readonly link: string;
  readonly searchQuery: string;
  readonly moviePage: string;
  readonly rating: number;
  readonly verified: boolean;
}

export interface PreviewOption {
  show: boolean;
  value: string;
}

export interface AppNavOption {
  label: string;
  route: string;
}

export interface Grabber {
  id: GrabberType;
  searchQuery: string;
  pageQuery: string;
  landingPath: string;
  subLinkPath: string;
}

export interface GrabberDto {
  searchQuery: string;
  pageQuery: string;
  landingPath: string;
  subLinkPath: string;
}

export interface Source {
  id: string;
  url: string;
  sourceItem: SourceItem;
  grabber: Grabber;
}

export interface SourceDto {
  url: string;
  sourceItem: SourceItem;
  grabberType: GrabberType;
}

export interface SourceUpdateRequest {
  url: string;
  searchQuery: string;
  pageQuery: string;
  sourceItem: string;
}

export interface GrabMovieResponse {
  name: string;
  description: string;
  releaseDate: string;
  actors: string[];
  actresses: string[];
  director: string[];
  source: string;
  cover: string;
}

export interface ItemStat {
  itemId: string;
  views: number;
}

export interface SourceFetchRequest {
  sourceId: string;
  page: string;
  rawQuery: string;
}

export interface CountDto {
  itemId: string;
  itemName: string;
  subItemCount: number;
}

export interface GrabTaskRequest {
  grabItems: string[];
}

export interface RelationDto {
  readonly id: string;
  readonly itemType: string;
  readonly items: LeanItem[];
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface GrabTask {
  readonly id: string;
  readonly item: string;
  readonly status: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface MovieCardOption {
  readonly showCover: boolean;
  readonly showName: boolean;
  readonly showCast: boolean;
  readonly showTag: boolean;
  readonly showDirector: boolean;
  readonly showDescription: boolean;
  readonly showStatus: boolean;
  readonly showTimestamp: boolean;
  readonly showControls: boolean;
}

export interface MovieViewLog {
  readonly id: string;
  readonly movieId: string;
  readonly createdAt: Date;
}
