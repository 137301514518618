import { watchlistSvc } from '../../core/config/AxioxConfig';
import { Count, PageRequest, Series } from '../model/Model';
import PagingUtil from '../util/PagingUtil';
import DataService from './DataService';

class SeriesDataService extends DataService<Series> {
  override URL: string = '/v1/series';

  public async getPage(request?: PageRequest) {
    return this.getAll(PagingUtil.getQuery(request));
  }

  public async getMovieCount(): Promise<Count> {
    return (await watchlistSvc.get<Count>(`${this.URL}/movie-count`)).data;
  }
}

export default new SeriesDataService();
