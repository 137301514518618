import { Chip, Tooltip } from '@mui/material';
import { Cast } from '../../model/Model';
import { VerifiedIcon, WatchIcon } from '../../../core/cmp/common/Icon';

export default function CastChip({ cast: cast }: { cast: Cast }) {
  const getColor = (cast: Cast) => {
    if (cast.rating > 85) return 'secondary';
    if (cast.rating > 70) return 'primary';
    return 'default';
  };

  return (
    <>
      {cast ? (
        <Tooltip title={`Rating: ${cast.rating}`} arrow>
          <Chip
            icon={<CastChipIcon cast={cast} />}
            label={cast.name}
            component="a"
            href={`/cast/${cast.id}`}
            color={getColor(cast)}
            variant="outlined"
            size="small"
            clickable
          />
        </Tooltip>
      ) : (
        ''
      )}
    </>
  );
}

function CastChipIcon({ cast }: { cast: Cast }) {
  return <>{cast.verified ? <VerifiedIcon /> : cast.watch ? <WatchIcon /> : ''}</>;
}
