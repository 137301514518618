import { Stack, Typography } from '@mui/material';
import { toAge } from '../../../wl/util/DateUtil';
import { AgeIcon } from './Icon';

export default function DateOfBirthIconDisplay({ value }: { value: Date }) {
  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <AgeIcon />
        <Typography variant="body2" color="text.secondary">
          {toAge(value)}
        </Typography>
      </Stack>
    </>
  );
}
