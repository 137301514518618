import { useEffect, useState } from 'react';
import { Movie, RelationDto } from '../../model/Model';
import RelationService from '../../svc/RelationService';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import MovieDynamicCard from '../movie/MovieDynamicCard';
import { useParams } from 'react-router-dom';
import AppTextField from '../../../core/cmp/common/form/AppTextField';
import ActionButton from '../../../core/cmp/common/button/ActionButton';
import MovieDataService from '../../svc/MovieDataService';
import MovieCard from '../movie/MovieCard';
import PagingUtil from '../../util/PagingUtil';

export default function RelationDetailPage() {
  const defaultMoviePageRequest = PagingUtil.getDefaultMoviePagingRequest();

  const { id } = useParams();
  const [relation, setRelation] = useState({} as RelationDto);
  const [namesInput, setNamesInput] = useState('');
  const [previewItems, setPreviewItems] = useState([] as Movie[]);

  const init = async (id: string) => {
    setRelation(await RelationService.getById(id));
  };

  const preview = async () => {
    const existingNames = relation.items.map((i) => i.name);
    const names = namesInput
      .split(',')
      .map((i) => i.trim().toUpperCase())
      .filter((i) => !existingNames.includes(i));

    if (names.length < 1) return;

    setPreviewItems((await MovieDataService.getPage({ ...defaultMoviePageRequest, names: names })).content);
  };

  const relate = async () => {
    const items = previewItems.map((i) => i.id) as string[];
    if (items.length < 1) return;

    await RelationService.addItemToRelation(relation.id, items);
    setPreviewItems([]);
  };

  const deleteRelation = async () => {
    if (id === undefined) return;

    await RelationService.deleteRelation(id);
  };

  useEffect(() => {
    if (id === undefined) return;

    init(id);
  }, [id]);

  return (
    <>
      {relation.id ? (
        <>
          <Stack spacing={1}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <AppTextField label={'Names'} value={namesInput} onChange={(value: string) => setNamesInput(value)} />
              </Grid>
              <Grid item xs={2}>
                <ActionButton label={'Check'} onClick={() => preview()} />
              </Grid>
              <Grid item xs={2}>
                <ActionButton label={'Relate'} onClick={() => relate()} />
              </Grid>
              <Grid item xs={2}>
                <ActionButton label={'Delete'} onClick={() => deleteRelation()} />
              </Grid>
              {previewItems.map((previewItem, index) => (
                <>
                  <Grid item xs={4}>
                    <MovieCard movie={previewItem} />
                  </Grid>
                </>
              ))}
              <Grid item xs={12}>
                <hr />
              </Grid>
              {relation.items.map((item, iIndex) => (
                <Grid key={iIndex} item xs={4}>
                  <MovieDynamicCard name={item.name} />
                </Grid>
              ))}
            </Grid>
          </Stack>
        </>
      ) : (
        ''
      )}
    </>
  );
}
