import { Stack } from '@mui/material';
import { Cast } from '../../model/Model';
import { DirectorIcon, FemaleCastIcon, MaleCastIcon } from '../../../core/cmp/common/Icon';
import CastChip from './CastChip';

export function CastChipList({ casts: casts, gender }: { casts: Cast[]; gender: string }) {
  return (
    <>
      <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
        {gender === 'FEMALE' ? <FemaleCastIcon /> : <MaleCastIcon />}
        {casts
          .filter((i) => i.gender === gender)
          .map((cast) => (
            <CastChip key={cast.id} cast={cast} />
          ))}
      </Stack>
    </>
  );
}

export function DirectorChip({ cast: cast }: { cast: Cast | undefined }) {
  return (
    <>
      <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
        <DirectorIcon />
        {cast ? <CastChip cast={cast} /> : ''}
      </Stack>
    </>
  );
}

