import { useEffect, useState } from 'react';
import { Grabber, GrabberDto } from '../../model/Model';
import GrabberSvc from '../../svc/GrabberSvc';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import GrabberCard from './GrabberCard';
import { Card, CardContent, Stack } from '@mui/material';
import AppTextField from '../../../core/cmp/common/form/AppTextField';
import ActionButton from '../../../core/cmp/common/button/ActionButton';

export default function GrabberDetailPage() {
  const { id } = useParams();
  const [grabber, setGrabber] = useState({} as Grabber);

  const init = async (id: string) => {
    setGrabber(await GrabberSvc.get(id));
  };

  useEffect(() => {
    if (!id) return;
    init(id);
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          {grabber.id ? <GrabberCard grabber={grabber} /> : ''}
        </Grid>
        <Grid item xs={8}>
          {grabber.id ? (
            <GrabberEditableCard
              grabber={grabber}
              onChange={async (grabberId: string, grabberRequest: GrabberDto) => {
                await GrabberSvc.update(grabberId, grabberRequest);
                if (id) init(id);
              }}
            />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </>
  );
}

function GrabberEditableCard({ grabber, onChange }: { grabber: Grabber; onChange: any }) {
  const [grabberRequest, setGrabberRequest] = useState({
    searchQuery: grabber.searchQuery,
    pageQuery: grabber.pageQuery,
    landingPath: grabber.landingPath,
    subLinkPath: grabber.subLinkPath,
  } as GrabberDto);

  return (
    <>
      <Card sx={{ minWidth: '250px' }} variant="outlined">
        <CardContent>
          <Stack spacing={1}>
            <AppTextField
              label={'Search Query'}
              value={grabberRequest.searchQuery}
              onChange={(v: string) => setGrabberRequest({ ...grabberRequest, searchQuery: v })}
            />
            <AppTextField
              label={'Page Query'}
              value={grabberRequest.pageQuery}
              onChange={(v: string) => setGrabberRequest({ ...grabberRequest, pageQuery: v })}
            />
            <AppTextField
              label={'Landing Path'}
              value={grabberRequest.landingPath}
              onChange={(v: string) => setGrabberRequest({ ...grabberRequest, landingPath: v })}
            />
            <AppTextField
              label={'Sub Link Path'}
              value={grabberRequest.subLinkPath}
              onChange={(v: string) => setGrabberRequest({ ...grabberRequest, subLinkPath: v })}
            />
            <ActionButton label={'Update'} onClick={() => onChange(grabber.id, grabberRequest)} />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
