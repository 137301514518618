import { Typography } from '@mui/material';

export default function AppCardTitle({ label }: { label: string }) {
  return (
    <>
      <Typography variant="h6" component="div">
        {label}
      </Typography>
    </>
  );
}
