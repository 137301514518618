import { Button } from '@mui/material';

export default function MovieImporter({ code, handleImport }: { code: string; handleImport: any }) {
  return (
    <>
      <Button
        variant="outlined"
        disabled={code.trim().length < 1}
        fullWidth
        onClick={() => {
          handleImport(code);
        }}
      >
        Import: {code}
      </Button>
    </>
  );
}
