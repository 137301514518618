import Grid from '@mui/material/Grid';
import { Cast } from '../../model/Model';
import CastCard from './CastCard';

export default function CastGallery({ actors }: { actors: Cast[] }) {
  return (
    <>
      <Grid container spacing={2}>
        {actors.map((actor, index) => (
          <Grid key={index} item xs={6} sm={4}>
            <CastCard cast={actor} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
