import { CardContent, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { Movie } from '../../model/Model';
import { useEffect, useState } from 'react';
import MovieDataService from '../../svc/MovieDataService';

export default function MovieSubStatus({ movie }: { movie: Movie }) {
  const [subStatus, setSubStatus] = useState(false);

  const init = async () => {
    await MovieDataService.syncSubStatus(movie.name)
    setSubStatus(await MovieDataService.checkSub(movie.name));
  };

  useEffect(() => {
    if (movie === undefined) return;

    init();
  }, [movie]);

  return (
    <>
      <Card variant="outlined" sx={{ minWidth: 100 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="body">
            Sub File: {subStatus ? 'Yes' : 'No'}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
