import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';

export default function CopyToClipboard({ value }: { value: string }) {
  return (
    <>
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(value);
        }}
        size="small"
      >
        <ContentCopyIcon fontSize="inherit" color="inherit" />
      </IconButton>
    </>
  );
}
