import { Card, CardActionArea, CardMedia, CardContent, Stack, Typography, Link, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import AppChip from '../../core/cmp/common/AppChip';
import ActionButton from '../../core/cmp/common/button/ActionButton';
import AppConfig from '../../core/config/AppConfig';
import { toDateOnly } from '../../wl/util/DateUtil';
import { MovieMetadata } from '../model/CrawlModel';
import { MovieCardOption } from '../../wl/model/Model';
import ModelUtil from '../../wl/util/ModelUtil';
import AppText from '../../core/cmp/common/AppText';

export default function MovieMetadataCard({
  movieMeta,
  option = ModelUtil.getFullMovieCardOption(),
  onScrapeItem,
  onScrapeCover,
  onDelete,
}: {
  movieMeta: MovieMetadata;
  option?: MovieCardOption;
  onScrapeItem: any;
  onScrapeCover: any;
  onDelete: any;
}) {
  useEffect(() => {}, [movieMeta]);

  return (
    <>
      <Card variant="outlined" sx={{ minWidth: 300, width: '100%' }}>
        <CardActionArea component="div" disableRipple>
          {option.showCover ? (
            <Link href={`/link/${movieMeta.linkId}`}>
              <CardMedia
                sx={{ height: 270, maxHeight: 640 }}
                image={movieMeta.cover ? `${AppConfig.CDN_URL}/movie-meta/cover/${movieMeta.id}.webp` : '/fdp.webp'}
                title={movieMeta.name}
              />
            </Link>
          ) : (
            ''
          )}
        </CardActionArea>
        <CardContent>
          <Stack spacing={1}>
            <Stack direction="row" spacing={1} alignContent="center" justifyContent="space-between">
              <Typography variant="body2" component="div" color="text.secondary" fontSize="small">
                {toDateOnly(movieMeta.releaseDate)}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
              {movieMeta.femaleCasts.map((cast, index) => (
                <AppChip key={index} label={cast} href={''} />
              ))}
            </Stack>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
              {movieMeta.maleCasts.map((cast, index) => (
                <AppChip key={index} label={cast} href={''} />
              ))}
            </Stack>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
              {movieMeta.casts.map((cast, index) => (
                <AppChip key={index} label={cast} href={''} />
              ))}
            </Stack>
            <Typography variant="body2" color="text.secondary">
              <Link href={movieMeta.source}>Source</Link>
            </Typography>
            <Tooltip title={movieMeta.description} placement="top" arrow>
              <Typography variant="body2" color="text.secondary" noWrap overflow="hidden" sx={{ maxWidth: 340 }}>
                {movieMeta.description}
              </Typography>
            </Tooltip>
            <AppText text={`Views: ${movieMeta.views.toLocaleString()}`} />
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <ActionButton label="Scrape Item" onClick={() => onScrapeItem(movieMeta.linkId)} />
            <ActionButton label="Scrape Cover" onClick={() => onScrapeCover(movieMeta.id)} />
            <ActionButton label={'Delete'} onClick={() => onDelete(movieMeta.id)} />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
