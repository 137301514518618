import { AxiosError } from 'axios';
import { ApiResponse, GrabTask, GrabTaskPageRequest, ItemPage } from '../model/Model';
import { watchlistSvc } from '../../core/config/AxioxConfig';
import PagingUtil from '../util/PagingUtil';

class GrabTaskSvc {
  private readonly URL: string = '/v1/grab-task';

  public async getAll(grabTaskPageRequest: GrabTaskPageRequest) {
    return (await watchlistSvc.get<ItemPage<GrabTask>>(`${this.URL}?${PagingUtil.getQuery(grabTaskPageRequest)}`)).data;
  }

  public async update(id: string, status: string) {
    try {
      return (await watchlistSvc.patch<ApiResponse>(`${this.URL}/${id}?status=${status}`)).data;
    } catch (ex: AxiosError | any) {
      console.error(ex);
      return {
        message: `Failed to update status for id: ${id}`,
      } as ApiResponse;
    }
  }
}

export default new GrabTaskSvc();
