import { Stack, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

export default function VerificationIconDisplay({ value }: { value: boolean }) {
  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <VerifiedUserIcon />
        <Typography variant="body2" color="text.secondary">
          {value == true ? <CheckBoxIcon fontSize="inherit" /> : <CheckBoxOutlineBlankIcon fontSize="inherit" />}
        </Typography>
      </Stack>
    </>
  );
}
