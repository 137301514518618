import { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import AppConstant from '../core/config/AppConstant';
import AppTextField from '../crawl/cmp/common/AppTextField';

export default function Player() {
  const [url, setUrl] = useState('');
  const init = async () => {};

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Stack spacing={1}>
        <AppTextField label={'URL...'} value={url} onChange={(value: string) => setUrl(value)} />
        <Button
          fullWidth
          variant="outlined"
          size="small"
          href={`${AppConstant.DEFAULT_MEDIA_PLAYER}://weblink?url=${url}`}
        >
          Play
        </Button>
      </Stack>
    </>
  );
}
