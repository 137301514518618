import { crawlSvc } from '../../core/config/AxioxConfig';
import { ApiResponse } from '../model/CrawlModel';

class QueueSvc {
  private readonly URL: string = '/v1/queue';

  public async scrapeItem(linkIds: string[]) {
    return (await crawlSvc.patch<ApiResponse>(`${this.URL}/scrape-item?linkIds=${linkIds.join(',')}`)).data;
  }

  public async scrapeCover(movieMetadataIds: string[]) {
    return (
      await crawlSvc.patch<ApiResponse>(`${this.URL}/scrape-cover?movieMetadataIds=${movieMetadataIds.join(',')}`)
    ).data;
  }
}

export default new QueueSvc();
