import { watchlistSvc } from '../../core/config/AxioxConfig';
import {
  ApiResponse,
  GrabMovieResponse,
  ItemPage,
  Source,
  SourceDto,
  SourceFetchRequest,
  SourceUpdateRequest,
} from '../model/Model';
import PagingUtil from '../util/PagingUtil';

class SourceDataService {
  private static URL: string = '/v1/source';

  public async fetch(id: string, sourceFetchRequest: SourceFetchRequest) {
    const query = PagingUtil.getFetchQuery(sourceFetchRequest);
    return (await watchlistSvc.get<GrabMovieResponse[]>(`${SourceDataService.URL}/${id}/fetch?${query}`)).data;
  }

  public async getAll() {
    return (await watchlistSvc.get<ItemPage<Source>>(SourceDataService.URL)).data;
  }

  public async get(id: string) {
    return (await watchlistSvc.get<Source>(`${SourceDataService.URL}/${id}`)).data;
  }

  public async save(req: SourceDto) {
    return (await watchlistSvc.post<ApiResponse>(SourceDataService.URL, req)).data;
  }

  public async update(id: string, req: SourceUpdateRequest) {
    return (await watchlistSvc.put<ApiResponse>(`${SourceDataService.URL}/${id}`, req)).data;
  }

  public async delete(id: string) {
    return (await watchlistSvc.delete<ApiResponse>(`${SourceDataService.URL}/${id}`)).data;
  }
}

export default new SourceDataService();
