import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import StudioDataService from '../../svc/StudioDataService';
import { Studio } from '../../model/Model';
import StudioCard from './StudioCard';

export default function MovieStudioChipList() {
  const { name } = useParams();
  const location = useLocation();
  const [studios, setStudios] = useState([] as Studio[]);

  const init = async () => {
    if (!location.pathname.startsWith('/movie')) return;

    setStudios((await StudioDataService.search(name!)).content);
  };

  useEffect(() => {
    if (name === undefined || location === undefined) return;
    init();
  }, []);

  return (
    <>
      {studios.map((studio, index) => (
        <StudioCard key={index} studio={studio} code={name} />
      ))}
    </>
  );
}
