import { CardMedia, CardContent, Typography, Stack, Link, CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import { Cast } from '../../model/Model';
import RatingIconDisplay from '../../../core/cmp/common/RatingIconDisplay';
import HeightIconDisplay from '../../../core/cmp/common/HeightIconDisplay';
import MovieCountIconDisplay from '../../../core/cmp/common/MovieCountIconDisplay';
import DateOfBirthIconDisplay from '../../../core/cmp/common/DateOfBirthIconDisplay';
import CoverIconDisplay from '../../../core/cmp/common/CoverIconDisplay';
import CopyToClipboard from '../../../core/cmp/common/CopyToClipboard';
import AppLink from '../../../core/cmp/common/AppLink';
import AppConfig from '../../../core/config/AppConfig';

export default function CastCard({ cast: cast }: { cast: Cast }) {
  const randomId = cast.id.charCodeAt(0).toString().slice(-1);
  const genderCode = cast.gender === 'FEMALE' ? 'F' : 'M';
  const defaultCover = `${AppConfig.CDN_URL}/asset/img/${`actor-${genderCode}-${randomId}.webp`}`;

  return (
    <>
      <Card sx={{ maxWidth: 340 }} variant={cast.verified ? 'outlined' : 'elevation'}>
        <CardActionArea component="div" disableRipple>
          <Link href={`/cast/${cast.id}`}>
            <CardMedia sx={{ height: 450 }} image={cast.cover ? cast.cover : defaultCover} title={cast.name} />
          </Link>
        </CardActionArea>
        <CardContent>
          <Typography gutterBottom component="div">
            <AppLink label={cast.name} href={`/cast/${cast.id}`} />
            <CopyToClipboard value={cast.name} />
          </Typography>
          <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" justifyContent="space-around">
            <RatingIconDisplay value={cast.rating} />
            <HeightIconDisplay value={cast.height} />
            <DateOfBirthIconDisplay value={cast.dob} />
            <CoverIconDisplay value={cast.cover} />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
