import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Studio } from '../../model/Model';
import { useEffect, useState } from 'react';
import StudioDataService from '../../svc/StudioDataService';
import ModelUtil from '../../util/ModelUtil';
import StudioCard from './StudioCard';
import { useSearchParams } from 'react-router-dom';
import ViewUtil from '../../util/ViewUtil';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LinkOpenerIcon from '../../../core/cmp/common/LinkOpenerIcon';
import CachedIcon from '@mui/icons-material/Cached';
import AppLink from '../../../core/cmp/common/AppLink';
import CopyToClipboard from '../../../core/cmp/common/CopyToClipboard';
import PageViewSelector from '../../../core/cmp/common/PageViewSelector';

export default function StudioPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState('');
  const [studios, setStudios] = useState([] as Studio[]);

  const init = async () => {
    setStudios((await StudioDataService.getPage()).content);
  };

  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  const handleAdd = async () => {
    if (keyword.trim().length < 1) return;
    await StudioDataService.save({ ...ModelUtil.getEmptyStudioRequest(), name: keyword });
    init();
  };

  const handleRefreshClick = () => init();

  const studioFilter = (i: Studio) => i.name.toLowerCase().includes(keyword.toLowerCase());

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Stack spacing={1}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <TextField
              id="input-search-keyword"
              label="Search.."
              variant="outlined"
              size="small"
              fullWidth
              value={keyword}
              onChange={handleKeywordChange}
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="outlined" startIcon={<CachedIcon />} fullWidth onClick={handleRefreshClick}>
              Refresh
            </Button>
          </Grid>
          <Grid item xs={4}>
            <PageViewSelector />
          </Grid>
        </Grid>
        {ViewUtil.isTableView(searchParams) ? (
          <StudioTable studios={studios.filter(studioFilter)} />
        ) : (
          <StudioGallery studios={studios.filter(studioFilter)} />
        )}
        {studios.filter(studioFilter).length < 1 ? <Button onClick={handleAdd}>Create: {keyword}</Button> : ''}
      </Stack>
    </>
  );
}

function StudioGallery({ studios }: { studios: Studio[] }) {
  return (
    <>
      <Grid container spacing={1}>
        {studios.map((studio, index) => (
          <Grid key={index} item xs={4}>
            <StudioCard studio={studio} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function StudioTable({ studios }: { studios: Studio[] }) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Link</TableCell>
              <TableCell align="center">Codes</TableCell>
              <TableCell align="center">Rating</TableCell>
              <TableCell align="center">Verified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studios.map((studio, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="center">{++index}</TableCell>
                <TableCell component="th" scope="row">
                  <AppLink label={studio.name} href={`/studio/${studio.id}`} />
                  <CopyToClipboard value={studio.name} />
                </TableCell>
                <TableCell>
                  {studio.link} {studio.link ? <LinkOpenerIcon value={`${studio.link}`} /> : ''}
                </TableCell>
                <TableCell>{studio.codes}</TableCell>
                <TableCell align="center">{studio.rating >= 0 ? studio.rating : ''}</TableCell>
                <TableCell align="center">{studio.verified ? <CheckBoxIcon fontSize="inherit" /> : ''}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
