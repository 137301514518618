import { crawlSvc } from '../../core/config/AxioxConfig';
import { ItemPage, MovieMetadataPageRequest } from '../../wl/model/Model';
import { ApiResponse, MovieMetadata } from '../model/CrawlModel';

class MovieMetadataSvc {
  private readonly URL: string = '/v1/movie-metadata';

  public async getPage(movieMetadataPageRequest: MovieMetadataPageRequest) {
    return (
      await crawlSvc.get<ItemPage<MovieMetadata>>(
        `${this.URL}?${MovieMetadataSvc.toRequestParam(movieMetadataPageRequest)}`
      )
    ).data;
  }

  public async delete(movieMetadataId: string) {
    return (await crawlSvc.delete<ApiResponse>(`${this.URL}/${movieMetadataId}`)).data;
  }

  private static toRequestParam(pageRequest: MovieMetadataPageRequest) {
    const params: URLSearchParams = new URLSearchParams({
      skip: ((pageRequest.page - 1) * pageRequest.pageSize).toString(),
      limit: pageRequest.pageSize.toString(),
      sortBy: pageRequest.sortBy,
      sortOrder: pageRequest.sortOrder,
      keyword: pageRequest.keyword,
    });

    if ('name' in pageRequest) {
      params.append('name', pageRequest.name);
    }

    if ('source' in pageRequest) {
      params.append('source', pageRequest.source);
    }

    if ('cast' in pageRequest) {
      params.append('cast', pageRequest.cast);
    }

    if ('femaleCast' in pageRequest) {
      params.append('femaleCast', pageRequest.femaleCast);
    }

    if ('maleCast' in pageRequest) {
      params.append('maleCast', pageRequest.maleCast);
    }

    if ('description' in pageRequest) {
      params.append('description', pageRequest.description);
    }

    return params.toString();
  }
}

export default new MovieMetadataSvc();
