import {
  Grid,
  Typography,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Switch,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Source, SourceRequest } from '../../model/CrawlModel';
import DataSvc from '../../svc/DataSvc';
import SourceEditableCard from './SourceEditableCard';
import AppLink from '../common/AppLink';

export default function CrawlSourcePage() {
  const [sources, setSources] = useState([] as Source[]);

  const handleAllowScrapeToggle = async (sourceId: string, value: boolean) => {
    console.log(sourceId);
    console.log(value);
  };

  const handleCreate = async (sourceRequest: SourceRequest) => {
    await DataSvc.createSource(sourceRequest);
    init();
  };

  const init = async () => {
    setSources(await DataSvc.getAllSource(''));
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Source
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Scrapper Id</TableCell>
                  <TableCell>Url</TableCell>
                  <TableCell>Allow Scrape</TableCell>
                  <TableCell>Source Item</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sources.map((source, k) => (
                  <TableRow key={k}>
                    <TableCell>{source.scrapperId}</TableCell>
                    <TableCell>{source.url}</TableCell>
                    <TableCell>
                      <Switch checked={source.allowScrape} disabled />
                    </TableCell>
                    <TableCell>{source.sourceItem}</TableCell>
                    <TableCell>
                      <AppLink href={`crawl/source/${source.id}`} label="Edit" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <SourceEditableCard onCreate={handleCreate} onUpdate={() => {}} source={null} />
        </Grid>
      </Grid>
    </>
  );
}
