import ListItemIcon from '@mui/material/ListItemIcon';
import { AppNavOption } from '../../wl/model/Model';
import { DashboardIcon, MovieIcon, CastIcon, TagIcon, SeriesIcon, RelationIcon, StudioIcon, ListIcon, SourceIcon, MineIcon, ImportIcon, SettingIcon } from '../cmp/common/Icon';


export default function SideDrawerMenuItemIcon({ open, appNavOption }: { open: boolean; appNavOption: AppNavOption }) {
  return (
    <>
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: open ? 3 : 'auto',
          justifyContent: 'center',
        }}
      >
        {['/', 'dashbaord'].includes(appNavOption.route) && <DashboardIcon />}
        {appNavOption.route === 'movie' && <MovieIcon />}
        {appNavOption.route === 'cast' && <CastIcon />}
        {appNavOption.route === 'tag' && <TagIcon />}
        {appNavOption.route === 'series' && <SeriesIcon />}
        {appNavOption.route === 'relation' && <RelationIcon />}
        {appNavOption.route === 'studio' && <StudioIcon />}
        {appNavOption.route === 'list' && <ListIcon />}
        {appNavOption.route === 'source' && <SourceIcon />}
        {appNavOption.route === 'mine' && <MineIcon />}
        {appNavOption.route === 'import' && <ImportIcon />}
        {appNavOption.route === 'setting' && <SettingIcon />}
      </ListItemIcon>
    </>
  );
}
