import AppConstant from './config/AppConstant';
import AppUtil from '../wl/util/AppUtil';

export default class Auth {
  public static getKey() {
    if (!this.keyExists()) {
      localStorage.setItem(AppConstant.API_KEY, prompt('Key')!);
    }
    return localStorage.getItem(AppConstant.API_KEY);
  }

  private static keyExists() {
    if (localStorage.getItem(AppConstant.API_KEY) == null) return false;
    if (AppUtil.isBlank(localStorage.getItem(AppConstant.API_KEY)!)) return false;

    return true;
  }
}
