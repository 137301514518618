import { Card, CardContent, CardActions, Grid, Stack, Button, ButtonGroup } from '@mui/material';
import CardField from '../../../core/cmp/common/CardField';
import { Count, Tag, TagType, TagUpdateRequest } from '../../model/Model';
import { useParams } from 'react-router-dom';
import TagDataService from '../../svc/TagDataService';
import { useEffect, useState } from 'react';
import RatingInput from '../../../core/cmp/common/RatingInput';
import TagCard from './TagCard';
import { AxiosError } from 'axios';
import DeleteButton from '../../../core/cmp/common/button/DeleteButton';
import UpdateButton from '../../../core/cmp/common/button/UpdateButton';
import AppCheckBox from '../../../core/cmp/common/form/AppCheckBox';
import AppTextField from '../../../core/cmp/common/form/AppTextField';
import { enqueueSnackbar } from 'notistack';

export default function TagDetailPage() {
  const { id } = useParams();
  const [tag, setTag] = useState({} as Tag);
  const [count, setCount] = useState({} as Count);

  const init = async (movieId: string) => {
    setCount(await TagDataService.getMovieCount());
    setTag(await TagDataService.getOne(movieId));
  };

  const updateHandler = async (id: string, request: TagUpdateRequest) => {
    await TagDataService.update(id, request);

    enqueueSnackbar('Updated');
    init(id);
  };

  const deleteHandler = async (id: string) => {
    if (!window.confirm('Confirm deletion!')) return;

    try {
      await TagDataService.delete(id);
      enqueueSnackbar(`Deleted item with id ${id}`);
      init(id);

      //@todo redirect
    } catch (ex: AxiosError | any) {
      enqueueSnackbar(ex.response.data.message);
    }
  };

  useEffect(() => {
    if (id === undefined) return;

    init(id);
  }, [id]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {tag.id ? <TagCard tag={tag} movieCount={count[tag.id]} /> : ''}
        </Grid>
        <Grid item xs={8}>
          {tag.id ? <TagEditableCard tag={tag} updateHandler={updateHandler} deleteHandler={deleteHandler} /> : ''}
        </Grid>
      </Grid>
    </>
  );
}

function TagEditableCard({ tag, updateHandler, deleteHandler }: { tag: Tag; updateHandler: any; deleteHandler: any }) {
  const [tagUpdateRequest, setTagUpdateRequest] = useState({
    name: '',
    rating: -1,
    type: TagType.UNKNOWN,
    verified: false,
  } as TagUpdateRequest);

  useEffect(() => {
    setTagUpdateRequest({
      name: tag.name,
      rating: tag.rating,
      type: tag.type,
      verified: tag.verified,
    });
  }, [tag]);

  return (
    <>
      <Card variant="outlined" sx={{ minWidth: 100 }}>
        <CardContent>
          <Stack spacing={1}>
            <CardField value={tag.name} />
            <AppTextField
              label="Name"
              value={tagUpdateRequest.name}
              onChange={(value: string) => {
                setTagUpdateRequest({ ...tagUpdateRequest, name: value });
              }}
            />
            <RatingInput
              rating={tag.rating}
              ratingChangeHandler={(rating: number) => updateHandler(tag.id, { ...tagUpdateRequest, rating: rating })}
            />
            <ButtonGroup variant="outlined" size="small">
              {Object.values(TagType).map((i, index) => (
                <Button
                  key={index}
                  variant={tag.type === i ? 'contained' : 'outlined'}
                  onClick={() => updateHandler(tag.id, { ...tagUpdateRequest, type: i })}
                >
                  {i.replaceAll('_', ' ')}
                </Button>
              ))}
            </ButtonGroup>
            <AppCheckBox
              checked={tagUpdateRequest.verified}
              label="Verified"
              onChange={(checked: boolean) => {
                const updatedTagUpdateRequest = { ...tagUpdateRequest, verified: checked };

                setTagUpdateRequest(updatedTagUpdateRequest);
                updateHandler(tag.id, updatedTagUpdateRequest);
              }}
            />
          </Stack>
        </CardContent>

        <CardActions>
          <UpdateButton onClick={() => updateHandler(tag.id, tagUpdateRequest)} />
          <DeleteButton onClick={() => deleteHandler(tag.id)} />
        </CardActions>
      </Card>
    </>
  );
}
