import { useState } from 'react';
import Card from '@mui/material/Card';
import { CardActions, CardContent, Stack } from '@mui/material';
import AppTextField from '../../../core/cmp/common/form/AppTextField';
import UpdateButton from '../../../core/cmp/common/button/UpdateButton';
import DeleteButton from '../../../core/cmp/common/button/DeleteButton';
import SingleAutoComplete from '../../../core/cmp/common/SingleAutoComplete';
import { GrabberType, LeanItem, Source, SourceDto } from '../../model/Model';
import Mapper from '../../util/Mapper';

export default function SourceEditableCard({
  source,
  onUpdate,
  onDelete,
}: {
  source: Source;
  onUpdate: any;
  onDelete: any;
}) {
  const [sourceUpdateRequest, setSourceUpdateRequest] = useState({
    url: source.url,
    sourceItem: source.sourceItem,
    grabberType: source.grabber.id,
  } as SourceDto);

  return (
    <>
      <Card sx={{ minWidth: '250px' }} variant="outlined">
        <CardContent>
          <Stack spacing={1}>
            <SingleAutoComplete
              label={'Grabber Type'}
              options={Object.keys(GrabberType).map((i) => ({ id: i, name: i } as LeanItem))}
              value={Mapper.toLeanItem(sourceUpdateRequest.grabberType, sourceUpdateRequest.grabberType)}
              onChange={(value: LeanItem | null) => {
                if (value == null) return;

                setSourceUpdateRequest({
                  ...sourceUpdateRequest,
                  grabberType: GrabberType[value.id as keyof typeof GrabberType],
                });
              }}
            />
            <AppTextField
              label="URL"
              value={sourceUpdateRequest.url}
              onChange={(value: string) => setSourceUpdateRequest({ ...sourceUpdateRequest, url: value })}
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <UpdateButton onClick={() => onUpdate('', sourceUpdateRequest)} />
            <DeleteButton onClick={() => onDelete('')} />
          </Stack>
        </CardActions>
      </Card>
    </>
  );
}
