export default class StringUtil {
  public static enumToLabel(enumValue: string) {
    return enumValue
      .split('_')
      .map((i) => this.capatilize(i))
      .join(' ');
  }

  public static capatilize(value: string) {
    if (value.length === 0) return '';

    return value.charAt(0).toUpperCase() + value.toLocaleLowerCase().slice(1);
  }
}
