import { watchlistSvc } from '../../core/config/AxioxConfig';
import { ApiResponse, ItemCount, ItemType } from '../model/Model';

class ItemStatDataService {
  private URL: string = '/v1/item-stat';

  public async getItemViewCount(itemId: string): Promise<ItemCount<string>> {
    return (await watchlistSvc.get<ItemCount<string>>(`${this.URL}?itemId=${itemId}&itemStatType=VIEW`)).data;
  }

  public async update(id: string, itemType: ItemType): Promise<ApiResponse> {
    return (await watchlistSvc.patch<ApiResponse>(`${this.URL}/${id}?itemType=${itemType}`)).data;
  }
}

export default new ItemStatDataService();
