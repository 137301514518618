import Grid from '@mui/material/Grid';
import { Count, Series } from '../../model/Model';
import SeriesCard from './SeriesCard';

export default function SeriesGallery({ series, movieCount }: { series: Series[]; movieCount: Count }) {
  return (
    <>
      <Grid container spacing={2}>
        {series.map((s) => (
          <Grid key={s.id} container item xs={4}>
            <SeriesCard series={s} movieCount={movieCount[s.id]} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
