import { IconButton, Divider, List } from '@mui/material';
import SideDrawerMenuItem from './SideDrawerMenuItem';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DrawerHeader from './AppSideDrawerHeader';
import { AppNavOption } from '../../wl/model/Model';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function AppSideDrawer({
  open,
  appNavOptions,
  handleDrawerClose,
}: {
  open: boolean;
  appNavOptions: AppNavOption[];
  handleDrawerClose: any;
}) {
  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        sx: {
          top: -16,
        },
      }}
    >
      <DrawerHeader>
        <IconButton size="small" onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {appNavOptions.map((appNavOption, index) => (
          <SideDrawerMenuItem key={index} appNavOption={appNavOption} open={open} />
        ))}
      </List>
      <Divider />
    </Drawer>
  );
}
