import Grid from '@mui/material/Grid/Grid';
import { Count, Tag } from '../../model/Model';
import TagCard from './TagCard';

export default function TagGallery({ tags, count }: { tags: Tag[]; count: Count }) {
  return (
    <>
      <Grid container spacing={1}>
        {tags.map((tag, index) => (
          <Grid key={index} item xs={3} sm={2}>
            <TagCard tag={tag} movieCount={count[tag.id]} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
