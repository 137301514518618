import Typography from '@mui/material/Typography';

export default function AppText({ text }: { text: string }) {
  return (
    <>
      <Typography variant="body2" color="text.secondary" fontSize="small">
        {text}
      </Typography>
    </>
  );
}
