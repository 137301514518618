import { Card, CardContent, Typography } from '@mui/material';
import { Grabber } from '../../model/Model';
import AppLink from '../../../core/cmp/common/AppLink';

export default function GrabberCard({ grabber }: { grabber: Grabber }) {
  return (
    <>
      <Card sx={{ width: '100%' }} variant="outlined">
        <CardContent>
          <Typography variant="body2">
            <AppLink label={grabber.id} href={`/grabber/${grabber.id}`} />
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
