import { useEffect, useState } from 'react';
import { Grabber, Source, SourceDto } from '../../model/Model';
import { Grid, Typography } from '@mui/material';
import SourceDataService from '../../svc/SourceDataService';
import SourceCard from './SourceCard';
import GrabberSvc from '../../svc/GrabberSvc';
import GrabberCard from './GrabberCard';
import AddSourceCard from './AddSourceCard';

export default function SourcePage() {
  const [sources, setSources] = useState([] as Source[]);
  const [grabbers, setGrabbers] = useState([] as Grabber[]);

  const init = async () => {
    setSources((await SourceDataService.getAll()).content);
    setGrabbers(await GrabberSvc.getAll());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Source
          </Typography>
        </Grid>
        {sources.map((source, index) => (
          <Grid key={index} item xs={4}>
            <SourceCard source={source} />
          </Grid>
        ))}
        <Grid item xs={4}>
          <AddSourceCard
            onClick={async (value: SourceDto) => {
              await SourceDataService.save(value);
              init();
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="div">
            Grabber
          </Typography>
        </Grid>
        {grabbers.map((grabber, index) => (
          <Grid key={index} item xs={3}>
            <GrabberCard grabber={grabber} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
