import { watchlistSvc } from '../../core/config/AxioxConfig';
import {
  LeanItem,
  ItemPage,
  Movie,
  ApiResponse,
  StatRequest,
  Counts,
  Agg,
  MovieRequest,
  MovieViewLog,
  MoviePagingRequest,
  MovieStatus,
} from '../model/Model';
import PagingUtil from '../util/PagingUtil';

class MovieDataService {
  private readonly URL: string = '/v1/movie';

  public async getPage(request: MoviePagingRequest) {
    return (await watchlistSvc.get<ItemPage<Movie>>(`${this.URL}?${PagingUtil.getQuery(request)}`)).data;
  }

  public async getByName(name: string) {
    return (await watchlistSvc.get<Movie>(`${this.URL}/${name}`)).data;
  }

  public async count(countBy: string) {
    return (await watchlistSvc.get<Agg[]>(`${this.URL}/count?countBy=${countBy}`)).data;
  }

  public async getFilterPresets() {
    return (await watchlistSvc.get<LeanItem[]>(`/v1/filter-preset/movie`)).data;
  }

  public async getStatuses() {
    return (await watchlistSvc.get<LeanItem[]>(`${this.URL}/statuses`)).data;
  }

  public async checkSub(name: string) {
    try {
      (await watchlistSvc.get<ApiResponse>(`${this.URL}/${name}/sub-file`)).data;
    } catch (ex: any) {
      return false;
    }
    return true;
  }

  public async syncSubStatus(id: string) {
    return (await watchlistSvc.patch<ApiResponse>(`${this.URL}/${id}/sub-file`)).data;
  }

  public async logView(id: string) {
    return (await watchlistSvc.patch<ApiResponse>(`${this.URL}/${id}/movie-view-log`)).data;
  }

  public async getMovieViewLogs(id: string) {
    return (await watchlistSvc.get<MovieViewLog[]>(`${this.URL}/${id}/movie-view-log`)).data;
  }

  public async getStat(request: StatRequest): Promise<Counts> {
    return (await watchlistSvc.get<Counts>(`${this.URL}/stat?${PagingUtil.getStatRequestQuery(request)}`)).data;
  }

  public async save(req: MovieRequest) {
    return (await watchlistSvc.post<ApiResponse>(this.URL, req)).data;
  }

  public async update(id: string, req: any) {
    return (await watchlistSvc.put<ApiResponse>(`${this.URL}/${id}`, req)).data;
  }

  public async updateStatus(id: string, status: MovieStatus) {
    return (await watchlistSvc.patch<ApiResponse>(`${this.URL}/${id}?status=${status}`)).data;
  }

  public async delete(id: string) {
    return (await watchlistSvc.delete<ApiResponse>(`${this.URL}/${id}`)).data;
  }
}

export default new MovieDataService();
