import { watchlistSvc } from '../../core/config/AxioxConfig';
import { ApiResponse, ItemPage } from '../model/Model';

class DataService<T> {
  protected URL: string = '';

  public async getAll(query: string) {
    return (await watchlistSvc.get<ItemPage<T>>(`${this.URL}?${query}`)).data;
  }

  public async getOne(id: string) {
    return (await watchlistSvc.get<T>(`${this.URL}/${id}`)).data;
  }

  public async save(req: any) {
    return (await watchlistSvc.post<ApiResponse>(this.URL, req)).data;
  }

  public async update(id: string, req: any) {
    return (await watchlistSvc.put<ApiResponse>(`${this.URL}/${id}`, req)).data;
  }

  public async delete(id: string) {
    return (await watchlistSvc.delete<ApiResponse>(`${this.URL}/${id}`)).data;
  }
}

export default DataService;
