import { Card, CardContent, Typography } from '@mui/material';
import { Tag } from '../../model/Model';
import CardField from '../../../core/cmp/common/CardField';
import CopyToClipboard from '../../../core/cmp/common/CopyToClipboard';
import AppLink from '../../../core/cmp/common/AppLink';
import CheckBoxDisplay from '../../../core/cmp/common/display/CheckBoxDisplay';

export default function TagCard({ tag, movieCount }: { tag: Tag; movieCount: number }) {
  return (
    <>
      <Card variant="outlined" sx={{ minWidth: 100 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            <AppLink label={tag.name} href={`/tag/${tag.id}`} />
            <CopyToClipboard value={tag.name} />
          </Typography>
          <Typography variant="h6" component="div">
            {movieCount}
          </Typography>
          <CardField value={`Type: ${tag.type}`} />
          <CardField value={`Rating: ${tag.rating}`} />
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            Verified: <CheckBoxDisplay checked={tag.verified} />
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
