import { useEffect, useState } from 'react';
import AppCheckBox from '../../../core/cmp/common/form/AppCheckBox';
import TagDataService from '../../svc/TagDataService';
import { LeanItem, SortOrder, Tag } from '../../model/Model';
import { Stack } from '@mui/material';
import _, { Dictionary } from 'lodash';
import Mapper from '../../util/Mapper';

export default function TagQuickSelector({ selectedTags, onChange }: { selectedTags: string[]; onChange: any }) {
  const [tags, setTags] = useState([] as LeanItem[]);

  const init = async () => {
    const tagsByUsage = (
      await TagDataService.getPage({
        page: 1,
        pageSize: 12,
        sortBy: 'usageRank',
        sortOrder: 'DESC' as SortOrder,
        keyword: '',
      })
    ).content.map((tag) => Mapper.toLeanItem(tag.id, tag.name));

    const tagsByRating = (
      await TagDataService.getPage({
        page: 1,
        pageSize: 8,
        sortBy: 'rating',
        sortOrder: 'DESC' as SortOrder,
        keyword: '',
      })
    ).content.map((tag) => Mapper.toLeanItem(tag.id, tag.name));

    const topTags: LeanItem[] = [];
    tagsByUsage.forEach((i) => topTags.push(i));
    const tagsByUsageIds = tagsByUsage.map((i) => i.id);
    tagsByRating.filter((i) => !tagsByUsageIds.includes(i.id)).forEach((i) => topTags.push(i));

    setTags(topTags);
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Stack direction="row" spacing={0} useFlexGap flexWrap="wrap">
        {tags
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((tag, index) => (
            <AppCheckBox
              key={index}
              checked={selectedTags.includes(tag.id)}
              label={tag.name}
              onChange={(checked: boolean) => onChange(tag.id, checked)}
            />
          ))}
      </Stack>
    </>
  );
}
