import Slider from '@mui/material/Slider';

export default function AppSlider({ value, onChange }: { value: number[]; onChange: any }) {
  return (
    <>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={(event: Event, newValue: number | number[]) => {
          onChange(newValue);
        }}
        // min={-1}
        // max={100}
        valueLabelDisplay="auto"
        size='small'
      />
    </>
  );
}
