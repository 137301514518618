import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import { LeanItem } from '../../../wl/model/Model';
import React from 'react';

export default function AutoCompleteV3({
  label,
  options,
  values,
  onChange,
}: {
  label: string;
  options: LeanItem[];
  values: LeanItem[];
  onChange: any;
}) {
  const [inputValue, setInputValue] = React.useState('');

  return (
    <>
      <Autocomplete
        size="small"
        multiple
        options={options}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={values}
        onChange={(event: React.SyntheticEvent, values: LeanItem[], reason: AutocompleteChangeReason) => {
          onChange(values);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        defaultValue={[]}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </>
  );
}
