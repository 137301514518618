export default class AppConstant {
  public static readonly DEFAULT_MEDIA_PLAYER = 'iina';
  public static readonly API_KEY = 'ak';
  public static readonly CODE_PLACEHOLDER = '${CODE}';
  public static readonly CODE_WITHOUT_DASH_PLACEHOLDER = '${CODE!_}';
  public static readonly DEFAULT_YEAR = 1900;
  public static readonly CURRENT_YEAR = new Date().getFullYear();
  public static readonly ITEM_SEPARATOR = ';';
  public static readonly MEDIA_DIR = '/Volumes/Backup01/media/';
}
