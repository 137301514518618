import {
  CastPagingRequest,
  GrabTaskPageRequest,
  LeanItem,
  LinkPageRequest,
  ListPageRequest,
  MinePageRequest,
  MoviePagingRequest,
  PageRequest,
  SortOrder,
  SourceFetchRequest,
  StatRequest,
} from '../model/Model';

export default class PagingUtil {
  public static getRandomPage(totalItems: number, pageSize: number): number {
    const randPage = Math.random() * (Math.ceil(totalItems / pageSize) - 1) + 1;
    return Math.ceil(randPage);
  }

  public static getOffset(pageRequest: PageRequest): number {
    return (pageRequest.page - 1) * pageRequest.pageSize;
  }

  public static getTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }

  public static getSkip(page: number, pageSize: number): number {
    return (page - 1) * pageSize;
  }

  public static getFetchQuery(sourceFetchRequest: SourceFetchRequest) {
    const params: URLSearchParams = new URLSearchParams({
      sourceId: sourceFetchRequest.sourceId,
      page: sourceFetchRequest.page,
      rawQuery: sourceFetchRequest.rawQuery,
    });

    return PagingUtil.clean(params).toString();
  }

  public static getStatRequestQuery(request: StatRequest) {
    const params: URLSearchParams = new URLSearchParams({
      ids: PagingUtil.toQueryParameter(request.ids),
      statItems: PagingUtil.toQueryParameter(request.statItems),
    });

    return PagingUtil.clean(params).toString();
  }

  // public static toQuery(pageRequest?: MoviePagingRequest | CastPagingRequest | PageRequest): string {
  //   if (pageRequest == null) return '';

  //   const params: URLSearchParams = new URLSearchParams({
  //     skip: this.getSkip(pageRequest.page, pageRequest.pageSize).toString(),
  //     limit: pageRequest.pageSize.toString(),
  //     sortBy: pageRequest.sortBy,
  //     sortOrder: pageRequest.sortOrder,
  //     keyword: pageRequest.keyword,
  //   });

  //   //// actor

  //   if ('gender' in pageRequest) {
  //     params.append('gender', pageRequest.gender);
  //   }

  //   if ('actor' in pageRequest) {
  //     params.append('actor', new Boolean(pageRequest.actor).toString());
  //   }

  //   if ('director' in pageRequest) {
  //     params.append('director', new Boolean(pageRequest.director).toString());
  //   }

  //   if ('verified' in pageRequest) {
  //     params.append('verified', new Boolean(pageRequest.verified).toString());
  //   }

  //   if ('watch' in pageRequest) {
  //     params.append('watch', new Boolean(pageRequest.watch).toString());
  //   }

  //   ////
  //   if ('ids' in pageRequest) {
  //     params.append('ids', PagingUtil.toQueryParameter(pageRequest.ids));
  //   }

  //   if ('names' in pageRequest) {
  //     params.append('names', PagingUtil.toQueryParameter(pageRequest.names));
  //   }

  //   if ('tags' in pageRequest) {
  //     params.append('tags', PagingUtil.leanItemstoQueryParameter(pageRequest.tags));
  //   }

  //   if ('excludeTags' in pageRequest) {
  //     params.append('excludeTags', PagingUtil.leanItemstoQueryParameter(pageRequest.excludeTags));
  //   }

  //   if ('statuses' in pageRequest) {
  //     params.append('statuses', PagingUtil.leanItemstoQueryParameter(pageRequest.statuses));
  //   }

  //   if ('actresses' in pageRequest) {
  //     params.append('actresses', PagingUtil.leanItemstoQueryParameter(pageRequest.actresses));
  //   }

  //   if ('actors' in pageRequest) {
  //     params.append('actors', PagingUtil.leanItemstoQueryParameter(pageRequest.actors));
  //   }

  //   if ('series' in pageRequest) {
  //     params.append('series', PagingUtil.leanItemstoQueryParameter(pageRequest.series));
  //   }

  //   if ('directors' in pageRequest) {
  //     params.append('directors', PagingUtil.leanItemstoQueryParameter(pageRequest.directors));
  //   }

  //   if ('presetFilters' in pageRequest) {
  //     params.append('presetFilters', PagingUtil.leanItemstoQueryParameter(pageRequest.presetFilters));
  //   }

  //   if ('releaseYears' in pageRequest) {
  //     params.append('releaseYears', PagingUtil.leanItemstoQueryParameter(pageRequest.releaseYears));
  //   }

  //   if ('description' in pageRequest) {
  //     params.append('description', pageRequest.description);
  //   }

  //   return PagingUtil.clean(params).toString();
  // }

  public static getQuery(
    pageRequest?:
      | CastPagingRequest
      | MoviePagingRequest
      | ListPageRequest
      | MinePageRequest
      | PageRequest
      // | GrabTaskPageRequest
      | LinkPageRequest
  ): string {
    if (pageRequest == null) return '';

    const params: URLSearchParams = new URLSearchParams({
      skip: this.getSkip(pageRequest.page, pageRequest.pageSize).toString(),
      limit: pageRequest.pageSize.toString(),
      sortBy: pageRequest.sortBy,
      sortOrder: pageRequest.sortOrder,
      keyword: pageRequest.keyword,
    });

    if ('ids' in pageRequest) {
      params.append('ids', PagingUtil.toQueryParameter(pageRequest.ids));
    }

    if ('names' in pageRequest) {
      params.append('names', PagingUtil.toQueryParameter(pageRequest.names));
    }

    if ('tags' in pageRequest) {
      params.append('tags', PagingUtil.leanItemstoQueryParameter(pageRequest.tags));
    }

    if ('excludeTags' in pageRequest) {
      params.append('excludeTags', PagingUtil.leanItemstoQueryParameter(pageRequest.excludeTags));
    }

    if ('statuses' in pageRequest) {
      params.append('statuses', PagingUtil.leanItemstoQueryParameter(pageRequest.statuses));
    }

    if ('actresses' in pageRequest) {
      params.append('actresses', PagingUtil.leanItemstoQueryParameter(pageRequest.actresses));
    }

    if ('actors' in pageRequest) {
      params.append('actors', PagingUtil.leanItemstoQueryParameter(pageRequest.actors));
    }

    if ('series' in pageRequest) {
      params.append('series', PagingUtil.leanItemstoQueryParameter(pageRequest.series));
    }

    if ('directors' in pageRequest) {
      params.append('directors', PagingUtil.leanItemstoQueryParameter(pageRequest.directors));
    }

    if ('presetFilters' in pageRequest) {
      params.append('presetFilters', PagingUtil.leanItemstoQueryParameter(pageRequest.presetFilters));
    }

    if ('releaseYears' in pageRequest) {
      params.append('releaseYears', PagingUtil.leanItemstoQueryParameter(pageRequest.releaseYears));
    }

    if ('description' in pageRequest) {
      params.append('description', pageRequest.description);
    }

    if ('gender' in pageRequest) {
      params.append('gender', pageRequest.gender);
    }

    if ('actor' in pageRequest) {
      params.append('actor', new Boolean(pageRequest.actor).toString());
    }

    if ('director' in pageRequest) {
      params.append('director', new Boolean(pageRequest.director).toString());
    }

    if ('verified' in pageRequest) {
      params.append('verified', new Boolean(pageRequest.verified).toString());
    }

    if ('watch' in pageRequest) {
      params.append('watch', new Boolean(pageRequest.watch).toString());
    }

    if ('itemId' in pageRequest) {
      params.append('itemId', pageRequest.itemId);
    }

    if ('lists' in pageRequest) {
      params.append('lists', PagingUtil.toQueryParameter(pageRequest.lists));
    }

    if ('includeImported' in pageRequest) {
      params.append('includeImported', new Boolean(pageRequest.includeImported).toString());
    }

    if ('ratings' in pageRequest) {
      params.append('ratings', PagingUtil.numToQueryParameter(pageRequest.ratings));
    }

    if ('url' in pageRequest) {
      params.append('url', pageRequest.url);
    }

    if ('cast' in pageRequest) {
      params.append('cast', pageRequest.cast);
    }

    if ('femaleCast' in pageRequest) {
      params.append('femaleCast', pageRequest.femaleCast);
    }

    if ('maleCast' in pageRequest) {
      params.append('maleCast', pageRequest.maleCast);
    }

    // if ('castCounts' in pageRequest) {
    //   params.append('ratings', PagingUtil.numToQueryParameter(pageRequest.castCounts));
    // }

    return PagingUtil.clean(params).toString();
  }

  private static clean(params: URLSearchParams): URLSearchParams {
    const cleaned = new URLSearchParams();
    params.forEach((value, key) => {
      if (PagingUtil.isNotBlank(value)) {
        cleaned.append(key, value);
      }
    });
    return cleaned;
  }

  public static getDefaultMoviePagingRequest(): MoviePagingRequest {
    return {
      page: 1,
      pageSize: 3,
      keyword: '',
      names: [],
      description: '',
      ids: [],
      actors: [],
      actresses: [],
      directors: [],
      tags: [],
      series: [],
      excludeTags: [],
      lists: [],
      presetFilters: [],
      sortBy: 'updatedAt',
      sortOrder: 'DESC' as SortOrder,
      releaseYears: [],
      ratings: [-1, 100],
      castCounts: [-1, -1],
      statuses: [],
      watch: false,
      verified: false,
    } as MoviePagingRequest;
  }

  public static getDefaultCastPagingRequest(): CastPagingRequest {
    return {
      page: 1,
      pageSize: 12,
      keyword: '',
      sortBy: 'updatedAt',
      sortOrder: 'DESC' as SortOrder,
      gender: 'FEMALE',
      actor: true,
      director: false,
      verified: false,
      watch: false,
    };
  }

  public static getDefaultListPageRequest(): ListPageRequest {
    return {
      page: 1,
      pageSize: 25,
      keyword: '',
      sortBy: 'updatedAt',
      sortOrder: SortOrder.DESC,
      itemId: '',
    };
  }

  public static getDefaultMinePageRequest(): MinePageRequest {
    return {
      page: 1,
      pageSize: 25,
      keyword: '',
      sortBy: 'updatedAt',
      sortOrder: SortOrder.DESC,
      includeImported: false,
    };
  }

  public static getDefaultGrabTaskPageRequest(): GrabTaskPageRequest {
    return {
      page: 1,
      pageSize: 10,
      keyword: '',
      sortBy: 'createdAt',
      sortOrder: SortOrder.DESC,
      statuses: ['FAILED', 'SKIPPED'],
    };
  }

  private static toQueryParameter(values: string[]): string {
    return values.join(',');
  }

  private static leanItemstoQueryParameter(values: LeanItem[]): string {
    return values.map((i) => i.id).join(',');
  }

  private static numToQueryParameter(values: number[]): string {
    return values.join(',');
  }

  private static isNotBlank(value: string): boolean {
    return value.trim().length > 0;
  }

  private static isNotEmpty(value: string[]): boolean {
    return value.length > 0;
  }
}
