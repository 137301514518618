import StarIcon from '@mui/icons-material/Star';
import { Stack, Typography } from '@mui/material';

export default function RatingIconDisplay({ value }: { value: number }) {
  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <StarIcon titleAccess="Rating" />
        <Typography variant="body2" color="text.secondary">
          {value === -1 ? '-' : value}
        </Typography>
      </Stack>
    </>
  );
}
