import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MovieDataService from '../../svc/MovieDataService';
import { ImportType, LeanItem, Movie, MovieRequest, MovieStatus, SortOrder } from '../../model/Model';
import MovieEditableCard from './MovieEditableCard';
import Grid from '@mui/material/Grid';
import MovieCard from './MovieCard';
import MovieSearch from './MovieSearch';
import MovieStudioChipList from '../studio/MovieStudioChipList';
import GrabService from '../../svc/GrabService';
import MovieSubStatus from './MovieSubStatus';
import { Stack } from '@mui/material';
import MovieStatCard from './MovieStatCard';
import ItemMovieChipList from './ItemMovieChipList';
import TagDataService from '../../svc/TagDataService';
import PagingUtil from '../../util/PagingUtil';
import DataUtil from '../../util/DataUtil';
import RelatedItemEditableCard from '../list/RelatedItemEditableCard';
import RelationService from '../../svc/RelationService';
import Mapper from '../../util/Mapper';
import MovieMetadataDynamicCard from '../../../crawl/cmp/MovieMetadataDynamicCard';
import { enqueueSnackbar } from 'notistack';
import ModelUtil from '../../util/ModelUtil';

export default function MovieDetailPage() {
  const { name } = useParams();
  const [movie, setMovie] = useState({} as Movie);
  const [tags, setTags] = useState([] as LeanItem[]);
  const [relatedMovies, setRelatedMovies] = useState([] as LeanItem[]);

  const updateHandler = async (id: string, movieRequest: MovieRequest) => {
    await MovieDataService.update(id, movieRequest);
    init();
  };

  const imageUpdateHandler = async (id: string, movieRequest: MovieRequest) => {
    await GrabService.grabMovieCover(movieRequest.name);
    init();
  };

  const deleteHandler = async (id: string) => {
    await MovieDataService.delete(id);
    init();
  };

  const importHandler = async (name: string, importType: ImportType) => {
    if (importType === ImportType.DETAIL) {
      enqueueSnackbar((await GrabService.grabMovie(name)).message);
    } else if (importType === ImportType.SUB) {
      enqueueSnackbar((await GrabService.grabSub(name)).message);
    } else if (importType === ImportType.TR) {
      enqueueSnackbar((await GrabService.grabTr(name)).message);
    }
    init();
  };

  const onMovieDetailGrab = async (name: string) => {
    enqueueSnackbar((await GrabService.grabMovie(name)).message);
    init();
  };

  const onMovieSubGrab = async (name: string) => {
    enqueueSnackbar((await GrabService.grabSub(name)).message);
    init();
  };

  const onStatusChange = async (id: string, status: MovieStatus) => {
    enqueueSnackbar((await MovieDataService.updateStatus(id, status)).message);
    init();
  };

  const init = async () => {
    if (name === undefined) return;
    const movieResult = await MovieDataService.getByName(name!!);
    setMovie(movieResult);
    setRelatedMovies((await RelationService.getByItem(movieResult.id)).flatMap((i) => i.items));

    MovieDataService.logView(movieResult.id);
  };

  const initOnce = async () => {
    setTags((await TagDataService.getPage()).content.map((tag) => Mapper.toLeanItem(tag.id, tag.name)));
  };

  useEffect(() => {
    if (name === undefined) return;
    init();
  }, [name]);

  useEffect(() => {
    initOnce();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Stack spacing={1}>
            {movie.id ? (
              <MovieCard movie={movie} onMovieDetailGrab={onMovieDetailGrab} onMovieSubGrab={onMovieSubGrab} />
            ) : (
              ''
            )}
            {movie.id ? (
              <MovieMetadataDynamicCard
                name={movie.name}
                option={{ ...ModelUtil.getFullMovieCardOption(), showCover: false }}
              />
            ) : (
              ''
            )}
            {movie.id ? <MovieStatCard movieId={movie.id} /> : ''}
            {movie.id ? <MovieSubStatus movie={movie} /> : ''}
          </Stack>
        </Grid>
        <Grid item xs={8}>
          {movie.id ? (
            <MovieEditableCard
              movie={movie}
              updateHandler={updateHandler}
              imageUpdateHandler={imageUpdateHandler}
              deleteHandler={deleteHandler}
            />
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          {movie.name ? <MovieSearch code={movie.name} /> : ''}
        </Grid>
        <Grid item xs={4}>
          <MovieStudioChipList />
        </Grid>
        <Grid item xs={4}>
          {movie.id ? <RelatedItemEditableCard item={movie.id} /> : ''}
        </Grid>
        {relatedMovies.length > 0 ? (
          <Grid item xs={12}>
            <ItemMovieChipList
              label={`Related`}
              pageRequest={{
                ...PagingUtil.getDefaultMoviePagingRequest(),
                pageSize: 3,
                sortOrder: 'ASC' as SortOrder,
                ids: relatedMovies.map((i) => i.id),
              }}
            />
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          {movie.id && movie.casts.filter(DataUtil.FEMALE_CAST_FILTER).length > 0 && tags.length > 0 ? (
            <ItemMovieChipList
              label={`More from cast`}
              pageRequest={{
                ...PagingUtil.getDefaultMoviePagingRequest(),
                pageSize: 12,
                sortBy: 'updatedAt',
                sortOrder: 'ASC' as SortOrder,
                actresses: movie.casts.filter(DataUtil.FEMALE_CAST_FILTER).map((i) => Mapper.toLeanItem(i.id, i.name)),
                tags: tags
                  .filter((i) => ['Watchlist', 'Bookmark'].includes(i.name))
                  .map((i) => Mapper.toLeanItem(i.id, i.name)),
              }}
            />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </>
  );
}
