import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import MovieList from './MovieList';
import { Movie, MoviePagingRequest, SortOrder } from '../../model/Model';
import MovieDataService from '../../svc/MovieDataService';
import PagingUtil from '../../util/PagingUtil';
import LinkOpenerIcon from '../../../core/cmp/common/LinkOpenerIcon';
import GrabService from '../../svc/GrabService';
import AppTextField from '../../../core/cmp/common/form/AppTextField';
import { enqueueSnackbar } from 'notistack';

interface MovieMap {
  [k: string]: Movie;
}

export default function MovieImportPage() {
  const moviePageRequest = {
    ...PagingUtil.getDefaultMoviePagingRequest(),
    pageSize: 3,
    sortBy: 'createdAt',
    sortOrder: 'DESC' as SortOrder,
  } as MoviePagingRequest;

  const [movies, setMovies] = useState([] as Movie[]);

  const handleImport = async (code: string) => {
    enqueueSnackbar((await GrabService.grabMovie(code)).message);
    init(moviePageRequest);
  };

  const init = async (request: MoviePagingRequest) => {
    setMovies((await MovieDataService.getPage(request)).content);
  };

  useEffect(() => {
    init(moviePageRequest);
  }, []);

  return (
    <>
      <Stack spacing={0}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <MovieChecker importHandler={handleImport} />
          </Grid>
          <Grid item xs={10}>
            <MovieList movies={movies} />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

function MovieChecker({ importHandler }: { importHandler: any }) {
  const moviePageRequest = { ...PagingUtil.getDefaultMoviePagingRequest(), pageSize: 9999 };
  const [movieMap, setMovieMap] = useState({} as MovieMap);
  const [nameListInput, setNameListInput] = useState('' as string);
  const [names, setNames] = useState([] as string[]);

  const checkHandler = () => {
    setNames(
      nameListInput
        .trim()
        .replaceAll('\n', ',')
        .replaceAll(' ', ',')
        .replaceAll(`'`, '')
        .split(',')
        .map((i) => i.replaceAll(`'`, '').trim())
    );
  };

  const checkExitingMovies = async () => {
    const movies = (await MovieDataService.getPage({ ...moviePageRequest, names: names })).content;
    const mapHolder = {} as MovieMap;
    movies.forEach((movie) => {
      mapHolder[movie.name] = movie;
    });
    setMovieMap(mapHolder);
  };

  useEffect(() => {
    checkExitingMovies();
  }, [names]);

  const init = async () => {};

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AppTextField label="Code.." value={nameListInput} onChange={(value: string) => setNameListInput(value)} />
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" disabled={nameListInput.trim().length < 1} fullWidth onClick={checkHandler}>
            Check
          </Button>
        </Grid>
        <Grid item xs={12}>
          <CodeMineTable movieNames={names} movieMap={movieMap} importHandler={importHandler} />
        </Grid>
      </Grid>
    </>
  );
}

function CodeMineTable({
  movieNames,
  movieMap,
  importHandler,
}: {
  movieNames: string[];
  movieMap: MovieMap;
  importHandler: any;
}) {
  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
        <Table stickyHeader sx={{ minWidth: 150 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Add</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {movieNames
              .filter((name) => !movieMap[name])
              .sort()
              .map((name, index) => (
                <MovieRow key={index} name={name} movie={movieMap[name]} importHandler={importHandler} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
        {movieNames
          .filter((name) => movieMap[name])
          .map((name) => (
            <>
              {name} <LinkOpenerIcon value={`/movie/${name}`} />
            </>
          ))}
      </Stack>
    </>
  );
}

function MovieRow({ name, movie, importHandler }: { name: string; movie: Movie | null; importHandler: any }) {
  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          {name} {movie ? <LinkOpenerIcon value={`/movie/${movie.name}`} /> : ''}
        </TableCell>
        <TableCell>
          {movie ? 'Yes' : ''}
          {!movie ? (
            <Button variant="outlined" size="small" onClick={() => importHandler(name)}>
              +
            </Button>
          ) : (
            ''
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
