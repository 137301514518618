import { Movie, MovieCardOption } from '../../model/Model';
import ModelUtil from '../../util/ModelUtil';
import MovieCard from './MovieCard';
import Grid from '@mui/material/Grid';

export default function MovieList({
  movies,
  onMovieSubGrab = null,
  onMovieDetailGrab = null,
  onBookmark = null,
  option = ModelUtil.getFullMovieCardOption(),
}: {
  movies: Movie[];
  onMovieSubGrab?: any;
  onMovieDetailGrab?: any;
  onBookmark?: any;
  option?: MovieCardOption;
}) {
  return (
    <>
      <Grid container spacing={1}>
        {movies.map((movie) => (
          <Grid key={movie.id} container item xs={6} sm={6} md={4} lg={4}>
            <MovieCard
              movie={movie}
              onMovieDetailGrab={onMovieDetailGrab}
              onMovieSubGrab={onMovieSubGrab}
              onBookmark={onBookmark}
              option={option}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
