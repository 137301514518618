import { useState, useEffect } from 'react';
import { Scrapper, ScrapperRequest, Source, SourceRequest } from '../../model/CrawlModel';
import DataSvc from '../../svc/DataSvc';
import { Grid, Card, CardContent, Typography, Stack } from '@mui/material';
import AppTextField from '../common/AppTextField';
import AppActionButton from '../common/AppActionButtion';
import AppLink from '../common/AppLink';
import AppCardTitle from '../common/AppCardTitle';
import SourceCard from '../source/SourceCard';

export default function ScrapperPage() {
  const [scrappers, setScrappers] = useState([] as Scrapper[]);

  const init = async () => {
    setScrappers(await DataSvc.getAllScrapper());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        {scrappers.map((scrapper, k) => (
          <Grid key={k} item xs={12}>
            <ScrapperCard scrapper={scrapper} />
          </Grid>
        ))}
        <Grid item xs={6}>
          <ScrapperCreateCard
            onCreate={async (scrapperRequest: ScrapperRequest) => {
              console.log(await DataSvc.createScrapper(scrapperRequest));
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
function ScrapperCard({ scrapper }: { scrapper: Scrapper }) {
  return (
    <>
      <Card sx={{ minWidth: 200 }} variant="outlined">
        <CardContent>
          <Stack>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              <AppLink href={`scrapper/${scrapper.id}`} label={scrapper.id} />
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {scrapper.description}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {scrapper.pageQuery}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {scrapper.searchQuery}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {scrapper.landingPath}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {scrapper.nestedLinkPath}
            </Typography>
            <SourceList scrapperId={scrapper.id} />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}

function SourceList({ scrapperId }: { scrapperId: string }) {
  const [sources, setSources] = useState([] as Source[]);

  const handleUpdate = async (sourceId: string, sourceRequest: SourceRequest) => {
    await DataSvc.updateSource(sourceId, sourceRequest);
    init();
  };

  const init = async () => {
    setSources(await DataSvc.getAllSource(scrapperId));
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        {sources.map((source, k) => (
          <Grid key={k} item xs={4}>
            <SourceCard source={source} onUpdate={handleUpdate} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function ScrapperCreateCard({ onCreate }: { onCreate: any }) {
  const [scrapperRequest, setScrapperRequest] = useState({
    id: '',
    description: '',
    searchQuery: '',
    pageQuery: '',
    landingPath: '',
    nestedLinkPath: '',
  } as ScrapperRequest);

  const init = async () => {
    setScrapperRequest(scrapperRequest);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Stack spacing={1}>
            <AppCardTitle label={'Create Scrapper'} />
            <AppTextField
              label={'Scrapper Id'}
              value={scrapperRequest.id}
              onChange={(value: string) => setScrapperRequest({ ...scrapperRequest, id: value })}
            />
            <AppActionButton
              label={'Save'}
              onClick={() => {
                onCreate(scrapperRequest);
              }}
              disabled={scrapperRequest.id.trim().length < 1}
            />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
