import Button from '@mui/material/Button';

export default function UpdateButton({ onClick }: { onClick: any }) {
  return (
    <>
      {onClick == null ? (
        ''
      ) : (
        <Button size="small" variant="outlined" fullWidth onClick={onClick} color="primary">
          Save
        </Button>
      )}
    </>
  );
}
