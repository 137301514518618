import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

export default function AppRadioButton({ label, value, onChange }: { label: string, value: string; onChange: any }) {
  return (
    <>
      <FormControl size="small">
        <RadioGroup
          row
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange((event.target as HTMLInputElement).value);
          }}
        >
          <FormControlLabel value="FEMALE" control={<Radio />} label="F" />
          <FormControlLabel value="MALE" control={<Radio />} label="M" />
          <FormControlLabel value="UNKNOWN" control={<Radio />} label="UN" />
        </RadioGroup>
      </FormControl>
    </>
  );
}
