import { useEffect, useState } from 'react';
import { Movie, MovieCardOption } from '../../model/Model';
import MovieCard from './MovieCard';
import MovieDataService from '../../svc/MovieDataService';
import ModelUtil from '../../util/ModelUtil';

export default function MovieDynamicCard({
  name,
  option = ModelUtil.getFullMovieCardOption(),
}: {
  name: string;
  option?: MovieCardOption;
}) {
  const [movie, setMovie] = useState({} as Movie);

  const init = async () => {
    setMovie(await MovieDataService.getByName(name));
  };

  useEffect(() => {
    init();
  }, []);

  return <>{movie.id ? <MovieCard movie={movie} option={option} /> : ''}</>;
}
