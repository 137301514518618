import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import { LeanItem } from '../../../wl/model/Model';
import React from 'react';

export default function SingleAutoComplete({
  label,
  options,
  value,
  onChange,
}: {
  label: string;
  options: LeanItem[];
  value: LeanItem | null;
  onChange: any;
}) {
  const [inputValue, setInputValue] = React.useState('');

  return (
    <div>
      <Autocomplete
        size="small"
        fullWidth
        options={options}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={value}
        onChange={(event: React.SyntheticEvent, value: LeanItem | null, reason: AutocompleteChangeReason) => {
          onChange(value);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        defaultValue={value}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </div>
  );
}
