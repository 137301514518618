import { Card, CardContent, Typography, Switch } from '@mui/material';
import { useState, useEffect } from 'react';
import { Source } from '../../model/CrawlModel';
import Mapper from '../../util/Mapper';

export default function SourceCard({ source, onUpdate }: { source: Source; onUpdate: any }) {
  const [sourceRequest, setSourceRequest] = useState(Mapper.toSourceRequest(source));

  const init = async () => {
    setSourceRequest(Mapper.toSourceRequest(source));
  };

  useEffect(() => {
    init();
  }, [source]);

  return (
    <>
      <Card sx={{ minWidth: 200 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {sourceRequest.sourceItem}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {sourceRequest.url}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Allow Scrape
            <Switch
              checked={sourceRequest.allowScrape}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onUpdate(source.id, { ...sourceRequest, allowScrape: event.target.checked });
              }}
            />
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
