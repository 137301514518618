import { crawlSvc } from '../../core/config/AxioxConfig';
import { ApiResponse, Scrapper, ScrapperRequest, Source, SourceRequest } from '../model/CrawlModel';

class DataSvc {
  private readonly URL: string = '/v1';

  public async getAllScrapper() {
    return (await crawlSvc.get<Scrapper[]>(`${this.URL}/scrapper`)).data;
  }

  public async getScrapperById(id :string) {
    return (await crawlSvc.get<Scrapper>(`${this.URL}/scrapper/${id}`)).data;
  }

  public async createScrapper(scrapperRequest: ScrapperRequest) {
    return (await crawlSvc.post<ApiResponse>(`${this.URL}/scrapper`, scrapperRequest)).data;
  }

  public async updateScrapper(scrapperId: string, scrapperRequest: ScrapperRequest) {
    return (await crawlSvc.put<ApiResponse>(`${this.URL}/scrapper/${scrapperId}`, scrapperRequest)).data;
  }


  public async getAllSource(scrapperId: string) {
    if (scrapperId.trim().length < 1) return (await crawlSvc.get<Source[]>(`${this.URL}/source`)).data;
    return (await crawlSvc.get<Source[]>(`${this.URL}/source?scrapperId=${scrapperId}`)).data;
  }

  public async getSourceById(sourceId: String) {
    return (await crawlSvc.get<Source>(`${this.URL}/source/${sourceId}`)).data;
  }

  public async createSource(sourceRequest: SourceRequest) {
    return (await crawlSvc.post<ApiResponse>(`${this.URL}/source`, sourceRequest)).data;
  }

  public async updateSource(sourceId: string, sourceRequest: SourceRequest) {
    return (await crawlSvc.put<ApiResponse>(`${this.URL}/source/${sourceId}`, sourceRequest)).data;
  }
}

export default new DataSvc();
