import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Count, Series } from '../../model/Model';
import AppLink from '../../../core/cmp/common/AppLink';
import CopyToClipboard from '../../../core/cmp/common/CopyToClipboard';

export default function SeriesTable({ series, movieCount }: { series: Series[]; movieCount: Count }) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Series</TableCell>
              <TableCell align="right">Movie Count</TableCell>
              <TableCell align="right">Rating</TableCell>
              <TableCell align="right">Verified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {series.map((series, index) => {
              return (
                <TableRow key={series.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell> {++index} </TableCell>
                  <TableCell component="th" scope="row">
                    <AppLink label={series.name} href={`/series/${series.id}`} />
                    <CopyToClipboard value={series.name} />
                  </TableCell>
                  <TableCell align="right">{movieCount[series.id]}</TableCell>
                  <TableCell align="right">{series.rating}</TableCell>
                  <TableCell align="right">{series.verified ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
