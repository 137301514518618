import { Count, Tag } from '../../model/Model';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AppLink from '../../../core/cmp/common/AppLink';
import CopyToClipboard from '../../../core/cmp/common/CopyToClipboard';
import CheckBoxDisplay from '../../../core/cmp/common/display/CheckBoxDisplay';

export default function TagTable({ tags, tagMovieCounts }: { tags: Tag[]; tagMovieCounts: Count }) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Tag</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Movie Count</TableCell>
              <TableCell align="center">Rating</TableCell>
              <TableCell align="center">Verified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tags.map((tag) => (
              <TableRow key={tag.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <AppLink label={tag.name} href={`/tag/${tag.id}`} />
                  <CopyToClipboard value={tag.name} />
                </TableCell>
                <TableCell>{tag.type}</TableCell>
                <TableCell align="center">{tagMovieCounts[tag.id]}</TableCell>
                <TableCell align="center">{tag.rating}</TableCell>
                <TableCell align="center">
                  <CheckBoxDisplay checked={tag.verified} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
