import Link from '@mui/material/Link';

export default function AppLink({ href, label }: { href: string; label: string }) {
  return (
    <>
      <Link href={href} underline="none" fontSize="inherit" color="inherit">
        {label}
      </Link>
    </>
  );
}
