import { Stack, Typography } from '@mui/material';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';

export default function MovieCountIconDisplay({ value }: { value: number }) {
  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <MovieCreationIcon />
        <Typography variant="body2" color="text.secondary">
          {value}
        </Typography>
      </Stack>
    </>
  );
}
