import { Button, Card, CardContent, Chip, Stack } from '@mui/material';
import AppUtil from '../../util/AppUtil';
import { Source } from '../../model/Model';
import { useEffect, useState } from 'react';
import SourceDataService from '../../svc/SourceDataService';
import { PlayArrowIcon } from '../../../core/cmp/common/Icon';
import AppConstant from '../../../core/config/AppConstant';

export default function MovieSearch({ code }: { code: string }) {
  const [sources, setSources] = useState([] as Source[]);

  const getSearchUrl = (source: Source, code: string) => {
    return `${source.url}${AppUtil.parseLinkWithCode(source.grabber.searchQuery, code)}`;
  };

  const init = async () => {
    setSources((await SourceDataService.getAll()).content);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Card variant="outlined" sx={{ minWidth: 100 }}>
        <CardContent>
          <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
            {sources.map((source, index) => (
              <Chip
                key={index}
                label={AppUtil.getHostname(source.url)}
                href={getSearchUrl(source, code)}
                target="_blank"
                component="a"
                variant="outlined"
                clickable
              />
            ))}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
