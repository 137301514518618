import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, FormControlLabel, Grid, SelectChangeEvent } from '@mui/material';
import SelectOption from '../../../core/cmp/common/SelectOption';
import { CastUpdateRequest } from '../../model/Model';
import { useEffect, useMemo, useState } from 'react';
import ModelUtil from '../../util/ModelUtil';

export default function CastAddDialog({
  open,
  handleClose,
  handleCreate,
}: {
  open: boolean;
  handleClose: any;
  handleCreate: any;
}) {
  const defaultActorUpdateRequest = useMemo(() => {
    return ModelUtil.getEmptyActorRequest() as CastUpdateRequest;
  }, []);

  const genderOptions = new Map<string, string>([
    ['MALE', 'Male'],
    ['FEMALE', 'Female'],
  ]);
  const [actorUpdateRequest, setActorUpdateRequest] = useState(defaultActorUpdateRequest);

  const handleGenderChange = (event: SelectChangeEvent) => {
    setActorUpdateRequest({ ...actorUpdateRequest, gender: event.target.value });
  };

  useEffect(() => {
    if (open) return;
    setActorUpdateRequest(defaultActorUpdateRequest);
  }, [open, defaultActorUpdateRequest]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Actor</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="actor-name-input"
                label="Name"
                size="small"
                value={actorUpdateRequest.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setActorUpdateRequest({ ...actorUpdateRequest, name: event.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectOption
                id="gender-select"
                label="Gender"
                value={actorUpdateRequest.gender}
                options={genderOptions}
                changeHandler={handleGenderChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={actorUpdateRequest.actor}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setActorUpdateRequest({ ...actorUpdateRequest, actor: event.target.checked });
                    }}
                  />
                }
                label="Actor"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={actorUpdateRequest.director}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setActorUpdateRequest({ ...actorUpdateRequest, director: event.target.checked });
                    }}
                  />
                }
                label="Directed"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleCreate(actorUpdateRequest);
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
