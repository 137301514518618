import TextField from '@mui/material/TextField';

export default function AppTextField({
  label,
  value,
  onChange,
  fullWidth = true,
}: {
  label: string;
  value: string;
  onChange: any;
  fullWidth?: boolean;
}) {
  return (
    <>
      <TextField
        label={label}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        size="small"
        fullWidth={fullWidth}
      />
    </>
  );
}
