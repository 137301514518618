import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Studio, StudioUpdateRequest } from '../../model/Model';
import StudioDataService from '../../svc/StudioDataService';
import {
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  CardActions,
  Grid,
  TextField,
  Stack,
  Button,
  Typography,
} from '@mui/material';
import CardField from '../../../core/cmp/common/CardField';
import RatingInput from '../../../core/cmp/common/RatingInput';
import ModelUtil from '../../util/ModelUtil';
import Mapper from '../../util/Mapper';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { AxiosError } from 'axios';
import StudioCard from './StudioCard';
import AppConstant from '../../../core/config/AppConstant';
import { enqueueSnackbar } from 'notistack';

export default function StudioDetailPage() {
  const { id } = useParams();
  const [studio, setStudio] = useState({} as Studio);
  const [movieCount, setMovieCount] = useState(0);

  const init = async (studioId: string) => {
    setStudio(await StudioDataService.getOne(studioId));

    setMovieCount((await StudioDataService.getMovieCount(studioId))[studioId]);
  };

  const updateHandler = async (id: string, request: StudioUpdateRequest) => {
    await StudioDataService.update(id, request);

    enqueueSnackbar('Updated');
    init(id);
  };

  const deleteHandler = async (id: string) => {
    if (!window.confirm('Confirm deletion!')) return;

    try {
      await StudioDataService.delete(id);
      enqueueSnackbar(`Deleted item with id ${id}`);
      init(id);

      //@todo redirect
    } catch (ex: AxiosError | any) {
      enqueueSnackbar(ex.response.data.message);
    }
  };

  useEffect(() => {
    if (id === undefined) return;

    init(id);
  }, [id]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Stack spacing={1}>
            {studio.id ? <StudioCard studio={studio} /> : ''}
            <Card variant={studio.verified ? 'outlined' : 'elevation'} sx={{ width: '100%' }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {movieCount}
                </Typography>
                <Typography variant="body2">Total Movies</Typography>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          {studio.id ? (
            <StudioEditableCard studio={studio} updateHandler={updateHandler} deleteHandler={deleteHandler} />
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
}

function StudioEditableCard({
  studio,
  updateHandler,
  deleteHandler,
}: {
  studio: Studio;
  updateHandler: any;
  deleteHandler: any;
}) {
  const [studioUpdateRequest, setStudioUpdateRequest] = useState(ModelUtil.getEmptyStudioRequest());

  useEffect(() => {
    setStudioUpdateRequest(Mapper.toStudioUpdateRequest(studio));
  }, [studio]);

  return (
    <>
      <Card sx={{ minWidth: 100 }}>
        <CardContent>
          <Stack spacing={1}>
            <CardField value={studio.name} />
            <TextField
              id="studio-name-input"
              label="Name"
              size="small"
              value={studioUpdateRequest.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setStudioUpdateRequest({ ...studioUpdateRequest, name: event.target.value });
              }}
            />
            <RatingInput
              rating={studio.rating}
              ratingChangeHandler={(rating: number) => {
                updateHandler(studio.id, { ...studioUpdateRequest, rating: rating });
              }}
            />
            <TextField
              id="studio-link-input"
              label="Link"
              size="small"
              value={studioUpdateRequest.link}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setStudioUpdateRequest({ ...studioUpdateRequest, link: event.target.value });
              }}
            />
            <TextField
              id="studio-movie=page-input"
              label="Movie Page"
              size="small"
              value={studioUpdateRequest.moviePage}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setStudioUpdateRequest({ ...studioUpdateRequest, moviePage: event.target.value });
              }}
            />
            <TextField
              id="studio-codes-input"
              label="Codes"
              size="small"
              value={studioUpdateRequest.codes.join(AppConstant.ITEM_SEPARATOR)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setStudioUpdateRequest({
                  ...studioUpdateRequest,
                  codes: event.target.value.split(AppConstant.ITEM_SEPARATOR),
                });
              }}
            />
            <TextField
              id="studio-cover-input"
              label="Cover"
              size="small"
              value={studioUpdateRequest.cover}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setStudioUpdateRequest({ ...studioUpdateRequest, cover: event.target.value });
              }}
            />
            <TextField
              id="studio-description-input"
              label="Description"
              size="small"
              value={studioUpdateRequest.description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setStudioUpdateRequest({ ...studioUpdateRequest, description: event.target.value });
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={studioUpdateRequest.verified}
                  size="small"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const updatedTagUpdateRequest = { ...studioUpdateRequest, verified: e.target.checked };

                    setStudioUpdateRequest(updatedTagUpdateRequest);
                    updateHandler(studio.id, updatedTagUpdateRequest);
                  }}
                />
              }
              label="Verified"
              labelPlacement="end"
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            size="small"
            startIcon={<SaveIcon />}
            onClick={() => {
              updateHandler(studio.id, studioUpdateRequest);
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => deleteHandler(studio.id)}
          >
            Delete
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
