import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import SideDrawerMenuItemIcon from './SideDrawerMenuItemIcon';
import { AppNavOption } from '../../wl/model/Model';

export default function SideDrawerMenuItem({ open, appNavOption }: { open: boolean; appNavOption: AppNavOption }) {
  const { pathname } = useLocation();

  return (
    <>
      <ListItem key={appNavOption.label} disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={{
            minHeight: 20,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
            background: pathname.includes(appNavOption.route) ? 'rgba(255, 255, 255, 0.08)' : '',
          }}
          component={Link}
          to={appNavOption.route}
        >
          <SideDrawerMenuItemIcon open={open} appNavOption={appNavOption} />
          <ListItemText primary={appNavOption.label} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    </>
  );
}
