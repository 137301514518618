import { Scrapper, ScrapperRequest, Source, SourceRequest } from '../model/CrawlModel';

export default class Mapper {
  public static toSourceRequest(source: Source): SourceRequest {
    return {
      scrapperId: source.scrapperId,
      url: source.url,
      allowScrape: source.allowScrape,
      sourceItem: source.sourceItem,
    } as SourceRequest;
  }
  public static getDefaultSourceRequest(): SourceRequest {
    return {
      scrapperId: 'UNKNOWN',
      url: '',
      allowScrape: false,
      sourceItem: 'MOVIE_META',
    } as SourceRequest;
  }

  public static toScrapperRequest(scrapper: Scrapper): ScrapperRequest {
    return {
      id: scrapper.id,
      description: scrapper.description,
      searchQuery: scrapper.searchQuery,
      pageQuery: scrapper.pageQuery,
      landingPath: scrapper.landingPath,
      nestedLinkPath: scrapper.nestedLinkPath,
    };
  }
}
