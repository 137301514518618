import { Typography } from '@mui/material';

export default function CardField({ value }: { value: string }) {
  return (
    <>
      <Typography sx={{ fontSize: 14 }} color="text.secondary">
        {value}
      </Typography>
    </>
  );
}
