import { Card, CardContent, Stack, Typography, TextField, Grid, Button, ButtonGroup } from '@mui/material';
import { isValidDateString } from '../../util/DateUtil';
import { LeanItem, Movie, MovieRequest, MovieStatus } from '../../model/Model';
import { useEffect, useState } from 'react';
import Mapper from '../../util/Mapper';
import RatingInput from '../../../core/cmp/common/RatingInput';
import CastDataSvc from '../../svc/CastDataSvc';
import SeriesDataService from '../../svc/SeriesDataService';
import TagDataService from '../../svc/TagDataService';
import AppCheckBox from '../../../core/cmp/common/form/AppCheckBox';
import UpdateButton from '../../../core/cmp/common/button/UpdateButton';
import DeleteButton from '../../../core/cmp/common/button/DeleteButton';
import ActionButton from '../../../core/cmp/common/button/ActionButton';
import AppTextField from '../../../core/cmp/common/form/AppTextField';
import TagQuickSelector from './TagQuickSelector';
import DataUtil from '../../util/DataUtil';
import MovieDataService from '../../svc/MovieDataService';
import AsyncAutoComplete from '../../../core/cmp/common/AsyncAutoComplete';
import SingleAsyncAutoComplete from '../../../core/cmp/common/SingleAsyncAutoComplete';
import SingleAutoComplete from '../../../core/cmp/common/SingleAutoComplete';
import AutoCompleteV3 from '../../../core/cmp/common/AutoCompleteV3';
import PagingUtil from '../../util/PagingUtil';

export default function MovieEditableCard({
  movie,
  updateHandler,
  imageUpdateHandler,
  deleteHandler,
}: {
  movie: Movie;
  updateHandler: any;
  imageUpdateHandler: any;
  deleteHandler: any;
}) {
  const [actressAutoCompleteOptions, setActressAutoCompleteOptions] = useState([] as LeanItem[]);
  const [actorAutoCompleteOptions, setActorAutoCompleteOptions] = useState([] as LeanItem[]);
  const [directorAutoCompleteOptions, setDirectorAutoCompleteOptions] = useState([] as LeanItem[]);
  const [seriesAutoCompleteOptions, setSeriesAutoCompleteOptions] = useState([] as LeanItem[]);
  const [tagAutoCompleteOptions, setTagAutoCompleteOptions] = useState([] as LeanItem[]);
  const [statusAutoCompleteOptions, setStatusAutoCompleteOptions] = useState([] as LeanItem[]);

  const [actressSearchKeyword, setActressSearchKeyword] = useState('');
  const [actorSearchKeyword, setActorSearchKeyword] = useState('');
  const [directorSearchKeyword, setDirectorSearchKeyword] = useState('');

  const [selectedTagAutoCompleteOptions, setSelectedTagAutoCompleteOptions] = useState([] as LeanItem[]);
  const [selectedActressAutoCompleteOptions, setSelectedActressAutoCompleteOptions] = useState([] as LeanItem[]);
  const [selectedActorAutoCompleteOptions, setSelectedActorAutoCompleteOptions] = useState([] as LeanItem[]);
  const [selectedDirectorAutoCompleteOption, setSlectedDirectorAutoCompleteOption] = useState(null as LeanItem | null);
  const [selectedSeriesAutoCompleteOption, setSelectedSeriesAutoCompleteOption] = useState(null as LeanItem | null);
  const [selectedStatusAutoCompleteOption, setSelectedStatusAutoCompleteOption] = useState(null as LeanItem | null);

  //@todo map directly
  const [movieRequest, setMovieRequest] = useState({
    name: '',
    rating: -1,
    link: '',
    subFile: false,
    cover: '',
    description: '',
    releaseOn: '',
    verified: false,
    status: MovieStatus.UNKNOWN,
    directorId: '',
    seriesId: '',
    casts: [],
    tags: [],
  } as MovieRequest);

  const QUICK_STATUSES = [
    MovieStatus.BOOKMARKED,
    MovieStatus.QUEUED,
    MovieStatus.WATCHING,
    MovieStatus.REWATCH,
    MovieStatus.COMPLETED,
    MovieStatus.STARRED,
  ];

  const actressAutoCompleteOptionsRequest = PagingUtil.getDefaultCastPagingRequest();
  const actorAutoCompleteOptionsRequest = { ...PagingUtil.getDefaultCastPagingRequest(), gender: 'MALE' };
  const directorAutoCompleteOptionsRequest = {
    ...PagingUtil.getDefaultCastPagingRequest(),
    gender: 'UNKNOWN',
    director: true,
    actor: false,
  };

  const initAutoComplete = async () => {
    setStatusAutoCompleteOptions(await MovieDataService.getStatuses());
    setTagAutoCompleteOptions(
      (await TagDataService.getPage()).content.map((tag) => Mapper.toLeanItem(tag.id, tag.name))
    );
    setSeriesAutoCompleteOptions(
      (await SeriesDataService.getPage()).content.map((series) => Mapper.toLeanItem(series.id, series.name))
    );

    initActressAutoComplateOptions(actressSearchKeyword);
    initActorAutoComplateOptions(actorSearchKeyword);
    initDirectorAutoComplateOptions(directorSearchKeyword);
  };

  const initActressAutoComplateOptions = async (keyword: string) => {
    const options = (await CastDataSvc.getPage({ ...actressAutoCompleteOptionsRequest, keyword: keyword })).content.map(
      (cast) => Mapper.toLeanItem(cast.id, cast.name)
    );
    setActressAutoCompleteOptions(options);
  };

  const initActorAutoComplateOptions = async (keyword: string) => {
    const options = (await CastDataSvc.getPage({ ...actorAutoCompleteOptionsRequest, keyword: keyword })).content.map(
      (cast) => Mapper.toLeanItem(cast.id, cast.name)
    );
    setActorAutoCompleteOptions(options);
  };

  const initDirectorAutoComplateOptions = async (keyword: string) => {
    const options = (
      await CastDataSvc.getPage({ ...directorAutoCompleteOptionsRequest, keyword: keyword })
    ).content.map((cast) => Mapper.toLeanItem(cast.id, cast.name));
    setDirectorAutoCompleteOptions(options);
  };

  useEffect(() => {
    initActressAutoComplateOptions(actressSearchKeyword);
  }, [actressSearchKeyword]);

  useEffect(() => {
    initActorAutoComplateOptions(actorSearchKeyword);
  }, [actorSearchKeyword]);

  useEffect(() => {
    initDirectorAutoComplateOptions(directorSearchKeyword);
  }, [directorSearchKeyword]);

  useEffect(() => {
    if (movie === undefined) return;

    setMovieRequest(Mapper.toMovieRequest(movie));
    setSelectedTagAutoCompleteOptions(movie.tags.map(DataUtil.toTagLeanItem));
    setSelectedActressAutoCompleteOptions(movie.casts.filter(DataUtil.FEMALE_CAST_FILTER).map(DataUtil.toCastLeanItem));
    setSelectedActorAutoCompleteOptions(movie.casts.filter(DataUtil.MALE_CAST_FILTER).map(DataUtil.toCastLeanItem));
    setSlectedDirectorAutoCompleteOption(
      movie.director == null ? null : Mapper.toLeanItem(movie.director.id, movie.director.name)
    );
    setSelectedStatusAutoCompleteOption(
      movie.status == null
        ? Mapper.toLeanItem(MovieStatus.UNKNOWN, MovieStatus.UNKNOWN)
        : Mapper.toLeanItem(movie.status, movie.status)
    );
    setSelectedSeriesAutoCompleteOption(
      movie.series == null ? null : Mapper.toLeanItem(movie.series.id, movie.series.name)
    );
  }, [movie]);

  useEffect(() => {
    initAutoComplete();
  }, []);

  return (
    <Card sx={{ minWidth: '250px' }} variant="outlined">
      <CardContent>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Typography variant="body2" component="div" sx={{ minWidth: 100 }}>
              {movie.name}
            </Typography>
            <RatingInput
              rating={movie.rating}
              ratingChangeHandler={(rating: number) => {
                updateHandler(movie.id, { ...movieRequest, rating: rating });
              }}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <AppTextField
              label="Name"
              value={movieRequest.name}
              onChange={(value: string) => setMovieRequest({ ...movieRequest, name: value })}
            />
            <ButtonGroup variant="outlined" size="small">
              {QUICK_STATUSES.map((i, index) => (
                <Button
                  key={index}
                  variant={movie.status === i ? 'contained' : 'outlined'}
                  onClick={() => updateHandler(movie.id, { ...movieRequest, status: i })}
                >
                  {i}
                </Button>
              ))}
            </ButtonGroup>
          </Stack>
          <AsyncAutoComplete
            label="Actresses"
            options={actressAutoCompleteOptions}
            values={selectedActressAutoCompleteOptions}
            onChange={(femaleCasts: LeanItem[]) => {
              const ids = movie.casts
                .filter(DataUtil.MALE_CAST_FILTER)
                .map((i) => i.id)
                .concat(femaleCasts.map((i) => i.id));

              updateHandler(movie.id, { ...movieRequest, casts: ids });
            }}
            inputValue={actressSearchKeyword}
            onInputChange={(value: string) => setActressSearchKeyword(value)}
          />
          <AsyncAutoComplete
            label="Actor"
            options={actorAutoCompleteOptions}
            values={selectedActorAutoCompleteOptions}
            onChange={(maleCast: LeanItem[]) => {
              const ids = movie.casts
                .filter(DataUtil.FEMALE_CAST_FILTER)
                .map((i) => i.id)
                .concat(maleCast.map((i) => i.id));

              updateHandler(movie.id, { ...movieRequest, casts: ids });
            }}
            inputValue={actorSearchKeyword}
            onInputChange={(value: string) => setActorSearchKeyword(value)}
          />
          <Stack direction="row" spacing={1}>
            <TagQuickSelector
              selectedTags={movie.tags.map((i) => i.id)}
              onChange={(tagId: string, checked: boolean) => {
                const tagIds = movie.tags.map((tag) => tag.id);
                if (checked) {
                  tagIds.push(tagId);
                  updateHandler(movie.id, { ...movieRequest, tags: tagIds });
                } else {
                  updateHandler(movie.id, { ...movieRequest, tags: tagIds.filter((id) => id !== tagId) });
                }
              }}
            />
          </Stack>
          <AutoCompleteV3
            label="Tags"
            options={tagAutoCompleteOptions}
            values={selectedTagAutoCompleteOptions}
            onChange={(values: LeanItem[]) => {
              updateHandler(movie.id, { ...movieRequest, tags: values.map((i) => i.id) });
            }}
          />
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <SingleAutoComplete
                label="Status"
                options={statusAutoCompleteOptions}
                value={selectedStatusAutoCompleteOption}
                onChange={(value: LeanItem | null) => {
                  updateHandler(movie.id, { ...movieRequest, status: value == null ? '' : value.id });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <SingleAsyncAutoComplete
                label="Directors"
                options={directorAutoCompleteOptions}
                value={selectedDirectorAutoCompleteOption}
                onChange={(value: LeanItem | null) => {
                  updateHandler(movie.id, { ...movieRequest, directorId: value == null ? '' : value.id });
                }}
                inputValue={directorSearchKeyword}
                onInputChange={(value: string) => setDirectorSearchKeyword(value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="move-release-date"
                label="Release Date"
                size="small"
                fullWidth
                value={movieRequest.releaseOn}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value;
                  if (!isValidDateString(value)) return;

                  setMovieRequest({ ...movieRequest, releaseOn: value });
                }}
              />
            </Grid>
          </Grid>
          <hr /> <br />
          <TextField
            id="movie-cover-input"
            label="Cover"
            size="small"
            value={movieRequest.cover}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setMovieRequest({ ...movieRequest, cover: event.target.value });
            }}
          />
          <TextField
            id="movie-description"
            label="Description"
            size="small"
            multiline
            rows={4}
            value={movieRequest.description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setMovieRequest({ ...movieRequest, description: event.target.value });
            }}
          />
          <SingleAutoComplete
            label="Series"
            options={seriesAutoCompleteOptions}
            value={selectedSeriesAutoCompleteOption}
            onChange={(value: LeanItem | null) => {
              updateHandler(movie.id, { ...movieRequest, seriesId: value == null ? '' : value.id });
            }}
          />
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <AppCheckBox
              checked={movieRequest.verified}
              label="Verified"
              onChange={(checked: boolean) => updateHandler(movie.id, { ...movieRequest, verified: checked })}
            />
            <ActionButton label="Update Cover" onClick={() => imageUpdateHandler(movie.id, movieRequest)} />
            <UpdateButton onClick={() => updateHandler(movie.id, movieRequest)} />
            <DeleteButton onClick={() => deleteHandler(movie.id)} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
