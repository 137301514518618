import { useEffect } from 'react';
import GrabTaskPage from './GrabTaskPage';

export default function SettingPage() {
  useEffect(() => {}, []);

  return (
    <>
      <GrabTaskPage />
    </>
  );
}
