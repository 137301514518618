import { useState, useEffect } from 'react';
import {
  GrabMovieResponse,
  GrabTaskRequest,
  GrabberType,
  Movie,
  MoviePagingRequest,
  Orientation,
  Source,
  SourceFetchRequest,
} from '../../model/Model';
import GrabService from '../../svc/GrabService';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Grid,
  Button,
  Typography,
  TextField,
  Stack,
} from '@mui/material';
import MovieDataService from '../../svc/MovieDataService';
import AppTextField from '../../../core/cmp/common/form/AppTextField';
import ActionButton from '../../../core/cmp/common/button/ActionButton';
import LinkOpenerIcon from '../../../core/cmp/common/LinkOpenerIcon';
import PeekService from '../../svc/PeekService';
import PagingUtil from '../../util/PagingUtil';
import MovieDynamicCard from '../movie/MovieDynamicCard';
import MovieCard from '../movie/MovieCard';
import { enqueueSnackbar } from 'notistack';

export default function SourceFeed({ source }: { source: Source }) {
  const moviePageRequest = {
    ...PagingUtil.getDefaultMoviePagingRequest(),
    pageSize: 99,
  } as MoviePagingRequest;
  const [sourceFetchRequest, setSourceFetchRequest] = useState({
    sourceId: source.id,
    page: '1',
    rawQuery: source.grabber.landingPath ? source.grabber.landingPath : '',
  } as SourceFetchRequest);
  const [grabMovieResponses, setGrabMovieResponses] = useState([] as GrabMovieResponse[]);
  const [existingMovieNames, setExistinMovieNames] = useState([] as string[]);
  const [movies, setMovies] = useState([] as Movie[]);

  const onImport = async (code: string) => {
    enqueueSnackbar((await GrabService.grabMovie(code)).message);
    init();
  };

  const onQueueAll = async (movieFeeds: GrabMovieResponse[]) => {
    const request = {
      grabItems: movieFeeds.filter((i) => !existingMovieNames.includes(i.name)).map((i) => i.name),
    } as GrabTaskRequest;

    enqueueSnackbar((await GrabService.grabAllMovies(request)).message);
  };

  const init = async () => {
    const grabMovieResponses = await PeekService.peek(sourceFetchRequest);
    const movies = (
      await MovieDataService.getPage({ ...moviePageRequest, names: grabMovieResponses.map((i) => i.name) })
    ).content;
    const movieNames = movies.map((i) => i.name);

    setMovies(movies);
    setExistinMovieNames(movieNames);
    setGrabMovieResponses(grabMovieResponses);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <TextField
            label="Page"
            value={sourceFetchRequest.page}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSourceFetchRequest({ ...sourceFetchRequest, page: event.target.value });
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <AppTextField
            label={'Raw Query'}
            value={sourceFetchRequest.rawQuery}
            onChange={(value: string) => setSourceFetchRequest({ ...sourceFetchRequest, rawQuery: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <ActionButton label="Refresh" onClick={() => init()} />
            <ActionButton label="Queue All" onClick={() => onQueueAll(grabMovieResponses)} />
          </Stack>
        </Grid>
        <Grid container item xs={12}>
          {grabMovieResponses
            .filter((i) => !existingMovieNames.includes(i.name))
            .map((update, index) => (
              <Grid key={index} container item xs={3} sm={4} md={4}>
                <FeedCard
                  movie={update}
                  importedMovies={existingMovieNames}
                  onImport={onImport}
                  orientation={source.grabber.id == GrabberType.JV_OS1 ? Orientation.HORIZONTAL : Orientation.DEFAULT}
                />
              </Grid>
            ))}
        </Grid>
        <Grid container item xs={12}>
          {grabMovieResponses
            .filter((i) => existingMovieNames.includes(i.name))
            .map((grabMovieResponse, index) => (
              <Grid key={index} container item xs={3} sm={4} md={4}>
                <MovieCard movie={movies.filter((movie) => movie.name == grabMovieResponse.name)[0]} />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </>
  );
}

function FeedCard({
  movie,
  importedMovies,
  onImport,
  orientation = Orientation.DEFAULT,
}: {
  movie: GrabMovieResponse;
  importedMovies: string[];
  onImport: any;
  orientation?: Orientation;
}) {
  useEffect(() => {
    console.log('orientation', orientation);
    console.log('importedMovies', importedMovies);
    console.log(movie.name);
  }, []);

  return (
    <>
      {importedMovies.includes(movie.name) ? (
        <>
          <MovieDynamicCard name={movie.name} />
        </>
      ) : (
        <>
          <Card
            variant="outlined"
            sx={
              orientation === Orientation.HORIZONTAL
                ? { maxWidth: 250, width: '100%' }
                : { minWidth: 300, width: '100%' }
            }
          >
            <CardActionArea component="div" disableRipple>
              <CardMedia
                sx={
                  orientation === Orientation.HORIZONTAL
                    ? { height: 350, maxHeight: 350 }
                    : { height: 270, maxHeight: 640 }
                }
                image={movie.cover}
                title={movie.description}
              />
            </CardActionArea>
            <CardContent>
              <Typography variant="body2" component="div">
                {importedMovies.includes(movie.name) ? (
                  <LinkOpenerIcon value={`/movie/${movie.name}`} />
                ) : (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => onImport(movie.name)}
                    disabled={importedMovies.includes(movie.name)}
                  >
                    Import: {movie.name}
                  </Button>
                )}{' '}
                {movie.description} <LinkOpenerIcon value={movie.source} />
              </Typography>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
}
