import { Card, CardContent, Stack } from '@mui/material';
import SingleAutoComplete from '../../../core/cmp/common/SingleAutoComplete';
import AppTextField from '../../../core/cmp/common/form/AppTextField';
import { GrabberType, ItemType, LeanItem, SourceDto, SourceItem } from '../../model/Model';
import { useState } from 'react';
import ActionButton from '../../../core/cmp/common/button/ActionButton';

export default function AddSourceCard({ onClick }: { onClick: any }) {
  const [sourceRequest, setSourceReqeust] = useState({
    url: '',
    sourceItem: SourceItem.MOVIE,
    grabberType: GrabberType.UNKNOWN,
  } as SourceDto);
  return (
    <>
      <Card sx={{ width: '100%' }} variant="outlined">
        <CardContent>
          <Stack spacing={1}>
            <SingleAutoComplete
              label={'Grabber'}
              options={Object.keys(GrabberType).map((i) => ({ id: i, name: i }))}
              value={{ id: sourceRequest.grabberType, name: sourceRequest.grabberType }}
              onChange={(value: LeanItem | null) => {
                if (value == null) return;
                setSourceReqeust({
                  ...sourceRequest,
                  grabberType: GrabberType[value.id as keyof typeof GrabberType],
                });
              }}
            />
            <SingleAutoComplete
              label={'Item Type'}
              options={Object.keys(SourceItem).map((i) => ({ id: i, name: i }))}
              value={{ id: sourceRequest.sourceItem, name: sourceRequest.sourceItem }}
              onChange={(value: LeanItem | null) => {
                if (value == null) return;
                setSourceReqeust({
                  ...sourceRequest,
                  sourceItem: SourceItem[value.id as keyof typeof SourceItem],
                });
              }}
            />
            <AppTextField
              label={'URL'}
              value={sourceRequest.url}
              onChange={(value: string) => setSourceReqeust({ ...sourceRequest, url: value })}
            />
            <ActionButton label={'Add'} onClick={() => onClick(sourceRequest)} />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
