import { useEffect, useState } from 'react';
import { LeanItem, Movie, SortOrder, MovieStatus } from '../../model/Model';
import MovieDataService from '../../svc/MovieDataService';
import PagingUtil from '../../util/PagingUtil';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import MovieList from './MovieList';
import CastDataSvc from '../../svc/CastDataSvc';
import TagDataService from '../../svc/TagDataService';
import ViewUtil from '../../util/ViewUtil';
import { useSearchParams } from 'react-router-dom';
import MovieImporter from './MovieImporter';
import MovieAdder from './MovieAdder';
import ModelUtil from '../../util/ModelUtil';
import SeriesDataService from '../../svc/SeriesDataService';
import GrabService from '../../svc/GrabService';
import AutoCompleteV3 from '../../../core/cmp/common/AutoCompleteV3';
import AppConstant from '../../../core/config/AppConstant';
import AppSlider from '../../../core/cmp/common/AppSlider';
import Typography from '@mui/material/Typography';
import AsyncAutoComplete from '../../../core/cmp/common/AsyncAutoComplete';
import AppTextField from '../../../core/cmp/common/form/AppTextField';
import AppCheckBox from '../../../core/cmp/common/form/AppCheckBox';
import Mapper from '../../util/Mapper';
import PageControl from '../../../core/cmp/common/PageControl';
import { enqueueSnackbar } from 'notistack';

export default function MoviePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const sortByOptions = [
    { id: 'name', name: 'Name' },
    { id: 'rating', name: 'Rating' },
    { id: 'releaseOn', name: 'Release' },
    { id: 'createdAt', name: 'Created' },
    { id: 'updatedAt', name: 'Updated' },
  ] as LeanItem[];

  const actressAutoCompleteOptionsRequest = PagingUtil.getDefaultCastPagingRequest();
  const actorAutoCompleteOptionsRequest = { ...PagingUtil.getDefaultCastPagingRequest(), gender: 'MALE' };
  const directorAutoCompleteOptionsRequest = {
    ...PagingUtil.getDefaultCastPagingRequest(),
    gender: 'UNKNOWN',
    director: true,
    actor: false,
  };

  const [actressSearchKeyword, setActressSearchKeyword] = useState('');
  const [actorSearchKeyword, setActorSearchKeyword] = useState('');
  const [directorSearchKeyword, setDirectorSearchKeyword] = useState('');

  const [movies, setMovies] = useState([] as Movie[]);
  const [actressAutoCompleteOptions, setActressAutoCompleteOptions] = useState([] as LeanItem[]);
  const [actorAutoCompleteOptions, setActorAutoCompleteOptions] = useState([] as LeanItem[]);
  const [directorAutoCompleteOptions, setDirectorAutoCompleteOptions] = useState([] as LeanItem[]);
  const [tagAutoCompleteOptions, setTagAutoCompleteOptions] = useState([] as LeanItem[]);
  const [statusAutoCompleteOptions, setStatusAutoCompleteOptions] = useState([] as LeanItem[]);
  const [releaseYearAutoCompleteOptions, setReleaseYearAutoCompleteOptions] = useState([] as LeanItem[]);
  const [seriesAutoCompleteOptions, setSeriesAutoCompleteOptions] = useState([] as LeanItem[]);
  const [presetFilterOptions, setPresetFilterOptions] = useState([] as LeanItem[]);
  const [totalMovies, setTotalMovies] = useState(1);
  const [pagingRequest, setPagingRequest] = useState(PagingUtil.getDefaultMoviePagingRequest());

  const handlePaginationChange = (value: number) => setPagingRequest({ ...pagingRequest, page: value });

  const handleKeywordChange = (value: string) => setPagingRequest({ ...pagingRequest, keyword: value });

  const handleDescriptionChange = (value: string) => setPagingRequest({ ...pagingRequest, description: value });

  const onSortByChange = (sortBy: string) => setPagingRequest({ ...pagingRequest, sortBy: sortBy });

  const onSortOrderChange = (newSortOrder: SortOrder) =>
    setPagingRequest({ ...pagingRequest, sortOrder: newSortOrder });

  const onMovieDetailGrab = async (name: string) => {
    enqueueSnackbar((await GrabService.grabMovie(name)).message);
    init();
  };

  const onMovieSubGrab = async (name: string) => {
    enqueueSnackbar((await GrabService.grabSub(name)).message);
    init();
  };

  const onBookmark = async (id: string) => {
    enqueueSnackbar((await MovieDataService.updateStatus(id, MovieStatus.BOOKMARKED)).message);
    init();
  };

  const init = () => {
    MovieDataService.getPage(pagingRequest).then((result) => {
      setMovies(result.content);
      setTotalMovies(result.count);
    });
  };

  const handleImport = async (code: string) => {
    enqueueSnackbar((await GrabService.grabMovie(code)).message);
    init();
  };

  const handleAdd = async (code: string) => {
    try {
      await MovieDataService.save({ ...ModelUtil.getEmptyMovieRequest(), name: code });
      enqueueSnackbar(`Added movie ${code}`);
      init();
    } catch (error: any) {
      enqueueSnackbar(`Failed to add movie ${code}`);
    }
  };

  const initAutoComplete = async () => {
    setPresetFilterOptions(await MovieDataService.getFilterPresets());
    setTagAutoCompleteOptions(
      (await TagDataService.getPage()).content.map((tag) => Mapper.toLeanItem(tag.id, tag.name))
    );
    setSeriesAutoCompleteOptions(
      (await SeriesDataService.getPage()).content.map((series) => Mapper.toLeanItem(series.id, series.name))
    );
    setStatusAutoCompleteOptions(await MovieDataService.getStatuses());
    initActressAutoComplateOptions(actressSearchKeyword);
    initActorAutoComplateOptions(actorSearchKeyword);
    initDirectorAutoComplateOptions(directorSearchKeyword);

    const years = Array.from(Array(AppConstant.CURRENT_YEAR - AppConstant.DEFAULT_YEAR).keys()).map((i) => {
      return Mapper.toLeanItem(`${AppConstant.CURRENT_YEAR - i}`, `${AppConstant.CURRENT_YEAR - i}`);
    });

    setReleaseYearAutoCompleteOptions(years);
  };

  const initActressAutoComplateOptions = async (keyword: string) => {
    const options = (await CastDataSvc.getPage({ ...actressAutoCompleteOptionsRequest, keyword: keyword })).content.map(
      (cast) => Mapper.toLeanItem(cast.id, cast.name)
    );
    setActressAutoCompleteOptions(options);
  };

  const initActorAutoComplateOptions = async (keyword: string) => {
    const options = (await CastDataSvc.getPage({ ...actorAutoCompleteOptionsRequest, keyword: keyword })).content.map(
      (cast) => Mapper.toLeanItem(cast.id, cast.name)
    );
    setActorAutoCompleteOptions(options);
  };

  const initDirectorAutoComplateOptions = async (keyword: string) => {
    const options = (
      await CastDataSvc.getPage({ ...directorAutoCompleteOptionsRequest, keyword: keyword })
    ).content.map((cast) => Mapper.toLeanItem(cast.id, cast.name));
    setDirectorAutoCompleteOptions(options);
  };

  useEffect(() => {
    initAutoComplete();
    const keyword = searchParams.get('keyword');
    if (keyword) {
      setPagingRequest({ ...pagingRequest, keyword: keyword });
    }
  }, []);

  useEffect(() => {
    initActressAutoComplateOptions(actressSearchKeyword);
  }, [actressSearchKeyword]);

  useEffect(() => {
    initActorAutoComplateOptions(actorSearchKeyword);
  }, [actorSearchKeyword]);

  useEffect(() => {
    initDirectorAutoComplateOptions(directorSearchKeyword);
  }, [directorSearchKeyword]);

  useEffect(() => {
    init();
  }, [pagingRequest]);

  return (
    <>
      <Stack spacing={1}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <AsyncAutoComplete
              label="Actresses"
              options={actressAutoCompleteOptions}
              values={pagingRequest.actresses}
              onChange={(values: LeanItem[]) => setPagingRequest({ ...pagingRequest, actresses: values })}
              inputValue={actressSearchKeyword}
              onInputChange={(value: string) => setActressSearchKeyword(value)}
            />
          </Grid>
          <Grid item xs={3}>
            <AsyncAutoComplete
              label="Actor"
              options={actorAutoCompleteOptions}
              values={pagingRequest.actors}
              onChange={(values: LeanItem[]) => setPagingRequest({ ...pagingRequest, actors: values })}
              inputValue={actorSearchKeyword}
              onInputChange={(value: string) => setActorSearchKeyword(value)}
            />
          </Grid>
          <Grid item xs={3}>
            <AsyncAutoComplete
              label="Directors"
              options={directorAutoCompleteOptions}
              values={pagingRequest.directors}
              onChange={(values: LeanItem[]) => setPagingRequest({ ...pagingRequest, directors: values })}
              inputValue={directorSearchKeyword}
              onInputChange={(value: string) => setDirectorSearchKeyword(value)}
            />
          </Grid>
          <Grid item xs={3}>
            <AutoCompleteV3
              label="Tags"
              options={tagAutoCompleteOptions}
              values={pagingRequest.tags}
              onChange={(values: LeanItem[]) => {
                setPagingRequest({ ...pagingRequest, tags: values });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <AppTextField label={'Serach...'} value={pagingRequest.keyword} onChange={handleKeywordChange} />
          </Grid>
          <Grid item xs={2}>
            <AppTextField
              label={'Description...'}
              value={pagingRequest.description}
              onChange={handleDescriptionChange}
            />
          </Grid>
          <Grid item xs={2}>
            <AutoCompleteV3
              label="Release Year"
              options={releaseYearAutoCompleteOptions}
              values={pagingRequest.releaseYears}
              onChange={(values: LeanItem[]) => {
                setPagingRequest({ ...pagingRequest, releaseYears: values });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <AutoCompleteV3
              label="Status"
              options={statusAutoCompleteOptions}
              values={pagingRequest.statuses}
              onChange={(values: LeanItem[]) => {
                setPagingRequest({ ...pagingRequest, statuses: values });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <AutoCompleteV3
              label="Preset Filters"
              options={presetFilterOptions}
              values={pagingRequest.presetFilters}
              onChange={(values: LeanItem[]) => {
                setPagingRequest({ ...pagingRequest, presetFilters: values });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography>Rating</Typography>
              <AppSlider
                value={pagingRequest.ratings}
                onChange={(values: number[]) => {
                  setPagingRequest({ ...pagingRequest, ratings: values });
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <AppCheckBox
              checked={pagingRequest.watch}
              label={'Watch'}
              onChange={(value: boolean) => {
                setPagingRequest({ ...pagingRequest, watch: value });
              }}
            />
            <AppCheckBox
              checked={pagingRequest.verified}
              label={'Verified'}
              onChange={(value: boolean) => {
                setPagingRequest({ ...pagingRequest, verified: value });
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <AppTextField
              label="Codes..."
              value={pagingRequest.names.join(AppConstant.ITEM_SEPARATOR)}
              onChange={(value: string) => {
                setPagingRequest({ ...pagingRequest, names: value.trim().split(AppConstant.ITEM_SEPARATOR) });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PageControl
              totalItems={totalMovies}
              pagingRequest={pagingRequest}
              onPageChange={(page: number) => setPagingRequest({ ...pagingRequest, page: page })}
              onPageSizeChange={(pageSize: number) => setPagingRequest({ ...pagingRequest, pageSize: pageSize })}
              onPaginationChange={handlePaginationChange}
              sortByOptions={sortByOptions}
              sortByChangeHandler={onSortByChange}
              sortOrderChangeHandler={onSortOrderChange}
              enableKeyboardShortcut={true}
            />
          </Grid>
        </Grid>
        {ViewUtil.isDefaultView(searchParams) ? (
          <MovieList
            movies={movies}
            onMovieDetailGrab={onMovieDetailGrab}
            onMovieSubGrab={onMovieSubGrab}
            onBookmark={onBookmark}
          />
        ) : (
          ''
        )}
        {movies.length < 1 ? <MovieImporter code={pagingRequest.keyword} handleImport={handleImport} /> : ''}
        {movies.length < 1 ? <MovieAdder code={pagingRequest.keyword} handleImport={handleAdd} /> : ''}

        <PageControl
          totalItems={totalMovies}
          pagingRequest={pagingRequest}
          onPageChange={(page: number) => setPagingRequest({ ...pagingRequest, page: page })}
          onPageSizeChange={(pageSize: number) => setPagingRequest({ ...pagingRequest, pageSize: pageSize })}
          onPaginationChange={handlePaginationChange}
          sortByOptions={sortByOptions}
          sortByChangeHandler={onSortByChange}
          sortOrderChangeHandler={onSortOrderChange}
          enableKeyboardShortcut={true}
        />
      </Stack>
    </>
  );
}
