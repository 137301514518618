import {
  Cast,
  CastUpdateRequest,
  GrabMovieResponse,
  Grabber,
  GrabberType,
  MovieCardOption,
  MovieRequest,
  MovieStatus,
  SeriesUpdateRequest,
  Source,
  SourceItem,
  SourceUpdateRequest,
  StudioUpdateRequest,
  TagType,
  TagUpdateRequest,
} from '../model/Model';

export default class ModelUtil {
  private static readonly DEFAULT_NUMERIC_VALUE = -1;
  private static readonly DEFAULT_DATE = '1900-01-01';

  public static filterActress(actors: Cast[]): Cast[] {
    return actors.filter((i) => i.gender === 'FEMALE');
  }
  public static filterActor(actors: Cast[]): Cast[] {
    return actors.filter((i) => i.gender === 'MALE');
  }

  public static getEmptyMovieRequest(): MovieRequest {
    return {
      name: '',
      rating: ModelUtil.DEFAULT_NUMERIC_VALUE,
      link: '',
      subFile: false,
      cover: '',
      description: '',
      releaseOn: ModelUtil.DEFAULT_DATE,
      verified: false,
      status: MovieStatus.UNKNOWN,
      directorId: '',
      seriesId: '',
      tags: [],
      casts: [],
    } as MovieRequest;
  }

  public static getEmptyActorRequest(): CastUpdateRequest {
    return {
      name: '',
      rating: ModelUtil.DEFAULT_NUMERIC_VALUE,
      dob: '1900-01-01',
      height: ModelUtil.DEFAULT_NUMERIC_VALUE,
      gender: 'FEMALE',
      profile: '',
      cover: '',
      actor: false,
      director: false,
      alternateNames: [],
      description: '',
      watch: false,
      verified: false,
      tags: [],
    } as CastUpdateRequest;
  }

  public static getEmptyStudioRequest(): StudioUpdateRequest {
    return {
      name: '',
      codes: [],
      cover: '',
      description: '',
      link: '',
      searchQuery: '',
      moviePage: '',
      rating: ModelUtil.DEFAULT_NUMERIC_VALUE,
      verified: false,
    } as StudioUpdateRequest;
  }

  public static getEmptySeriesRequest(): SeriesUpdateRequest {
    return {
      name: '',
      link: '',
      description: '',
      cover: '',
      rating: ModelUtil.DEFAULT_NUMERIC_VALUE,
      verified: false,
    } as SeriesUpdateRequest;
  }

  public static getEmptyTagRequest(): TagUpdateRequest {
    return {
      name: '',
      rating: ModelUtil.DEFAULT_NUMERIC_VALUE,
      type: TagType.UNKNOWN,
      verified: false,
    } as TagUpdateRequest;
  }

  public static getEmtpySourceUpdateRequest(): SourceUpdateRequest {
    return {
      url: '',
      searchQuery: '',
      sourceItem: '',
    } as SourceUpdateRequest;
  }

  public static getEmptyGrabMovieResponse(): GrabMovieResponse {
    return {
      name: '',
      description: '',
      releaseDate: '',
      actors: [],
      actresses: [],
      director: [],
      source: '',
      cover: '',
    } as GrabMovieResponse;
  }

  public static getEmptySourceUpdateRequest(): SourceUpdateRequest {
    return {
      url: '',
      searchQuery: '',
      pageQuery: '',
      sourceItem: '',
    } as SourceUpdateRequest;
  }

  public static getEmptySource(): Source {
    return {
      id: '',
      url: '',
      sourceItem: SourceItem.MOVIE,
      grabber: {
        id: GrabberType.UNKNOWN,
        searchQuery: '',
        pageQuery: '',
      } as Grabber,
    } as Source;
  }

  public static getFullMovieCardOption(): MovieCardOption {
    return {
      showName: true,
      showCover: true,
      showCast: true,
      showTag: true,
      showDirector: true,
      showDescription: true,
      showStatus: true,
      showTimestamp: true,
      showControls: true
    };
  }

  public static getMinimalMovieCardOption(): MovieCardOption {
    return {
      showCover: true,
      showName: false,
      showCast: false,
      showTag: false,
      showDirector: false,
      showDescription: false,
      showStatus: false,
      showTimestamp: false,
      showControls: false
    };
  }
}
