import { Stack } from '@mui/material';
import MovieChip from './MovieChip';

export default function MovieChipList({ names }: { names: string[] }) {
  return (
    <>
      <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
        {names.map((name, index) => (
          <MovieChip key={index} name={name} />
        ))}
      </Stack>
    </>
  );
}
