import { Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { AppPage, RelationDto } from '../../model/Model';
import RelationService from '../../svc/RelationService';
import MovieDynamicCard from '../movie/MovieDynamicCard';
import AppLink from '../../../core/cmp/common/AppLink';
import ModelUtil from '../../util/ModelUtil';

export default function RelationPage() {
  const [relations, setRelations] = useState([] as RelationDto[]);

  const init = async () => {
    setRelations(await RelationService.getPage());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Stack spacing={1}>
      {relations.map((relation, rIndex) => (
        <Grid key={rIndex} container spacing={1}>
          {relation.items.splice(0, 3).map((item, iIndex) => (
            <Grid key={`${rIndex}-${iIndex}`} item xs={4}>
              <MovieDynamicCard name={item.name} option={ModelUtil.getMinimalMovieCardOption()} />
            </Grid>
          ))}

          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" component="span">
              <AppLink href={`/${AppPage.RELATION}/${relation.id}`} label={`View all`} />
            </Typography>
          </Grid>
          <hr />
        </Grid>
      ))}
    </Stack>
  );
}
