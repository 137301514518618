import { useEffect, useState } from 'react';
import StatDataService from '../svc/StatDataService';
import { Card, CardContent, Typography, Grid, Stack } from '@mui/material';
import { Agg, Cast, Count, ItemCount, Movie } from '../model/Model';
import StringUtil from '../util/StringUtil';
import { Doughnut, Line } from 'react-chartjs-2';
import ChartUtil from '../util/ChartUtil';
import { useNavigate } from 'react-router-dom';
import AppChip from '../../core/cmp/common/AppChip';
import MovieDataService from '../svc/MovieDataService';

export default function DashboardPage() {
  const navigate = useNavigate();
  const [stat, setStat] = useState({} as Count);
  const [movieByMaxViewCount, setmovieByMaxViewCount] = useState([] as ItemCount<Movie>[]);
  const [castByMaxViewCount, setCastByMaxViewCount] = useState([] as ItemCount<Cast>[]);
  const [movieCountByCode, setMovieCountByCode] = useState([] as Agg[]);
  const [movieCountByStatusChartData, setMovieCountByStatusChartData] = useState(ChartUtil.getDefaultPieChartData());
  const [movieCountByCodeChartData, setMovieCountByCodeChartData] = useState(ChartUtil.getDefaultPieChartData());
  const [movieCountByReleaseChartData, setMovieCountByReleaseChartData] = useState(ChartUtil.getDefaultLineChartData());

  const init = async () => {
    setStat(await StatDataService.getAll());
    setmovieByMaxViewCount(await StatDataService.getMovieByTopView());
    setCastByMaxViewCount(await StatDataService.getCastByTopView());

    const movieCountByStatus = await MovieDataService.count('status');
    setMovieCountByStatusChartData({
      ...movieCountByStatusChartData,
      labels: movieCountByStatus.map((i) => StringUtil.capatilize(i.itemId)),
      datasets: [
        {
          data: movieCountByStatus.map((i) => i.itemCount.toString()),
          backgroundColor: ChartUtil.BG_COLORS,
        },
      ],
    });

    const movieCountByCode = await MovieDataService.count('code');
    setMovieCountByCode(movieCountByCode);
    console.log('movieCountByCode', movieCountByCode);

    setMovieCountByCodeChartData({
      ...movieCountByStatusChartData,
      labels: movieCountByCode.map((i) => StringUtil.capatilize(i.itemId)),
      datasets: [
        {
          data: movieCountByCode.map((i) => i.itemCount.toString()),
          backgroundColor: ChartUtil.BG_COLORS,
        },
      ],
    });

    const movieCountByReleaseChartData = await MovieDataService.count('release');

    setMovieCountByReleaseChartData({
      ...movieCountByReleaseChartData,
      labels: movieCountByReleaseChartData.map((i) => i.itemId),
      datasets: [
        {
          label: 'Movies by Release Year',
          data: movieCountByReleaseChartData.map((i) => i.itemCount.toString()),
          backgroundColor: ChartUtil.BG_COLORS,
        },
      ],
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Stack spacing={1}>
        <Typography>Dashboard</Typography>
        <Grid container spacing={1}>
          {Object.keys(stat).map((key, index) => (
            <Grid key={index} item xs={2}>
              <StatCard label={StringUtil.enumToLabel(key)} value={stat[key]} />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Line data={movieCountByReleaseChartData} />
          </Grid>
          <Grid item xs={6}>
            <Doughnut data={movieCountByStatusChartData} />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Card variant="outlined" sx={{ minWidth: 100 }}>
              <CardContent>
                <CardContentHeader label="Top Movies" />
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                  {movieByMaxViewCount.map((itemCount, index) => (
                    <MovieChip key={index} movie={itemCount.item} />
                  ))}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card variant="outlined" sx={{ minWidth: 100 }}>
              <CardContent>
                <CardContentHeader label="Top Casts" />
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                  {castByMaxViewCount.map((itemCount, index) => (
                    <CastChip key={index} cast={itemCount.item} />
                  ))}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

function CastChip({ cast }: { cast: Cast }) {
  return (
    <>
      <AppChip label={cast.name} href={`/cast/${cast.id}`} />
    </>
  );
}

function MovieChip({ movie }: { movie: Movie }) {
  return (
    <>
      <AppChip label={movie.name} href={`/movie/${movie.id}`} />
    </>
  );
}

function CardContentHeader({ label }: { label: string }) {
  return (
    <>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {label}
      </Typography>
    </>
  );
}

function StatCard({ label, value }: { label: string; value: number }) {
  return (
    <>
      <Card variant="outlined" sx={{ minWidth: 100 }}>
        <CardContent>
          <CardContentHeader label={label} />
          <Typography variant="h6" component="div">
            {value}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
