import { AppNavOption } from '../../wl/model/Model';

export default class AppConfig {
  public static readonly WL_SVC_API_URL = 'https://localhost:3001';
  public static readonly CWL_SVC_API_URL = 'https://localhost:5001';
  public static readonly UTIL_API_URL = 'http://localhost:3002';
  public static readonly CDN_URL = 'http://localhost:3003';
  public static readonly APP_NAV_OPTIONS: AppNavOption[] = [
    {
      label: 'Dashboard',
      route: 'dashbaord',
    },
    {
      label: 'Movie',
      route: 'movie',
    },
    {
      label: 'Cast',
      route: 'cast',
    },
    {
      label: 'Tag',
      route: 'tag',
    },
    {
      label: 'Series',
      route: 'series',
    },
    {
      label: 'Relation',
      route: 'relation',
    },
    {
      label: 'Studio',
      route: 'studio',
    },
    {
      label: 'Source',
      route: 'source',
    },
    {
      label: 'Import',
      route: 'import',
    },
    {
      label: 'Crawl',
      route: 'crawl',
    },
    {
      label: 'Settings',
      route: 'setting',
    },
  ];
}
