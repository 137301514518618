import { AxiosError } from 'axios';
import { watchlistSvc } from '../../core/config/AxioxConfig';
import { ApiResponse, GrabTaskRequest } from '../model/Model';

class GrabService {
  private static readonly URL: string = '/v1/grab';

  public async grabMovie(code: string) {
    try {
      return (await watchlistSvc.patch<ApiResponse>(`${GrabService.URL}/movie?code=${code}`)).data;
    } catch (ex: AxiosError | any) {
      console.error(ex);
      return {
        message: `Failed to grab movie for: ${code}`,
      } as ApiResponse;
    }
  }

  public async grabAllMovies(request: GrabTaskRequest) {
    return (await watchlistSvc.post<ApiResponse>(`${GrabService.URL}/movie`, request)).data;
  }

  public async grabMovieCover(code: string) {
    try {
      return (await watchlistSvc.patch<ApiResponse>(`${GrabService.URL}/cover?code=${code}`)).data;
    } catch (ex: AxiosError | any) {
      console.error(ex);
      return {
        message: `Failed to grab movie for: ${code}`,
      } as ApiResponse;
    }
  }

  public async grabSub(code: string) {
    try {
      return (await watchlistSvc.patch<ApiResponse>(`${GrabService.URL}/sub?code=${code}`)).data;
    } catch (ex: AxiosError | any) {
      console.error(ex);
      return {
        message: `Failed to grab sub for: ${code}`,
      } as ApiResponse;
    }
  }

  public async grabTr(code: string) {
    try {
      return (await watchlistSvc.patch<ApiResponse>(`${GrabService.URL}/tr?code=${code}`)).data;
    } catch (ex: AxiosError | any) {
      console.error(ex);
      return {
        message: `Failed to grab tr for: ${code}`,
      } as ApiResponse;
    }
  }
}

export default new GrabService();
