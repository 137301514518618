import { Stack, Typography } from '@mui/material';
import PanoramaIcon from '@mui/icons-material/Panorama';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export default function CoverIconDisplay({ value }: { value: string }) {
  return (
    <>
      <Stack direction="row" alignItems="center" gap={1}>
        <PanoramaIcon />
        <Typography variant="body2" color="text.secondary">
          {value.trim().length > 0 ? (
            <CheckBoxIcon fontSize="inherit" />
          ) : (
            <CheckBoxOutlineBlankIcon fontSize="inherit" />
          )}
        </Typography>
      </Stack>
    </>
  );
}
