import { Button, ButtonGroup } from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { useSearchParams } from 'react-router-dom';
import ViewUtil from '../../../wl/util/ViewUtil';

export default function PageViewSelector() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <ButtonGroup fullWidth variant="outlined" aria-label="outlined primary button group">
        <Button
          variant="outlined"
          startIcon={<CollectionsIcon />}
          fullWidth
          disabled={!ViewUtil.isTableView(searchParams)}
          onClick={() => setSearchParams(ViewUtil.getDefaultViewSetting())}
        >
          Gallery
        </Button>
        <Button
          variant="outlined"
          startIcon={<TableRowsIcon />}
          fullWidth
          disabled={ViewUtil.isTableView(searchParams)}
          onClick={() => setSearchParams(ViewUtil.getTableViewSetting())}
        >
          Table
        </Button>
      </ButtonGroup>
    </>
  );
}
