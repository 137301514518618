import Typography from '@mui/material/Typography';

export default function AppTextIcon({ icon }: { icon: React.ReactNode }) {
  return (
    <>
      <Typography variant="body2" component="div" color="text.secondary" fontSize="small">
        {icon}
      </Typography>
    </>
  );
}
