import { Grid, Typography, Card, CardContent, Switch } from '@mui/material';
import { useState, useEffect } from 'react';
import { AppSetting } from '../../model/CrawlModel';
import CrawlSettingSvc from '../../svc/CrawlSettingSvc';
import ScrapperPage from '../scrapper/ScrapperPage';
import CrawlSourceDetailPage from './CrawlSourcePage';

export default function CrawlSettingPage() {
  const [appSettings, setAppSettings] = useState([] as AppSetting[]);

  const init = async () => {
    setAppSettings(await CrawlSettingSvc.getAllAppSetting());
  };

  const handleAppSettingUpdate = async (id: string, value: boolean) => {
    await CrawlSettingSvc.updateAppSetting(id, value);
    init();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        {appSettings.map((appSetting, index) => (
          <Grid item key={index} xs={4}>
            <Card sx={{ minWidth: 200 }}>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {appSetting.id}
                  <Switch
                    checked={appSetting.value === 'true'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleAppSettingUpdate(appSetting.id, event.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}

        <Grid item xs={12}>
          <ScrapperPage />
        </Grid>

        <Grid item xs={12}>
          <CrawlSourceDetailPage />
        </Grid>
      </Grid>
    </>
  );
}
