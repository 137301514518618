import axios from 'axios';
import AppConfig from './AppConfig';
import Auth from '../Auth';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const watchlistSvc = axios.create({
  baseURL: AppConfig.WL_SVC_API_URL,
  headers: {
    'Content-type': 'application/json',
    'X-Api-Key': Auth.getKey(),
  },
});

const crawlSvc = axios.create({
  baseURL: AppConfig.CWL_SVC_API_URL,
  headers: {
    'Content-type': 'application/json',
    'X-Api-Key': Auth.getKey(),
  },
});

export { watchlistSvc, crawlSvc };
