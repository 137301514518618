import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import DashboardPage from './wl/cmp/DashboardPage';
import TagPage from './wl/cmp/tag/TagPage';
import TagDetailPage from './wl/cmp/tag/TagDetailPage';
import CastPage from './wl/cmp/cast/CastPage';
import MoviePage from './wl/cmp/movie/MoviePage';
import SettingPage from './wl/cmp/SettingPage';
import MovieDetailPage from './wl/cmp/movie/MovieDetailPage';
import MovieImportPage from './wl/cmp/movie/MovieImportPage';
import CastDetailPage from './wl/cmp/cast/CastDetailPage';
import SeriesPage from './wl/cmp/series/SeriesPage';
import SeriesDetailPage from './wl/cmp/series/SeriesDetailPage';
import AppConfig from './core/config/AppConfig';
import StudioPage from './wl/cmp/studio/StudioPage';
import StudioDetailPage from './wl/cmp/studio/StudioDetailPage';
import { useEffect, useState } from 'react';
import AppUtil from './wl/util/AppUtil';
import SourcePage from './wl/cmp/source/SourcePage';
import SourceDetailPage from './wl/cmp/source/SourceDetailPage';
import RelationPage from './wl/cmp/relation/RelationPage';
import RelationDetailPage from './wl/cmp/relation/RelationDetailPage';
import GrabberDetailPage from './wl/cmp/source/GrabberDetailPage';
import { AppPage } from './wl/model/Model';
import CrawlSettingPage from './crawl/cmp/source/CrawlSettingPage';
import AppSideDrawer from './core/nav/AppSideDrawer';
import DrawerHeader from './core/nav/AppSideDrawerHeader';
import AppTitleBar from './core/nav/AppTitleBar';
import MovieMetadataPage from './crawl/MovieMetadataPage';
import CrawlSourceDetailPage from './crawl/cmp/source/CrawlSourcePage';
import CrawlSourcePage from './crawl/cmp/source/CrawlSourcePage';
import { SnackbarProvider } from 'notistack';
import Player from './extra/Player';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === undefined) return;
    document.title = AppUtil.getFullPageName(pathname);
  }, [pathname]);

  return (
    <div className="App">
      <SnackbarProvider   autoHideDuration={5000} />
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<DashboardPage />} />
            <Route path={`${AppPage.TAG}/:id`} element={<TagDetailPage />} />
            <Route path={`${AppPage.TAG}`} element={<TagPage />} />
            <Route path={`${AppPage.CAST}/:id`} element={<CastDetailPage />} />
            <Route path={`${AppPage.CAST}`} element={<CastPage />} />
            <Route path={`${AppPage.MOVIE}/:name`} element={<MovieDetailPage />} />
            <Route path={`${AppPage.MOVIE}`} element={<MoviePage />} />
            <Route path={`${AppPage.SERIES}/:id`} element={<SeriesDetailPage />} />
            <Route path={`${AppPage.SERIES}`} element={<SeriesPage />} />
            <Route path={`${AppPage.RELATION}/:id`} element={<RelationDetailPage />} />
            <Route path={`${AppPage.RELATION}`} element={<RelationPage />} />
            <Route path={`${AppPage.STUDIO}/:id`} element={<StudioDetailPage />} />
            <Route path={`${AppPage.STUDIO}`} element={<StudioPage />} />
            <Route path={`${AppPage.SOURCE}/:id`} element={<SourceDetailPage />} />
            <Route path={`${AppPage.GRABER}/:id`} element={<GrabberDetailPage />} />
            <Route path={`${AppPage.SOURCE}`} element={<SourcePage />} />
            <Route path={`${AppPage.SETTING}`} element={<SettingPage />} />
            <Route path={`${AppPage.IMPORT}`} element={<MovieImportPage />} />
            <Route path={`${AppPage.CRAWL}/${AppPage.SETTING}`} element={<CrawlSettingPage />} />
            <Route path={`${AppPage.CRAWL}/${AppPage.LINK}/:id`} element={<MovieMetadataPage />} />
            <Route path={`${AppPage.CRAWL}/${AppPage.LINK}`} element={<MovieMetadataPage />} />
            <Route path={`${AppPage.CRAWL}/${AppPage.MOVIE_METADATA}`} element={<MovieMetadataPage />} />
            <Route path={`${AppPage.CRAWL}/${AppPage.SOURCE}/:id`} element={<CrawlSourceDetailPage />} />
            <Route path={`${AppPage.CRAWL}/${AppPage.SOURCE}`} element={<CrawlSourcePage />} />
            <Route path={`${AppPage.CRAWL}`} element={<CrawlSettingPage />} />
            <Route path={`extra/player`} element={<Player />} />
            <Route path="*" element={<DashboardPage />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
}

function Layout() {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppTitleBar open={open} handleDrawerOpen={handleDrawerOpen} />
        <AppSideDrawer open={open} appNavOptions={AppConfig.APP_NAV_OPTIONS} handleDrawerClose={handleDrawerClose} />
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          <DrawerHeader />
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
