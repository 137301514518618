import { Card, CardContent, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { CountDto } from '../../model/Model';
import CastDataSvc from '../../svc/CastDataSvc';

export default function CastTagStatCard({ actorId }: { actorId: string }) {
  const [tags, setTags] = useState([] as CountDto[]);

  const init = async () => {
    const statResult = await CastDataSvc.getTagStat(actorId);
    setTags(statResult);
  };

  useEffect(() => {
    init();
  }, [actorId]);

  return (
    <>
      <Card variant="outlined" sx={{ minWidth: 100 }}>
        <CardContent>
          <Typography variant="h6" component="div">
            Tags
          </Typography>
          <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
            {tags.map((tag, index) => (
              <Tooltip key={index} title={`Total: ${tag.subItemCount}`} arrow>
                <Chip label={tag.itemName} variant="outlined" size="small" />
              </Tooltip>
            ))}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
