import { useEffect, useState } from 'react';
import MovieMetaSvc from '../svc/MovieMetadataSvc';
import { MovieCardOption, SortOrder } from '../../wl/model/Model';
import { MovieMetadata } from '../model/CrawlModel';
import MovieMetadataCard from './MovieMetadataCard';
import QueueSvc from '../svc/QueueSvc';
import ModelUtil from '../../wl/util/ModelUtil';

export default function MovieMetadataDynamicCard({
  name,
  option = ModelUtil.getFullMovieCardOption(),
}: {
  name: string;
  option?: MovieCardOption;
}) {
  const [movieMetaItems, setMovieMetaItems] = useState([] as MovieMetadata[]);

  const init = async () => {
    const movieMetaPage = await MovieMetaSvc.getPage({
      page: 1,
      pageSize: 10,
      sortBy: 'createdAt',
      sortOrder: 'DESC' as SortOrder,
      keyword: '',
      femaleCast: '',
      maleCast: '',
      cast: '',
      name: name,
      linkId: '',
      source: '',
      description: '',
    });

    setMovieMetaItems(movieMetaPage.content);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {movieMetaItems.map((movieMetadata, k) => (
        <MovieMetadataCard
          key={k}
          movieMeta={movieMetadata}
          option={option}
          onScrapeItem={(linkId: string) => QueueSvc.scrapeItem([linkId])}
          onScrapeCover={(movieMetadataId: string) => QueueSvc.scrapeCover([movieMetadataId])}
          onDelete={async (metadataId: string) => {
            if (!confirm('Confirm delete!!!')) return;
            await MovieMetaSvc.delete(metadataId);
            init();
          }}
        />
      ))}
    </>
  );
}
