import { Card, CardMedia, CardContent, Stack, Typography, CardActionArea, Link, Tooltip, Button } from '@mui/material';
import { toDateOnly, toFromNow } from '../../util/DateUtil';
import RatingDisplay from '../../../core/cmp/common/RatingDisplay';
import TagChipList from '../tag/TagChipList';
import { CastChipList, DirectorChip } from '../cast/Casts';
import { ItemCount, Movie, MovieCardOption, MovieStatus, MovieViewLog } from '../../model/Model';
import SubtitleFileIconDisplay from '../../../core/cmp/common/SubtitleFileIconDisplay';
import SubtitleIconDisplay from '../../../core/cmp/common/SubtitleIconDisplay';
import SeriesIconDisplay from '../../../core/cmp/common/SeriesIconDisplay';
import AppConfig from '../../../core/config/AppConfig';
import CopyToClipboard from '../../../core/cmp/common/CopyToClipboard';
import AppLink from '../../../core/cmp/common/AppLink';
import ActionButton from '../../../core/cmp/common/button/ActionButton';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import EditIcon from '@mui/icons-material/Edit';
import ModelUtil from '../../util/ModelUtil';
import ItemStatDataService from '../../svc/ItemStatDataService';
import { useEffect, useState } from 'react';
import {
  BookmarkAddIcon,
  BookmarkRemoveIcon,
  DownloadIcon,
  PlayArrowIcon,
  ViewIcon,
} from '../../../core/cmp/common/Icon';
import AppConstant from '../../../core/config/AppConstant';
import MovieDataService from '../../svc/MovieDataService';
import ActionIconButton from '../../../core/cmp/common/button/ActionIconButton';
import AppText from '../../../core/cmp/common/AppText';
import AppTextIcon from '../../../core/cmp/common/AppTextIcon';

export default function MovieCard({
  movie,
  option = ModelUtil.getFullMovieCardOption(),
  onMovieSubGrab = null,
  onMovieDetailGrab = null,
  onBookmark = null,
}: {
  movie: Movie;
  option?: MovieCardOption;
  onMovieSubGrab?: any;
  onMovieDetailGrab?: any;
  onBookmark?: any;
}) {
  const [itemViewCount, setItemViewCount] = useState({} as ItemCount<string>);
  const [movieViewLogs, setMovieViewLogs] = useState([] as MovieViewLog[]);

  const init = async () => {
    setItemViewCount(await ItemStatDataService.getItemViewCount(movie.id));
    setMovieViewLogs(await MovieDataService.getMovieViewLogs(movie.id));
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Card variant="outlined" sx={{ minWidth: 300, width: '100%' }}>
      <CardActionArea component="div" disableRipple>
        <Link href={`/movie/${movie.name}`}>
          <CardMedia
            sx={{ height: 270, maxHeight: 640 }}
            image={movie.cover ? `${AppConfig.CDN_URL}/movie/cover/${movie.name}.webp` : '/fdp.webp'}
            title={movie.name}
          />
        </Link>
      </CardActionArea>
      <CardContent>
        <Stack spacing={1}>
          {option.showName ? (
            <Stack direction="row" spacing={1} alignContent="center" justifyContent="space-between">
              <Typography variant="body2" fontSize="small" color="text.secondary" sx={{ minWidth: 65 }}>
                <AppLink label={movie.name} href={`/movie/${movie.name}`} />
                <CopyToClipboard value={movie.name} />
              </Typography>
              <RatingDisplay rating={movie.rating} />
              <AppTextIcon icon={<ViewIcon />} />
              <AppText text={movieViewLogs.length.toLocaleString()} />
              <AppText text={toDateOnly(movie.releaseOn)} />
            </Stack>
          ) : (
            ''
          )}
          {option.showCast ? (
            <>
              <CastChipList casts={movie.casts} gender="FEMALE" />
              <CastChipList casts={movie.casts} gender="MALE" />
              <DirectorChip cast={movie.director} />
            </>
          ) : (
            ''
          )}
          {option.showTag ? (
            <>
              <TagChipList tags={movie.tags} />
            </>
          ) : (
            ''
          )}
          {option.showStatus ? (
            <Stack direction="row" spacing={1}>
              <SubtitleFileIconDisplay value={movie.subFile} />
              <SubtitleIconDisplay value={movie.subFile} />
              <SeriesIconDisplay value={movie.series} />
              <Typography
                variant="body2"
                color="text.secondary"
                noWrap
                overflow="hidden"
                sx={{ maxWidth: 80, textTransform: 'capitalize' }}
              >
                {movie.status.toLowerCase()}
              </Typography>
            </Stack>
          ) : (
            ''
          )}

          {option.showDescription ? (
            <Tooltip title={movie.description} placement="top" arrow>
              <Typography variant="body2" color="text.secondary" noWrap overflow="hidden" sx={{ maxWidth: 340 }}>
                {movie.description}
              </Typography>
            </Tooltip>
          ) : (
            ''
          )}

          {option.showTimestamp ? (
            <Stack direction="row" spacing={1}>
              <AppTextIcon icon={<ViewIcon />} />
              <AppText text={movieViewLogs.length > 0 ? toFromNow(movieViewLogs[0].createdAt) : '-'} />
              <AppText text=" | " />
              <AppTextIcon icon={<LibraryAddIcon fontSize="small" />} />
              <AppText text={toFromNow(movie.createdAt)} />
              <AppText text=" | " />
              <AppTextIcon icon={<EditIcon fontSize="small" />} />
              <AppText text={toFromNow(movie.updatedAt)} />
            </Stack>
          ) : (
            ''
          )}

          {option.showControls ? (
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
              <ActionButton
                label="Detail"
                onClick={onMovieDetailGrab == null ? null : () => onMovieDetailGrab(movie.name)}
                startIcon={<DownloadIcon />}
              />
              <ActionButton
                label="Sub"
                onClick={onMovieSubGrab == null ? null : () => onMovieSubGrab(movie.name)}
                startIcon={<DownloadIcon />}
              />
              <Button
                variant="outlined"
                size="small"
                href={`${AppConstant.DEFAULT_MEDIA_PLAYER}://weblink?url=${AppConstant.MEDIA_DIR}/${movie.name}.mp4`}
              >
                <PlayArrowIcon />
              </Button>
              <ActionIconButton
                icon={movie.status != MovieStatus.BOOKMARKED ? <BookmarkAddIcon /> : <BookmarkRemoveIcon />}
                onClick={onBookmark == null ? null : () => onBookmark(movie.id)}
              />
            </Stack>
          ) : (
            ''
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
