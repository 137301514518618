import { Button } from '@mui/material';

export default function ActionIconButton({ icon, onClick = null }: { icon: React.ReactNode; onClick?: any }) {
  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={onClick == null ? () => console.log('Not Implemented') : onClick}
      >
        {icon}
      </Button>
    </>
  );
}
