import { Cast, Count } from '../../model/Model';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import { toAge } from '../../util/DateUtil';
import AppLink from '../../../core/cmp/common/AppLink';
import CopyToClipboard from '../../../core/cmp/common/CopyToClipboard';

export default function CastTable({ actors, actorMovieCount }: { actors: Cast[]; actorMovieCount: Count }) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Age</TableCell>
              <TableCell align="right">Height</TableCell>
              <TableCell align="right">Rating</TableCell>
              <TableCell align="right">Movies</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {actors.map((actor, index) => (
              <TableRow key={actor.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{++index}</TableCell>
                <TableCell>
                  <AppLink label={actor.name} href={`/cast/${actor.id}`} />
                  <CopyToClipboard value={actor.name} />
                </TableCell>
                <TableCell align="right">{toAge(actor.dob)}</TableCell>
                <TableCell align="right">{actor.height === -1 ? '-' : actor.height}</TableCell>
                <TableCell align="right">{actor.rating === -1 ? '-' : actor.rating}</TableCell>
                <TableCell align="right">{actorMovieCount[actor.id]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
