import { Chip } from '@mui/material';

export default function MovieChip({ name }: { name: String }) {
  return (
    <>
      {name ? (
        <Chip label={name} component="a" href={`/movie/${name}`} variant="outlined" size="small" clickable />
      ) : (
        ''
      )}
    </>
  );
}
