import { URLSearchParams } from 'url';

export default class ViewUtil {
  private static KEY_VIEW = 'view';
  private static VIEW_TABLE = 'table';
  private static VIEW_DEFAULT = '';

  public static isDefaultView(searchParams: URLSearchParams): boolean {
    return (
      searchParams.get(ViewUtil.KEY_VIEW) === null || searchParams.get(ViewUtil.KEY_VIEW) === ViewUtil.VIEW_DEFAULT
    );
  }

  public static isTableView(searchParams: URLSearchParams): boolean {
    return searchParams.get(ViewUtil.KEY_VIEW) === ViewUtil.VIEW_TABLE;
  }

  public static getTableViewSetting() {
    return { view: ViewUtil.VIEW_TABLE };
  }

  public static getDefaultViewSetting() {
    return { view: ViewUtil.VIEW_DEFAULT };
  }
}
