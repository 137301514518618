import dayjs from 'dayjs';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export function toAge(date: Date): string {
  const defaultValue = '-';
  if (date == null) return defaultValue;

  if (dayjs(date).year() <= 1900) return defaultValue;

  const age = dayjs().diff(date, 'years');
  if (isNaN(age)) return defaultValue;

  return age.toString();
}

export function toDateOnly(date: string): string {
  const defaultValue = '-';

  if (date == null) return defaultValue;

  const momentDate = dayjs(date, DEFAULT_DATE_FORMAT);

  if (momentDate.year() <= 1900) return defaultValue;

  return momentDate.format('D MMM, YYYY');
}

export function isValidDateString(value: string): boolean {
  const date = dayjs(value, DEFAULT_DATE_FORMAT);
  return date.isValid();
}

export function toDate(value: string): Date {
  const date = dayjs(value, DEFAULT_DATE_FORMAT);
  return date.toDate();
}

export function toFromNow(value: Date): string {
  return dayjs(value).fromNow();
}

export function toDateString(value: Date): string {
  return dayjs(value).format('DD-MM-YYYY')
}
