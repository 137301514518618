import { Card, CardContent, Stack, Typography, Switch } from '@mui/material';
import { useState, useEffect } from 'react';
import { Source } from '../../model/CrawlModel';
import Mapper from '../../util/Mapper';
import AppActionButton from '../common/AppActionButtion';
import AppTextField from '../common/AppTextField';

export default function SourceEditableCard({
  source = null,
  onCreate,
  onUpdate,
}: {
  source: Source | null;
  onCreate: any;
  onUpdate: any;
}) {
  const [sourceRequest, setSourceRequest] = useState(
    source == null ? Mapper.getDefaultSourceRequest() : Mapper.toSourceRequest(source)
  );

  const init = async () => {};

  useEffect(() => {
    init();
  }, [source]);

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Stack spacing={1}>
            <Typography variant="h6" component="div">
              {source == null ? 'Create' : 'Update'} Source
            </Typography>
            <AppTextField
              label={'Scrapper Id'}
              value={sourceRequest.scrapperId}
              onChange={(value: string) => setSourceRequest({ ...sourceRequest, scrapperId: value })}
            />
            <AppTextField
              label={'URL'}
              value={sourceRequest.url}
              onChange={(value: string) => setSourceRequest({ ...sourceRequest, url: value })}
            />
            <Switch
              checked={sourceRequest.allowScrape}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (source == null) {
                  onCreate({ ...sourceRequest, allowScrape: event.target.checked });
                } else {
                  onUpdate(source.id, { ...sourceRequest, allowScrape: event.target.checked });
                }
              }}
            />
            <AppTextField
              label={'Page Query'}
              value={sourceRequest.sourceItem}
              onChange={(value: string) => setSourceRequest({ ...sourceRequest, sourceItem: value })}
            />

            <AppActionButton
              label={source == null ? 'Create' : 'Update'}
              onClick={() => {
                if (source == null) {
                  onCreate(sourceRequest);
                } else {
                  onUpdate(source.id, sourceRequest);
                }
              }}
              disabled={sourceRequest.url.trim().length < 1}
            />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
